// WithSidebar.js
import React, { useState } from 'react';
import SidebarMenu from './SidebarMenu';
import '../assets/css/adminLayout.css';

const WithSidebar = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="admin-layout">
      <button className="hamburger-menu" onClick={toggleSidebar}>
        ☰
      </button>

      <SidebarMenu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="admin-content">{children}</div>
    </div>
  );
};

export default WithSidebar;
