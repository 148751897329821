// Componente para el formulario de Depósito
import React from 'react';
import { Modal, Button, Form, FormLabel } from 'react-bootstrap';
import '../assets/css/Modales.css';

const DepositoModal = ({ show, handleClose }) => {


  return (
    <Modal show={show} onHide={handleClose} className="Forms">
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
      <link href="https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap" rel="stylesheet"></link>
      <Modal.Header className="FormsHeader" closeButton >
        <Modal.Title>Depósito</Modal.Title>
      </Modal.Header>
      <Modal.Body className="FormsBody">
        <Form>
          <div
            style={{
              height: '360px',
              border: '2px dashed #ccc', // Líneas punteadas para indicar espacio reservado
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#f0f0f0', // Fondo opcional
              color: '#333',
            }}
          >
            <p>Espacio reservado para video</p>
          </div>
          <FormLabel>ALIAS PARA TRANSFERIR:</FormLabel>
          <FormLabel className="label-box">playbet.TEL</FormLabel>
          <FormLabel>PAGAR S.A.</FormLabel>
          <FormLabel>CUIT: 30715305050</FormLabel>
          <h3 className='FormsBodyh2'><span>¡ATENCION!</span></h3>
          <h6>
              El CUIL de la cuenta desde donde transfiera
              debe coincidir con el de su usuario. 
          </h6>
          <h5>Sino no se acreditara la transferencia.</h5>
        </Form>
      </Modal.Body>
      <Modal.Footer className="FormsFooter">
        <Button className="botonCancelar gradient-borderCancel" onClick={handleClose}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DepositoModal;