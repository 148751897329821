import React from 'react';
import '../../assets/css/footer.css';

const Privacidad = () => {
  return (
    <div className="footer-page">
        <div className='footer-page-title'>
            <h1>POLÍTICA DE PRIVACIDAD</h1>
        </div>
        <div className='footer-page-content'>
            <h5>Información general</h5>
            <p> 
            La política de privacidad describe cómo www.play.bet.ar procesa la información y los datos proporcionados. La recopilación, el procesamiento y el uso de los datos de los jugadores se manejan exclusivamente de acuerdo con las normas de protección de datos aplicables. www.play.bet.ar ha tomado las medidas técnicas y organizativas necesarias para garantizar que la recogida, tratamiento y uso de los datos se gestionen exclusivamente de acuerdo con la normativa vigente en materia de protección de datos, tanto a nivel interno como por parte de proveedores de servicios externos. Al enviar la información del Jugador a www.play.bet.ar y utilizar el Sitio, el Jugador confirma su consentimiento para el uso de su información personal como se define en esta Política de Privacidad. Si el jugador no está de acuerdo con los términos de esta política de privacidad, www.play.bet.ar sugiere no crear una cuenta de juego.

            </p>
            <h5>Recopilación, procesamiento y uso de datos personales</h5>
            <p>
                La información y datos relacionados con el Jugador que pueden ser recopilados por www.play.bet.ar, utilizados y procesados, son los siguientes:
                    1- Información proporcionada mediante el llenado de formularios en el Sitio Web o cualquier otra información enviada a través del Sitio Web o por correo electrónico;
                    2- La recopilación de estos datos se realiza para la correcta identificación del jugador, para la comunicación con el jugador y en cumplimiento de las leyes y reglamentos de protección;
                    3- Detalles de las transacciones realizadas con www.play.bet.ar, ya sea a través del sitio web, teléfono u otros medios;
                    4- www.play.bet.ar podrá utilizar información y datos personales junto con otra información con la finalidad de:
                        * Procesar apuestas;
                        * Configuración, actividad y gestión de la cuenta del jugador;
                        * Cumplir con las obligaciones legales y reglamentarias de www.play.bet.ar;
                        * Crear perfiles personales;
                        * Realizar investigaciones, encuestas y análisis de jugadores;
                        * Proporcionar al Jugador información sobre ofertas promocionales y productos y servicios de www.play.bet.ar, cuando esté permitido;
                        * Supervisar las operaciones con el objetivo de prevenir el fraude, las apuestas irregulares, el blanqueo de capitales y las trampas.

            </p>
            <h5>Aplicación de cookies</h5>
            <p>
                www.play.bet.ar utiliza "cookies" (pequeños archivos de texto que se almacenan en el ordenador del jugador y que almacena el navegador de Internet). www.play.bet.ar utiliza cookies para que las ofertas online sean fáciles de usar, eficientes y seguras. El lector tiene la posibilidad de impedir el almacenamiento de cookies en su ordenador ajustando la configuración de su navegador de Internet. Sin embargo, al hacerlo, la gama de funciones de las ofertas puede verse limitada.
            </p>
            <h5>Transferencia de datos</h5>
            <p>
                Los datos de los jugadores se tratan de forma estrictamente confidencial. Se excluye la transmisión de datos personales con fines de marketing a terceros. www.play.bet.ar está legalmente obligado a verificar los datos personales ingresados ​​durante el proceso de registro. Sin embargo, en casos excepcionales, es posible que www.play.bet.ar esté obligado legalmente a transmitir datos del Jugador, por ejemplo, obedeciendo a las autoridades legales. La retransmisión de los datos del Jugador sólo se produce, en esta ocasión, en el marco de una base jurídica.
                En casos excepcionales, www.play.bet.ar emplea subsidiarias o empresas afiliadas para brindar servicios. Estas empresas están obligadas a tratar los datos del Jugador de forma confidencial y segura y sólo pueden utilizarlos a este respecto si es necesario para llevar a cabo su tarea. Los datos transmitidos se limitan al mínimo necesario.

            </p>
            <h5>Acceso, modificaciones y derecho de cancelación</h5>
            <p>
                www.play.bet.ar se esfuerza por almacenar correctamente los datos personales del jugador. El jugador puede visualizar y modificar sus datos en cualquier momento. Los datos del disco se borrarán por completo de los discos duros, bases de datos y otros dispositivos antes de que el dispositivo quede fuera de servicio.
                www.play.bet.ar realiza periódicamente copias de seguridad de los datos con el fin de conservar toda la información y datos archivados.
                Le informamos que los datos no podrán ser eliminados cuando aún existan obligaciones legales por parte de www.play.bet.ar o cuando los datos aún sean necesarios en www.play.bet.ar para el pago de servicios.
                No obstante, www.play.bet.ar asume la responsabilidad por la divulgación de los datos del Jugador mediante transferencia de datos y/o acceso injustificado por parte de terceros basado en errores.
            </p>
            <h5>Cambios en el Reglamento de Protección de Datos</h5>
            <p>
                www.play.bet.ar se reserva el derecho de modificar esta declaración de protección de datos en cualquier momento de acuerdo con la normativa de protección de datos vigente. El jugador será informado por correo electrónico, aviso en el e sitio web en esta etapa de cualquier cambio.
            </p>
        </div>
      
    </div>
  );
};

export default Privacidad;