// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vip-container {
    background-color: #1a1a1a;
    color: gold;
    min-height: 100vh;
  }
  
  .vip-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: black;
    color: gold;
    position: relative;
    background-size: cover; /* Ajustar el tamaño */
    background-position: center; /* Centrar el GIF */
    background-repeat: no-repeat; /* Evitar repeticiones */
    height: 80px; /* Ajusta la altura según tu diseño */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    font-family: "Lexend", sans-serif;
    list-style: none;
  }
  
  .vip-content {
    padding: 50px;
    text-align: center;
  }
  
  .vip-footer {
    background-color: #000;
    padding: 10px;
    text-align: center;
    color: gold;
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/css/vipStyles.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,kBAAkB;IAClB,sBAAsB,EAAE,sBAAsB;IAC9C,2BAA2B,EAAE,mBAAmB;IAChD,4BAA4B,EAAE,wBAAwB;IACtD,YAAY,EAAE,qCAAqC;IACnD,2BAA2B;IAC3B,4BAA4B;IAC5B,+BAA+B;IAC/B,8BAA8B;IAC9B,iCAAiC;IACjC,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,WAAW;EACb","sourcesContent":[".vip-container {\n    background-color: #1a1a1a;\n    color: gold;\n    min-height: 100vh;\n  }\n  \n  .vip-navbar {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 10px;\n    background-color: black;\n    color: gold;\n    position: relative;\n    background-size: cover; /* Ajustar el tamaño */\n    background-position: center; /* Centrar el GIF */\n    background-repeat: no-repeat; /* Evitar repeticiones */\n    height: 80px; /* Ajusta la altura según tu diseño */\n    border-top-left-radius: 5px;\n    border-top-right-radius: 5px;\n    border-bottom-right-radius: 5px;\n    border-bottom-left-radius: 5px;\n    font-family: \"Lexend\", sans-serif;\n    list-style: none;\n  }\n  \n  .vip-content {\n    padding: 50px;\n    text-align: center;\n  }\n  \n  .vip-footer {\n    background-color: #000;\n    padding: 10px;\n    text-align: center;\n    color: gold;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
