import React from 'react';
import '../assets/css/Promociones.css';
import promo1 from '../assets/images/promoplaybet.jpg';

const Promociones = () => {
  return (
    <div className="promociones-container">
      {/* Texto "PROMOCIONES" gigante con efecto neón */}
      <h1 className="promociones-title">PROMOCIONES</h1>

      {/* Contenedor del banner promocional */}
      <div className="promociones-banner">
        <img src={promo1} alt="Promoción" className="banner-image"/>
      </div>
    </div>
  );
};

export default Promociones;