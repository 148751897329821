import React, { useEffect, useState, useContext } from 'react';
import { checkUserRole, updateUserBalance, getUserNameById } from '../../services/UserServices/user'; 
import { getCashiers, deleteCashier, editCashier, createCashier, debitBalanceCashier, creditBalanceCashier } from '../../services/UserServices/cashier';
import Modal from 'react-modal';
import { UserContext } from './UserContext';
import '../assets/css/adminCashiers.css';

Modal.setAppElement('#root');

const AdminCashiers = () => {
  const { currentUser } = useContext(UserContext);
  const { setCurrentUser } = useContext(UserContext);
  const [cashiers, setCashiers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCashier, setSelectedCashier] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [formData, setFormData] = useState({});
  const [balance, setBalance] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [newCashierData, setNewCashierData] = useState({
    cashier_name: '',
    cuil: '',
    balance: 0,
    comission: 0,
    user_id: ''
  });
  const staffId = currentUser?.id;
  const staffBalance = currentUser?.balance || 0;

  useEffect(() => {
    const fetchCashiersWithUsers = async () => {
      try {
        const cashiersData = await getCashiers();
  
        // Carga los nombres de los cajeros para cada jugador
        const CashiersWithUserNames = await Promise.all(
          cashiersData.map(async (cashier) => {
            try {
              const userName = await getUserNameById(cashier.user_id);
              return { ...cashier, user_name: userName };
            } catch {
              return { ...cashier, user_name: 'Error al cargar' };
            }
          })
        );
  
        setCashiers(CashiersWithUserNames);
      } catch (err) {
        setError('Error al cargar los jugadores.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchCashiersWithUsers();
  }, []);

  const filteredCashiers = cashiers.filter((cashier) => {
    
    const term = searchTerm.toLowerCase();
    
    return (
      (cashier.id?.toString() || '').toLowerCase().includes(term) ||
      (cashier.cuil?.toLowerCase() || '').includes(term) ||
      (cashier.cashier_name?.toLowerCase() || '').includes(term) ||
      (cashier.user_name?.toLowerCase() || '').includes(term)
    );
  });

  const formatDate = (isoDate) => {
    if (!isoDate) return 'N/A'; // Manejar valores nulos o indefinidos
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatDateWhitTime = (isoDate) => {
    if (!isoDate) return 'N/A'; // Manejar valores nulos o indefinidos
  
    const date = new Date(isoDate);
  
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
    const year = date.getFullYear();
  
    const hours = String(date.getHours()).padStart(2, '0'); // Obtener horas con formato 2 dígitos
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Obtener minutos con formato 2 dígitos
  
    return `${day}/${month}/${year} ${hours}:${minutes}`; // Retornar la fecha y la hora
  };

  const handleOpenModal = (type, cashier) => {
    if (!cashier || !cashier.id) {
      console.error("Jugador inválido o sin ID:", cashier);
      return;
    }
    console.log("Abrir modal con cajero:", cashier);
    setModalType(type);
    setSelectedCashier({ ...cashier }); // Copia para evitar referencias mutables.
    setFormData(cashier || {});
    setBalance(0);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCashier(null);
    setFormData({});
    setBalance(0);
    window.location.reload();
  };

  const handleDeleteCashier = async () => {
    try {
      await deleteCashier(selectedCashier.id);
      setCashiers(cashiers.filter((cashier) => cashier.id !== selectedCashier.id));
    } catch (err) {
      alert('Error al eliminar cajero.');
    } finally {
      handleCloseModal();
    }
  };

  const handleEditCashier = async () => {
    try {
      const updatedCashier = await editCashier(selectedCashier.id, formData);
      setCashiers(cashiers.map((cashier) => (cashier.id === selectedCashier.id ? updatedCashier : cashier)));
    } catch (err) {
      alert('Error al editar cajero.');
    } finally {
      handleCloseModal();
    }
  };

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
    setNewCashierData({ cashier_name: '', cuil: '', balance: 0, comission: '', user_id: '' });
  };

  const handleCreateCashier = async () => {
    try {
      const newCashier = await createCashier(newCashierData);
      setCashiers([...cashiers, newCashier]);
      handleCloseCreateModal();
    } catch (err) {
      alert('Error al crear cajero.');
    }
  };

  const handleBalanceChange = async (type) => {
    console.log("Entrando a handleBalanceChange. Tipo:", type, "Cajero seleccionado:", selectedCashier);
    console.log("Usuario STAFF actual: ", currentUser);
    try {
      if (!selectedCashier || !selectedCashier.id) {
        console.error("Error: Cajero no seleccionado o falta ID.");
        alert("Seleccione un cajero válido antes de proceder.");
        return;
      }

      if (!currentUser || !staffId) {
        console.error("Error: Usuario actual no válido.");
        alert("Error de autenticación. Vuelva a iniciar sesión.");
        return;
      }

      const role = await checkUserRole(staffId);
      console.log("Rol del usuario actual:", role);

      // Obtener saldo actual del jugador seleccionado
      const currentCashierBalance = parseFloat(selectedCashier.balance || 0);
      console.log("Saldo actual del cajero:", currentCashierBalance);

      // Convertir el balance ingresado a número
      const inputBalance = parseFloat(balance);
      if (isNaN(inputBalance) || inputBalance <= 0) {
        throw new Error("El monto ingresado debe ser un número válido mayor a 0.");
      }

      let updatedBalance = currentCashierBalance;
      let updateBalanceStaff = parseFloat(staffBalance);

      if (type === 'credit') {
        updatedBalance += balance; // Sumar el monto ingresado
      } else if (type === 'debit') {
        updatedBalance -= balance; // Restar el monto ingresado
        if (updatedBalance < 0) {
          throw new Error("El saldo no puede ser negativo."); // Validar que no sea negativo
        }
      } else {
        console.error("Tipo de operación inválido:", type);
        return;
      }

      console.log("Nuevo saldo calculado:", updatedBalance);

      if (role === 1) {
        console.log("Procesando como ADMIN");
        if (type === 'credit') await creditBalanceCashier(selectedCashier.id, updatedBalance);
        if (type === 'debit') await debitBalanceCashier(selectedCashier.id, updatedBalance);
      } else {
        console.log("Procesando como STAFF");
        const staffBalance = cashiers.find(( cashier) => cashier.id === staffId)?.balance;
        if (type === 'credit') {
          if (staffBalance < balance) throw new Error('Saldo insuficiente para acreditar.');
          updateBalanceStaff -= parseFloat(balance);
          await creditBalanceCashier(selectedCashier.id, updatedBalance);
          await updateUserBalance(staffId, updateBalanceStaff)
          setCurrentUser((prevUser) => {
            const updatedUser = {
              ...prevUser,
              balance: updateBalanceStaff,
            };
            localStorage.setItem('currentUser', JSON.stringify(updatedUser)); // Persistir
            return updatedUser;
          });
        }
        if (type === 'debit') {
          updateBalanceStaff += parseFloat(balance);
          console.log("saldo a agregar a staff", updateBalanceStaff)
          await debitBalanceCashier(selectedCashier.id, updatedBalance);
          await updateUserBalance(staffId, updateBalanceStaff)
          setCurrentUser((prevUser) => {
            const updatedUser = {
              ...prevUser,
              balance: updateBalanceStaff,
            };
            localStorage.setItem('currentUser', JSON.stringify(updatedUser)); // Persistir
            return updatedUser;
          });
        }
      }

      const updatedCashiers = await getCashiers();
      setCashiers(updatedCashiers);
    } catch (err) {
      console.error("Error capturado en handleBalanceChange:", err.message, err);
      alert(err.message || 'Error al actualizar saldo.');
    } finally {
      handleCloseModal();
    }
  };

  return (
    <div className="admin-cashiers-container">
      <h1>Gestión de Cajeros</h1>
      <label htmlFor="balance">Balance Actual:</label>
      <input
        type="text"
        id="balance"
        value={staffBalance}
        readOnly
        disabled
      />
      {loading && <p>Cargando...</p>}
      {error && <p className="error-message">{error}</p>}
      {!loading && !error && (
        <>
          <button color='rgb(0, 255, 255)' className="action-button create" onClick={() => handleOpenCreateModal()}>
            Crear Cajero
          </button>
          {/* Campo de búsqueda */}
          <input
            type="text"
            placeholder="Buscar por ID, CUIL, Nombre o Cajero"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />

          {filteredCashiers.length > 0 ? (
            <table className="cashiers-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>CUIL</th>
                  <th>Nombre</th>
                  <th>Saldo</th>
                  <th>Fecha de Creación</th>
                  <th>Antepasado</th>
                  <th>Comisión</th>
                  <th>Ultimo Inicio de Sesion</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {filteredCashiers.map((cashier) => (
                  <tr key={cashier.id}>
                    <td>{cashier.id}</td>
                    <td>{cashier.cuil}</td>
                    <td>{cashier.cashier_name}</td>
                    <td>{cashier.balance}</td>
                    <td>{formatDate(cashier.created_at)}</td>
                    <td>{cashier.user_name}</td>
                    <td>{cashier.comission + ' %'}</td>
                    <td>{formatDateWhitTime(cashier.last_session)}</td>
                    <td>
                      <button className="action-button edit" onClick={() => handleOpenModal('edit', cashier)}>Editar</button>
                      <button className="action-button credit" onClick={() => handleOpenModal('credit', cashier)}>Acreditar</button>
                      <button className="action-button debit" onClick={() => handleOpenModal('debit', cashier)}>Retirar</button>
                      <button className="action-button delete" onClick={() => handleOpenModal('delete', cashier)}>Eliminar</button>
                      <button title="Cambiar Contraseña" className="action-button changePassword" onClick={() => handleOpenModal('changePassword', cashier)}>🔒</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No se encontraron cajeros que coincidan con "{searchTerm}".</p>
          )}
        </>
      )}
      {!loading && !error && cashiers.length === 0 && <p>No se encontraron cajeros.</p>}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="modal-content">
          {modalType === 'delete' && (
            <>
              <br></br>
              <p>¿Estás seguro de que deseas eliminar al cajero "{selectedCashier?.Cashier_name}"?</p>
              <button className="confirm-button" onClick={handleDeleteCashier}>Confirmar</button>
            </>
          )}
          {modalType === 'edit' && (
        <>
            <h2>Editar Cajero</h2>
            <form onSubmit={(e) => { e.preventDefault(); handleEditCashier(); }}>
            <label>
                Nombre:
                <input
                type="text"
                value={formData.cashier_name || ''}
                onChange={(e) => setFormData({ ...formData, cashier_name: e.target.value })}
                />
            </label>

            <label>
                CUIL:
                <input
                type="text"
                value={formData.cuil || ''}
                onChange={(e) => setFormData({ ...formData, cuil: e.target.value })}
                />
            </label>

            <label>
                Comisión:
                <div style={{ position: "relative", display: "inline-block" }}>
                  <input
                    type="number"
                    min="0"
                    max="100"
                    step="0.01"
                    value={formData.comission || ''}
                    onChange={(e) => {
                      let value = parseFloat(e.target.value);
                      if (isNaN(value)) value = 0; // Si no es número, establece 0
                      value = Math.min(Math.max(value, 0), 100); // Limita entre 0 y 100
                      setFormData({ ...formData, comission: value });
                    }}
                    style={{
                      paddingRight: "20px", // Espacio para el porcentaje
                      width: "80px", // Ajusta el ancho
                      textAlign: "right"
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "8px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "white",
                      fontSize: "14px"
                    }}
                  >
                    %
                  </span>
                </div>
              </label>  

            <button className="confirm-button" type="submit">Guardar</button>
            </form>
        </>
          )}
          {(modalType === 'credit' || modalType === 'debit') && (
            <>
              <h2>{modalType === 'credit' ? 'Acreditar Saldo' : 'Retirar Saldo'}</h2>
              <form onSubmit={(e) => { e.preventDefault(); handleBalanceChange(modalType); }}>
                <label>
                    Saldo actual: 
                    <span>
                    {selectedCashier?.balance !== undefined ? ` $${selectedCashier.balance} pesos` : 'Cargando...'}
                    </span>
                </label>
                <label>
                  Monto a {(modalType === 'credit' ? 'Acreditar' : 'Retirar')}:
                  <input
                    type='text'
                    value={balance}
                    onChange={(e) => setBalance(Number(e.target.value))}
                  />
                </label>
                <button className="confirm-button" type="submit">Confirmar</button>
              </form>
            </>
          )}
          <button className="close-button" onClick={handleCloseModal}>Cerrar</button>
          {modalType === 'changePassword' && (
        <>
            <h2>Editar Contraseña</h2>
            <form onSubmit={(e) => { e.preventDefault(); handleEditCashier(); }}>

            <label>
                Contraseña:
                <input
                value={formData.password || ''}
                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                />
            </label>

            <button className="confirm-button" type="submit">Guardar</button>
            </form>
        </>
        )}
        </div>
      </Modal>
      <Modal
        isOpen={isCreateModalOpen}
        onRequestClose={handleCloseCreateModal}
        contentLabel="Crear Cajero"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="modal-content">
          <h2>Crear Cajero</h2>
          <form onSubmit={(e) => { e.preventDefault(); handleCreateCashier(); }}>
            <label>
              Nombre:
              <input
                type="text"
                value={newCashierData.cashier_name}
                onChange={(e) => setNewCashierData({ ...newCashierData, cashier_name: e.target.value })}
                required
              />
            </label>
            <label>
              Contraseña:
              <input
                type="text"
                value={newCashierData.password}
                onChange={(e) => setNewCashierData({ ...newCashierData, password: e.target.value })}
                required
              />
            </label>
            <label>
              CUIL:
              <input
                type="text"
                value={newCashierData.cuil}
                onChange={(e) => setNewCashierData({ ...newCashierData, cuil: e.target.value })}
                required
              />
            </label>
            <label>
              Comisión:
              <div style={{ position: "relative", display: "inline-block" }}>
                <input
                  type="number"
                  min="0"
                  max="100"
                  step="0.01"
                  value={newCashierData.comission}
                  onChange={(e) => {
                    let value = parseFloat(e.target.value);
                    if (isNaN(value)) value = 0; // Si no es número, establece 0
                    value = Math.min(Math.max(value, 0), 100); // Limita entre 0 y 100
                    setNewCashierData({ ...newCashierData, comission: value });
                  }}
                  style={{
                    paddingRight: "20px", // Espacio para el porcentaje
                    width: "80px", // Ajusta el ancho
                    textAlign: "right"
                  }}
                />
                <span
                  style={{
                    position: "absolute",
                    right: "8px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "white",
                    fontSize: "14px"
                  }}
                >
                  %
                </span>
              </div>
            </label>
            <label>
              Antepasado:
              <input
                type="number"
                value={newCashierData.user_id}
                onChange={(e) => setNewCashierData({ ...newCashierData, user_id: parseInt(e.target.value) })}
              />
            </label>
            <button className="confirm-button" type="submit">Crear</button>
          </form>
          <button className="close-button" onClick={handleCloseCreateModal}>Cerrar</button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminCashiers;
