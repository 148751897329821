// Componente para el formulario de Retiro
import React, { useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Modal, Button, Form } from 'react-bootstrap';
import { useUser } from '../contexts/UserContext';
import { getAuthToken, createCashout } from '../services/telepagosApi';
import { debitBalancePlayer, editPlayer } from '../services/UserServices/player'
import { debitBalanceCashier, editCashier } from '../services/UserServices/cashier'
import { createMovimiento } from '../services/ReportServices/movimientos';
import { toast } from 'react-toastify';
import { jsPDF } from "jspdf";
import '../assets/css/Modales.css';

const RetiroModal = ({ show, handleClose }) => {
  const { user, setUser } = useUser();
  const [cbuAlias, setCbuAlias] = useState('');
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);  
  const pdfRef = useRef(null);

  // Función para limpiar los campos del formulario
  const resetFields = () => {
    setCbuAlias('');
    setAmount('');
    setLoading(false);
    setShowConfirmationModal(false); // Oculta el modal de confirmación
  };

  const handleModalClose = () => {
    resetFields();
    handleClose(); // Cierra el modal
  };

  const openConfirmationModal = () => {
    if (!cbuAlias || !amount || parseFloat(amount) <= 0) {
      alert("Por favor, complete todos los campos correctamente.");
      return;
    }
    setShowConfirmationModal(true);
  };

  const handleWithdraw = async () => {

    if (user.first_deposit) {
      toast.error("No puede realizar un retiro. Debe esperar al próximo día.");
      return;
    }

    setLoading(true);
    setIsConfirming(true);  // Bloquear botón de confirmar

    try {
      const token = await getAuthToken();

      const referenceId = uuidv4();
      let updatedBalance;
      let retiro = true;

      // Crear cashoutData dinámicamente
      const cashoutData = {
        cuit: user.cuil,
        amount: parseFloat(amount),
        reference_id: referenceId,
      };

      // Determinar si es CVU o Alias
      if (/^\d{22}$/.test(cbuAlias)) {
        cashoutData.cvu = cbuAlias; // Es un CVU
      } else {
        cashoutData.alias = cbuAlias; // Es un Alias
      }

      
      await createCashout(token, cashoutData);

      // Diferenciar la lógica según el role_id del usuario emisor
      if (user.role_id === 3) {
        // Usuario es un cajero: usar debitBalanceCashier
        updatedBalance = parseFloat(user.balance) - parseFloat(amount);
        await debitBalanceCashier(user.id, updatedBalance);
        await editCashier(user.id, {first_deposit: retiro});
      } else {
        // Usuario no es cajero: usar debitBalancePlayer
        updatedBalance = parseFloat(user.balance) - parseFloat(amount);
        await debitBalancePlayer(user.id, updatedBalance);
        await editPlayer(user.id, {first_deposit: retiro});
      }

      // Actualizar el balance en el contexto
      setUser((prevUser) => ({ ...prevUser, balance: updatedBalance, first_deposit: retiro }));

      // Generar IDs de transacción únicos
      const baseTransactionId = `TX-${Date.now()}`;
      const transactionIdTelepagos = `${baseTransactionId}-T`; // TELEPAGOS

      // Registrar movimiento de "RETIRO TELEPAGOS" (Usuario → TELEPAGOS)
      const movimientoRetiro = {
          id_sender: user.id,
          id_receptor: null,
          id_cashier: user.role_id === 3 ? user.user_id : user.cashier_id,
          amount: parseFloat(amount),
          balance_before: parseFloat(user.balance),
          balance_after: updatedBalance,
          transaction_id: transactionIdTelepagos,
          description: "RETIRO TELEPAGOS",
          fecha_hora: new Date().toISOString(),
          sender_type: user.role_id === 3 ? "cashier" : "player",
          receiver_type: null, // TELEPAGOS es un sistema
      };

      await createMovimiento(movimientoRetiro);

      //Mostrar notificación de éxito
      toast.success("Retiro generado exitosamente.");
      pdfRef.current = generatePDF();
      handleModalClose(); // Cierra el modal principal
      setShowDownloadModal(true); //
    } catch (error) {
      console.error("Error al generar el retiro:", error);

      //Mostrar notificación de error con el mensaje del servidor si está disponible
      let errorMessage = "Hubo un error al generar el retiro. Intente nuevamente.";
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage);
    } finally {
      setLoading(false);
      setIsConfirming(false);  // Habilitar botón después de la operación
      setShowConfirmationModal(false);
    }
  };


  // 📌 Función para generar y descargar el PDF
  const generatePDF = () => {
    const doc = new jsPDF();
    const date = new Date().toLocaleString();
  
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text("Comprobante de Transferencia", 20, 20);
  
    doc.setFontSize(12);
    doc.text(`Fecha y Hora: ${date}`, 20, 35);
    doc.text(`Monto Transferido: $${parseFloat(amount).toFixed(2)}`, 20, 45);
  
    doc.setFont("helvetica", "normal");
    doc.text("Emisor:", 20, 60);
    doc.text(`Nombre de usuario: PLAYBET`, 20, 70);
    doc.text(`CUIL: 30715305050`, 20, 80);
  
    doc.text("Receptor:", 20, 100);
    doc.text(`Nombre de usuario: ${user.player_name || user.cashier_name}`, 20, 110);
    doc.text(`CUIL: ${user.cuil || 'N/A'}`, 20, 120);
  
    return doc;
  };
  
  const savePDF = () => {
    if (pdfRef.current) {
      pdfRef.current.save(`Comprobante_Transferencia_${Date.now()}.pdf`);
    }
    setShowDownloadModal(false); // Cerrar modal después de guardar
  };

  return (
    <>
      <Modal show={show} onHide={handleModalClose} className="Forms">
          <Modal.Header closeButton className="FormsHeader">
            <Modal.Title>Retiro</Modal.Title>
          </Modal.Header>
          <Modal.Body className="FormsBody">
            <Form>
              <Form.Group controlId="formCbuAlias">
                <h3>¡Recuerde que solo puede hacer 1 retiro por dia del monto que desee!</h3>
                <Form.Label>CBU o Alias</Form.Label>
                <Form.Control
                  type="text"
                  value={cbuAlias}
                  onChange={(e) => setCbuAlias(e.target.value)}
                  placeholder="Ingrese su CBU o Alias"
                />
              </Form.Group>
              <Form.Group controlId="formAmount">
                <Form.Label>Monto</Form.Label>
                <Form.Control
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Ingrese el monto a retirar"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="FormsFooter">
            <Button className="botonCancelar gradient-borderCancel" onClick={handleModalClose}
            disabled={loading}>
              Cancelar
            </Button>
            <Button className="botonRetirar gradient-borderRetirar" onClick={openConfirmationModal} disabled={loading}>
              {loading ? 'Procesando...' : 'Retirar'}
            </Button>
          </Modal.Footer>
      </Modal>

      {/* Modal de Confirmación */}
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        centered
        className="Forms"
      >
        <Modal.Header className="FormsHeader" closeButton>
          <Modal.Title>Confirmar Retiro</Modal.Title>
        </Modal.Header>
        <Modal.Body className="FormsBody">
          <p>
            ¿Está seguro de que desea retirar{" "}
            <strong>
              {new Intl.NumberFormat("es-AR", {
                style: "currency",
                currency: "ARS",
              }).format(amount)}
            </strong>{" "}
            a la cuenta con alias <strong>{cbuAlias}</strong>?
          </p>
        </Modal.Body>
        <Modal.Footer className="FormsFooter">
          <Button
            className="botonCancelar gradient-borderCancel"
            onClick={() => setShowConfirmationModal(false)}
          >
            Cancelar
          </Button>
          <Button className="botonConfirmar" onClick={handleWithdraw} disabled={isConfirming}>
            {isConfirming ? 'Procesando...' : 'Confirmar'}
            
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de Descarga */}
          <Modal show={showDownloadModal} onHide={() => setShowDownloadModal(false)} centered className="Forms">
              <Modal.Header className="FormsHeader" closeButton>
                <Modal.Title>Comprobante de Transferencia</Modal.Title>
              </Modal.Header>
              <Modal.Body className="FormsBody">
                <p>¿Desea descargar un comprobante de la transferencia?</p>
              </Modal.Body>
              <Modal.Footer className="FormsFooter">
                <Button className="botonCancelar gradient-borderCancel" onClick={() => setShowDownloadModal(false)}>Cancelar</Button>
                <Button className="botonConfirmar" onClick={savePDF}>Sí, Descargar</Button>
              </Modal.Footer>
          </Modal>
    </>
  );
};

export default RetiroModal;
