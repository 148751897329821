import React from 'react';
import { useLocation } from 'react-router-dom';
import '../assets/css/footer.css'; 

const Footer = () => {
  const location = useLocation();
  const isVIPPage = location.pathname.startsWith('/vip');

  return (
    <footer className={`casino-footer ${isVIPPage ? 'vip-footer' : ''}`}>
      <div className="footer-sections">
        {/* Sección: Deporte */}
        <div className="footer-column">
          <h4>Paginas</h4>
          <ul>
            <li><a href="/ ">Inicio</a></li>
            <li><a href="/tragamonedas">Casino</a></li>
            <li><a href="/promociones">Promociones</a></li>
          </ul>
        </div>

        {/* Sección: Políticas del Sitio */}
        <div className="footer-column">
          <h4>Políticas del Sitio</h4>
          <ul>
            <li><a href="/lavado-de-dinero">Contra el Lavado de Dinero</a></li>
            <li><a href="/terminos-y-condiciones">Términos y Condiciones</a></li>
            <li><a href="/privacidad">Políticas de Privacidad</a></li>
            <li><a href="/validacion-apuestas">Validación y Aceptación de Apuestas</a></li>
          </ul>
        </div>

        {/* Sección: Información Adicional */}
        <div className="footer-column">
          <h4>Información Adicional</h4>
          <ul>
            <li><a href="/reglas-deportivas">Reglas Deportivas</a></li>
            <li><a href="/reglas-del-juego">Reglas del Juego</a></li>
            <li><a href="/glosario">Glosario de Apuestas</a></li>
            <li><a href="/juego-responsable">Juego Responsable</a></li>
          </ul>
        </div>

        {/* Sección: Playbet */}
        <div className="footer-column">
          <h4>Playbet</h4>
          <ul>
            <li><a href="/quienes-somos">Quiénes Somos</a></li>
          </ul>
        </div>
      </div>

      {/* Sección inferior con redes sociales */}
      <div className="footer-bottom">
        <div className="footer-about">
          <h4>Playbet</h4>
          <p>
            Somos un sitio oficial de apuestas deportivas, tragamonedas, poker y casino online. Nuestra plataforma
            cuenta con rigurosas normas de seguridad para proteger tus datos y brindarte una navegación confiable.
            Viví la mejor experiencia de juego online desde donde quieras y cuando quieras. Bienvenido a Play.bet.ar.
          </p>
        </div>

        <div className="footer-social">
          <h4>Seguinos en nuestras redes</h4>
          <button className="whatsapp-button" onClick={() => window.open('https://wa.link/96n6kk', '_blank')}>
            WhatsApp
          </button>
          {/* Espacio para más redes sociales */}
          <button className="instagram-button" onClick={() => window.open('https://www.instagram.com/playbet.arg?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==', '_blank')}>
            Instagram
          </button>
          <button className="facebook-button" onClick={() => window.open('https://www.facebook.com/share/19rm9zTd7n/', '_blank')}>
            Facebook
          </button>
        </div>

        <div className="footer-warning">
          <h4>Monedero Compatible</h4>
          <p>Podés jugar solo si eres adulto. El juego puede causar adicción patológica. 18+</p>
        </div>
      </div>

      <div className="footer-legal">
        <p>&copy; 2024 Playbet. Todos los derechos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;
