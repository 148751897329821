import httpClient from '../httpClient';

// Crear un informe de cajero
export const createMovimiento = async (reportData) => {
  const response = await httpClient.post('/movimientos', reportData);
  return response.data;
};

export const getMovimientos = async () => {
    try {
        const response = await httpClient.get('/movimientos');
        console.log('Respuesta del servicio getMovimientos:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error en el servicio getMovimientos:', error);
        throw error;
    }
};

// Obtener un informe de cajero por ID
export const getMovimientosById = async (id) => {
  const response = await httpClient.get(`/movimientos/${id}`);
  return response.data;
};
