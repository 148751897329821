// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Botón flotante "Volver Arriba" */
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: rgba(4, 199, 199, 0.9); /* Color azul brillante */
    color: white;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
}

/* Mostrar botón cuando el usuario baja */
.scroll-to-top.show {
    opacity: 1;
    visibility: visible;
}

/* Efecto al pasar el mouse */
.scroll-to-top:hover {
    transform: scale(1.1);
    background-color: #039393;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/ScrollToTopButton.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,WAAW;IACX,YAAY;IACZ,wCAAwC,EAAE,yBAAyB;IACnE,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,eAAe;IACf,0CAA0C;IAC1C,kDAAkD;IAClD,UAAU;IACV,kBAAkB;IAClB,aAAa;AACjB;;AAEA,yCAAyC;AACzC;IACI,UAAU;IACV,mBAAmB;AACvB;;AAEA,6BAA6B;AAC7B;IACI,qBAAqB;IACrB,yBAAyB;AAC7B","sourcesContent":["/* Botón flotante \"Volver Arriba\" */\n.scroll-to-top {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    width: 50px;\n    height: 50px;\n    background-color: rgba(4, 199, 199, 0.9); /* Color azul brillante */\n    color: white;\n    border: none;\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 24px;\n    cursor: pointer;\n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);\n    transition: opacity 0.3s ease, transform 0.3s ease;\n    opacity: 0;\n    visibility: hidden;\n    z-index: 1000;\n}\n\n/* Mostrar botón cuando el usuario baja */\n.scroll-to-top.show {\n    opacity: 1;\n    visibility: visible;\n}\n\n/* Efecto al pasar el mouse */\n.scroll-to-top:hover {\n    transform: scale(1.1);\n    background-color: #039393;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
