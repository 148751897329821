// Componente para banner mayor de 18
import React, { useState, useEffect } from 'react';
import { useUser } from '../contexts/UserContext';
import { toast } from 'react-toastify';
import '../assets/css/Mayor18.css';

const Mayor18 = () => {
  const { isLoggedIn } = useUser(); // Verifica si hay sesión activa
  const [showModal, setShowModal] = useState(false); // Controla la visibilidad del modal

  useEffect(() => {
    const acceptedAge = localStorage.getItem('mayorEdadAceptado');
    
    if (!acceptedAge) {
      setShowModal(true); // Muestra el modal solo si no está registrado en localStorage
    }
  }, []);

  const handleYes = () => {
    localStorage.setItem('mayorEdadAceptado', 'true');
    setShowModal(false); // Cierra el modal
    
  };

  const handleNo = () => {
    toast.error('Debes ser mayor de 18 años para continuar.');
    window.location.href = 'https://google.com'; // Redirige al usuario
  };

  return (
    <>
      {showModal && (
        <div className="OL-modal">
          <div className="contenido-modal">
            <h2>¿Eres mayor de 18 años?</h2>
            <p>Debes confirmar que tienes más de 18 años para usar esta página.</p>
            <div className="modal-buttons">
              <button onClick={handleYes} className="yes-button">Sí</button>
              <button onClick={handleNo} className="no-button">No</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Mayor18;
