import React from 'react';
import '../../assets/css/footer.css';

const TerminosCondiciones = () => {
  return (
    <div className="footer-page">
        <div className='footer-page-title'>
            <h1>TÉRMINOS Y CONDICIONES</h1>
        </div>
        <div className='footer-page-content'>
            <h5>1.1 Condiciones de uso</h5>
            <p> 
                Sólo podrán abrir una cuenta y acceder a los servicios de www.play.bet.ar a usuarios adultos que hayan cumplido dieciocho años. Es responsabilidad del Jugador obedecer las leyes que le son aplicables antes de registrarse en la Aplicación. En cualquier caso, si se determina que un jugador es menor de edad, www.play.bet.ar suspenderá inmediatamente su cuenta y tomará las medidas que considere pertinentes.
                El jugador se compromete a registrar su nueva cuenta de juego en www.play.bet.ar aportando prueba documentada de su edad, identidad y domicilio. El jugador DEBE completar el formulario de registro utilizando datos reales y eligiendo su propio nombre de usuario, contraseña, PIN y respuesta de seguridad. Al realizar el registro, el jugador recibe un correo electrónico que contiene los detalles ingresados ​​durante el registro. La información proporcionada durante el registro DEBE ser precisa y completa en todos los aspectos.
                El Jugador debe mantener la información de la cuenta de juego en secreto y confidencial, ya que el Jugador es responsable de todas las apuestas realizadas en la cuenta y de cualquier otra actividad que se lleve a cabo. De más el jugador debe hacer todo lo posible para impedir el uso de su cuenta personal por parte de terceros.

            </p>
            <h5>1.2 Creación de la cuenta del juego</h5>
            <p>
                Los jugadores sólo pueden abrir una cuenta.
                El jugador es consciente de que al utilizar la aplicación podría perder sumas de dinero, por lo que www.play.bet.ar no asume ninguna responsabilidad por cualquier pérdida de dinero u otra pérdida pecuniaria resultante de las acciones del jugador en el juego. La cuenta del juego debe estar registrada en datos válidos y deben ser utilizados únicamente por el titular de la cuenta. Por razones de seguridad, se le pide al jugador que copie el código captcha.

            </p>
            <h5>1.3 Gestión de datos personales</h5>
            <p>
                Al iniciar sesión en la cuenta del juego, el jugador puede gestionar sus datos personales. Todas las operaciones que implican editar la información ingresada durante el registro en el sitio www.play.bet.ar son rastreadas y reportadas al jugador por correo electrónico.            </p>
            <h5>1.3.1 Dirección de correo electrónico</h5>
            <p>
                La dirección de correo electrónico ingresada por el jugador al crear la cuenta del juego puede cambiarse recientemente. De hecho, desde la página de Información personal, al ingresar el código PIN, la nueva dirección de correo electrónico y su confirmación, se actualizará la dirección de correo electrónico. Para evitar la incautación de la cuenta de un tercero, se enviará una notificación del reemplazo de la dirección de correo electrónico a la dirección de correo electrónico anterior.

            </p>
            <h5>1.3.2 Contraseña</h5>
            <p>
                Durante el acto de registro, se le solicitará al jugador que ingrese la contraseña. El jugador lo recibirá en la dirección de correo electrónico indicada en el formulario de registro. Además, el correo electrónico también contendrá el código PIN y la respuesta personal a la pregunta secreta. www.play.bet.ar aconseja cambiar la contraseña con frecuencia.
                La contraseña se puede cambiar al iniciar sesión en la cuenta del juego. Al ingresar la contraseña anterior, la nueva contraseña y su confirmación, el jugador ha cambiado la contraseña y recibirá un correo electrónico confirmando la operación.
                Si el jugador no recuerda la contraseña, haga clic en el enlace "Olvidé mi contraseña" en la página de inicio. Se le pedirá al jugador que ingrese el nombre de usuario, la dirección de correo electrónico registrada en la cuenta y el código captcha (por razones de seguridad). La aplicación enviará un correo electrónico con la contraseña olvidada, instando al usuario a cambiarla lo antes posible.
                En el caso de que durante el proceso de recuperación de contraseña los datos ingresados ​​sean incorrectos, el lector recibe un correo electrónico de notificación donde se especifican los datos erróneos y la dirección IP utilizada durante esta sesión.
            </p>
            <h5>1.3.3 Código PIN</h5>
            <p>
                Durante el proceso de registro, se le pedirá al jugador que ingrese el código PIN. El código PIN es un código alfanumérico que protege la edición de datos financieros, este último se utiliza para recibir retiros de créditos del juego. El jugador recibirá el código PIN en la dirección de correo electrónico indicada en el formulario de registro, el correo electrónico también incluirá la contraseña. y la respuesta personal a la pregunta secreta.
                Además, se requerirá el PIN al editar el correo electrónico desde la página de Información personal.
                El proceso de "PIN olvidado" requiere que el jugador ingrese su contraseña y responda la pregunta secreta ingresada durante el registro. El sistema enviará un correo electrónico al jugador con el PIN recuperado, instándole a cambiarlo lo antes posible.
            </p>
            <h5>1.3.4 Pregunta secreta/respuesta personal</h5>
            <p>
                El jugador también tiene la opción de cambiar su pregunta secreta desde la página de Información personal. Se solicitará al jugador que introduzca los siguientes datos: respuesta personal anterior, nueva respuesta personal y su confirmación. El jugador recibirá una notificación por correo electrónico.
            </p>
            <h5>1.3.5 Datos financieros</h5>
            <p>
                Los datos financieros utilizados por el personal de www.play.bet.ar para enviar retiros al jugador se pueden editar desde la página de Información Personal. Para confirmar la operación se le pedirá al jugador que introduzca el PIN y recibirá una notificación por correo electrónico.
            </p>
            <h5>1.4 Ley local</h5>
            <p>
                Los juegos de azar en línea pueden estar prohibidos por la ley vigente en el lugar donde se realiza la transacción, en este caso www.play.bet.ar invita al Jugador a no realizar transacciones de juego. www.play.bet.ar no asume ninguna responsabilidad por cualquier violación de las leyes locales o nacionales perpetradas por el jugador. Es responsabilidad exclusiva del Jugador responder ante las autoridades fiscales competentes por las ganancias recaudadas en el portal de juego.
            </p>
            <h5>1.5 Gestión de cuentas de juego</h5>
            <p>
                Todos los retiros de la cuenta serán auditados antes de ser procesados. Si el proceso de verificación revela una violación de los términos y condiciones por parte del cliente, www.play.bet.ar tiene el derecho de retener el pago de las ganancias. Las solicitudes de retiro se procesarán mediante el mismo método de pago utilizado por el jugador para el depósito.

            </p>
            <h5>1.6 Problemas de software y tecnología</h5>
            <p>
                En caso de que se produzcan errores de comunicación o del sistema en relación con la liquidación de cuentas u otras funciones o componentes del software, ni www.play.bet.ar ni el proveedor del software serán responsables ante el jugador o un tercero con respecto a estos errores www.play.bet.ar se reserva el derecho, en caso de tales errores, de eliminar todos los productos afectados del Sitio y de tomar cualquier otra acción correctiva.
                Si bien www.play.bet.ar se esfuerza por garantizar que el sitio web esté disponible las 24 horas del día, www.play.bet.ar no se hace responsable si por algún motivo el sitio web no está disponible en cualquier momento o durante cualquier período www.play .bet.ar se reserva el derecho de realizar cambios o correcciones, o de modificar, suspender o discontinuar cualquier aspecto del Sitio Web y el contenido o servicios o productos disponibles a través del mismo, incluido el acceso de los jugadores al mismo.            </p>
            <h5>1.7 Actualizaciones</h5>
            <p>
                El jugador será informado vía correo electrónico o sistema de mensajería sobre cualquier tipo de actualización. Los administradores de www.play.bet.ar son responsables de informar a los jugadores sobre las actualizaciones mencionadas anteriormente.
            </p>
            <h5>1.8 Quejas y disputas</h5>           
            <p>
                Si el Jugador desea presentar una queja con respecto a los Servicios, el Jugador primero debe comunicarse con el Servicio del Jugador con respecto a la queja, que será enviada al Equipo de Servicio al Jugador al Gerente o Supervisor hasta que se resuelva.
                Puede encontrar fácilmente los contactos de servicio del reproductor en la página "Contáctenos" disponible en el sitio. Alternativamente, es posible utilizar el sistema de buzón para enviar notificaciones a los administradores.
                www.play.bet.ar se esforzará por resolver el problema a la mayor brevedad posible.
            </p>
        </div>
      
    </div>
  );
};

export default TerminosCondiciones;