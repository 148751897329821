// userServices/player.js

import httpClient from '../httpClient';

// Obtener todos los jugadores
export const getPlayers = async () => {
  const response = await httpClient.get('/players');
  return response.data;
};

// Crear un jugador
export const createPlayer = async (playerData) => {
  const response = await httpClient.post('/players', playerData);
  return response.data;
};

// Obtener un jugador por ID
export const getPlayerById = async (id) => {
  const response = await httpClient.get(`/players/${id}`);
  return response.data;
};

// Buscar jugadores por nombre o CUIL
export const searchPlayers = async (searchTerm) => {
  const response = await httpClient.get('/players/search', {
    params: { searchTerm },
  });
  return response.data;
};

// Actualizar saldo del jugador
export const updatePlayerBalance = async (id, amount) => {
  const response = await httpClient.patch(`/players/${id}/balance`, { amount });
  return response.data;
};

// Actualizar la última sesión de un jugador
export const updatePlayerLastSession = async (playerId, lastSession) => {
  try {
    const response = await httpClient.put(`/players/${playerId}/last-session`, {
      last_session: lastSession,
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating last session for player with ID ${playerId}:`, error.response?.data || error.message);
    throw error;
  }
};

// Editar un jugador
export const editPlayer = async (id, playerData) => {
  const response = await httpClient.put(`/players/${id}`, playerData);
  return response.data;
};

// Eliminar un jugador
export const deletePlayer = async (id) => {
  const response = await httpClient.delete(`/players/${id}`);
  return response.data;
};

// Iniciar sesión jugador
export const loginPlayer = async (identifier, password) => {
  const response = await httpClient.post('/players/login', { identifier, password });
  return response.data;
};

// Función para acreditar saldo a un jugador
export const creditBalancePlayer = async (playerId, balance) => {
    try {
      const response = await httpClient.put(`/players/${playerId}`, { balance });
      return response.data;
    } catch (error) {
      console.error(`Error crediting balance to player with ID ${playerId}:`, error.response?.data || error.message);
      throw error;
    }
};
  
  // Función para retirar saldo de un jugador
export const debitBalancePlayer = async (playerId, balance) => {
    try {
      const response = await httpClient.put(`/players/${playerId}`, { balance });
      return (response.data);
    } catch (error) {
      console.error(`Error debiting balance from player with ID ${playerId}:`, error.response?.data || error.message);
      throw error;
    }
};

// Función para iniciar sesión unificada (Player o Cashier)
export const loginUnified = async ({ identifier, password }) => {
  try {
    // Intentar login como Player
    try {
      const playerResponse = await httpClient.post('/players/login', { identifier, password });
      return { ...playerResponse.data};
    } catch (error) {
      console.error('No se encontró el usuario en Players, intentando en Cashiers...');
    }

    // Intentar login como Cashier
    try {
      const cashierResponse = await httpClient.post('/cashiers/login', { identifier, password });
      return { ...cashierResponse.data};
    } catch (error) {
      console.error('No se encontró el usuario en Cashiers.');
    }

    throw new Error('Credenciales inválidas');
  } catch (error) {
    console.error('Error unificado al iniciar sesión:', error.response?.data || error.message);
    throw error;
  }
};


  
