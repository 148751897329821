import React from 'react';
import '../../assets/css/footer.css';

const ReglasDeportivas = () => {
  return (
    <div className="footer-page">
        <div className='footer-page-title'>
            <h1>REGLAS DEPORTIVAS</h1>
        </div>
        <div className='footer-page-content'>
            <h5>Fútbol</h5>
            <p> 
                Todas las apuestas en el fútbol se basan en 90 minutos de juego más el tiempo de reposición, a menos que sea especificado de otra manera (como en los torneos triangulares que son generalmente de 45 minutos, o bien en los eventos juveniles de 80 minutos). Los tiempos suplementarios, gol de oro o penalties no se tienen en cuenta.
                Los encuentros que sean cancelados, suspendidos o aplazado y no recuperados dentro de las 24 horas siguientes a la hora de inicio, serán considerados nulos.
                Solamente los eventos interrumpidos de forma oficial se definirán como Cancelados. Si el árbitro silba regularmente el final del encuentro antes de que finalicen los 90 minutos de juego, el resultado final será válido. Esta regla no se refiere a los encuentros jugados en 2 tiempos de 40 minutos. En caso de error por parte del Corredor de apuestas, las mismas serán consideradas nulas.
            </p>
            <h5>Tenis</h5>
            <p>
                Los encuentros de tenis se considerarán nulos en caso de cancelación o si son suspendidos y no finalizados dentro de las 72 horas siguientes a la hora de inicio.
                Si, por cualquier razón, el encuentro tuviera que disputarse en una superficie diferente a la prevista al momento de la publicación, www.play.bet.ar se reserva el derecho de considerar nulos los eventos.
                Si un jugador se retira o es descalificado una vez que se ha completado totalmente el primer set, su rival será considerado ganador. Si el retiro o la descalificación se produce antes o durante el primer set, las apuestas se saldarán como nulas.
                Si un jugador se retira o es descalificado antes del primer encuentro del torneo, las apuestas relativas a sus prestaciones durante el torneo se considerarán Anuladas.
                En las competiciones en las cuales, según reglamento, un encuentro está constituido por 5 sets, si el partido viene disputado al mejor de 3 sets, todas las apuestas colocadas en el evento serán Anuladas (por ejemplo en la Copa Davis, cuando el resultado ya indica el equipo nacional vencedor, los equipos pueden decidir jugar al mejor de 3 sets. En este  caso, todas las apuestas relativas al encuentro o a los encuentros acortados se saldarán como nulas).
            </p>
            <h5>Tenis de Mesa</h5>
            <p>
                Si un jugador se retira antes del inicio del encuentro, las apuestas se saldarán como nulas. Si el retiro se produce después del inicio del match su rival será considerado ganador. Si un jugador se retira o es descalificado antes del primer encuentro del torneo, las apuestas relativas a sus prestaciones durante el torneo se considerarán Anuladas.
            </p>
            <h5>Baloncesto</h5>
            <p>
                Para los tipos de apuestas relativas al Baloncesto, será válido el resultado al término del encuentro, eventuales tiempos suplementarios incluidos, para Over/Under se irá a considerar el resultado del 4/4.
                Un encuentro de Baloncesto será considerado nulo si es cancelado o interrumpido y no concluido dentro de 72 horas a partir del momento de su inicio oficial.
            </p>
            <h5>Hockey sobre hielo</h5>
            <p>
                El resultado de las apuestas se basa en el resultado final del encuentro, tiempos suplementarios y shoot outs excluidos (a menos que se especifique lo contrario).
            </p>
            <h5>Balonmano</h5>
            <p>
                El resultado de las apuestas se basa en el resultado final del encuentro, tiempos suplementarios y shoot outs excluidos.
            </p>
            <h5>Béisbol</h5>
            <p>
                El resultado de las apuestas viene determinado por el resultado final del encuentro, incluyendo eventuales extra entradas. Las apuestas se considerarán válidas si se juegan por lo menos 8.5 entradas, de otro modo serán consideradas nulas. Si, por cualquier razón (lluvia, nieve, obscuridad) un encuentro se interrumpe y no viene concluido dentro de 12 horas, o hay una situación de paridad, las apuestas se saldarán como nulas. En caso de dos encuentros entre los mismos equipos en el mismo día, se considerará válido solo el resultado del primer encuentro.
            </p>
            <h5>Vóleibol</h5>
            <p>
                Las apuestas relativas a los encuentros de vóleibol se basan siempre en el resultado al mejor de 5sets (el Golden Set no viene considerado).
            </p>
            <h5>Fórmula 1</h5>
            <p>
                Los resultados relativos a las apuestas en la Fórmula 1 serán publicados después del final de la carrera. No se considerarán las decisiones posteriores realizadas por las autoridades de la carrera / FIA. Un evento de Fórmula 1 será considerada nulo en el caso de una cancelación o si interrumpida y no concluida dentro de 72 horas a partir de la hora oficial de inicio. Si una carrera es, por  cualquier razón, abandonada antes de los 75% de la distancia prevista (redondeada al número de vueltas completadas ), el evento será considerado nulo, aunque la FIA considera la carrera como terminada y asigna puntos a los pilotos y a los equipos. 
                Las decisiones tomadas por la autoridades de la carrera / FIA antes del final de la sesión de clasificación que tienen influencia sobre la posición en la parrilla de salida, no tienen ningún efecto sobre las apuestas colocadas en los eventos "pole".
                Si un piloto no es capaz de participar en la carrera de clasificación, todas las apuestas relacionadas directamente con ese piloto serán canceladas. Si un piloto no es capaz, por cualquier motivo, de participar en la carrera principal después de terminar con éxito las calificaciones, todas las apuestas relacionadas directamente con esa carrera principal serán nulas (las apuestas relacionadas con las carrera de calificación se mantendrán). 
                Un piloto que participa en la vuelta de calentamiento (warm-up-lap) se considera que ha participado en la carrera misma.
            </p>
            <h5>Motociclismo</h5>
            <p>
                Los resultados relativos a las apuestas en el Motociclismo serán publicados después del final de la carrera. No se considerarán las decisiones posteriores realizadas por la autoridades de la carrera / FIM. Una carrera de motos será considerada Anulada en caso de cancelación o si interrumpida y no concluida dentro de 72 horas a partir de la hora oficial de inicio. Si la carrera es, por  cualquier razón, interrumpida antes del 66% de la duración total (redondeada hacia abajo al número de vueltas completadas ), el evento será considerado Anulado, aunque la FIA considera la carrera como terminada y asigna puntos a los equipos. 
                Un piloto que participa en la vuelta de calentamiento (warm-up-lap) se considera que ha participado en la carrera.
            </p>
            <h5>Fútbol Americano</h5>
            <p>
                El resultado de las apuestas se basa en el resultado final del encuentro, tiempos suplementarios incluidos (a menos que se especifique lo contrario).
            </p>
            <h5>Rugby</h5>
            <p>
                El resultado de una apuesta de rugby siempre se basa en el tiempo regular del partido (salvo indicación contraria).
            </p>
            <h5>Futsal</h5>           
            <p>
                Todas las apuestas relativas al Futsal se refieren al tiempo regular del partido (tiempos suplementarios y tiros penales excloidos).
            </p>
            <h5>Deportes de Invierno</h5>
            <p>
                Si un evento de deportes de invierno (esquí alpino, esquí nórdico o de saltos de esquí) se suspende o se pospone, las apuestas seguirán siendo válidas siempre que el evento se ve completado en el mismo lugar dentro de las 24 horas. Si el evento se lleva a cabo en un lugar diferente, las apuestas se considerarán nulas. En caso de que un competidor no inicie la competición, todas las apuestas relacionadas con el competidor serán anuladas.
                Por Esquí alpino, si uno o más esquiadores no llegan a la conclusión del partido, el número de carreras terminadas determinará el ganador del duelo HH(Head to Head). Si ambos esquiadores participan en el mismo número de rondas, la apuesta se establece como Anulada.
            </p>
            <h5>Ciclismo</h5>
            <p>
                Si una carrera por etapas se pospone o se interrumpe, todas las apuestas serán consideradas válidas hasta que se complete la carrera (o hasta que no venga oficialmente cancelada), mientras que las carreras de una sola etapa o en eventos que se disputan en una sola fecha, se considerarán válidas Anuladas en caso de cancelación o interrupción siempre que no vengan concluidas dentro de 72 horas a partir de la hora oficial de inicio.
            </p>
            <h5>Shooker</h5>
            <p>
                Si el evento es pospuesto o interrumpido, todas las apuestas serán consideradas como "abiertas"(en curso) hasta que el evento se haya completado. En el caso de un encuentro ganado por un jugador antes de que el número total de rondas(frames) venga completado, las apuestas serán válidas, siempre y cuando se haya iniciado el partido, de lo contrario serán anuladas.
            </p>
            <h5>Cricket</h5>
            <p>
                El resultado del partido se determina por el resultado oficial. Si un partido se declara oficialmente "sin resultado", todas las apuestas para ese partido serán anuladas. Si el partido es pospuesto o interrumpido y está previsto que tenga lugar o que continúe dentro de las 48 horas desde el momento de inicio original o de la hora de interrupción, todas las apuestas serán válidas, de lo contrario serán consideradas Anuladas. En caso de empate, las apuestas serán perdedoras.
            </p>
            <h5>Waterpolo</h5>
            <p>
                Todas las apuestas válidas relativas al Waterpolo se refieren al tiempo regular del partido (tiempos suplementarios y tiros penales exclusos).
            </p>
            <h5>Pasapalo</h5>
            <p>
                El resultado de las apuestas se basa en el resultado final del encuentro, tiempos suplementarios y shoot outs excluido.
            </p>
            <h5>Boxe/Mma</h5>
            <p>
                El resultado de la apuestas se determina por el resultado final del encuentro. Si el mismo fuera cancelado, postergado o interrumpido y no recuperado dentro de las 24 horas, el evento será declarado void. En el caso de empate al finalizar el encuentro, el mismo será declarado void.
            </p>
            <h5>Beach Soccer</h5>
            <p>
                El resultado de las apuestas se determina por el resultado final del encuentro. Si el mismo fuera cancelado, postergado o interrumpido y no recuperado dentro de las 24 horas, el evento será declarado void
            </p>
        </div>
      
    </div>
  );
};

export default ReglasDeportivas;