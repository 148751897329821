import React, { useContext, useState, useEffect } from 'react';
import '../assets/css/adminDashboard.css';
import { UserContext } from './UserContext';
import { getPermissionByName, updatePermissionByName } from '../../services/RoleServices/permissions';

const AdminDashboard = () => {
  const { currentUser } = useContext(UserContext);
  const [telepagosEnabled, setTelepagosEnabled] = useState(null);
  const [transfersEnabled, setTransfersEnabled] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Cargar los estados iniciales desde la base de datos
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        setLoading(true);
        const telepagos = await getPermissionByName('TELEPAGOS');
        const transfers = await getPermissionByName('TRANSFERS');
        setTelepagosEnabled(telepagos.status);
        setTransfersEnabled(transfers.status);
      } catch (err) {
        setError('Error al cargar los permisos');
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
  }, []);

  // Manejar el cambio del toggle de Telepagos
  const toggleTelepagos = async () => {
    try {
      setLoading(true);
      const updatedTelepagos = await updatePermissionByName('TELEPAGOS', !telepagosEnabled);
      setTelepagosEnabled(updatedTelepagos.status);
    } catch (err) {
      setError('Error al actualizar el permiso de TELEPAGOS');
    } finally {
      window.location.reload();
      setLoading(false);
    }
  };

  // Manejar el cambio del toggle de Transfers
  const toggleTransfers = async () => {
    try {
      setLoading(true);
      const updatedTransfers = await updatePermissionByName('TRANSFERS', !transfersEnabled);
      setTransfersEnabled(updatedTransfers.status);
    } catch (err) {
      setError('Error al actualizar el permiso de TRANSFERS');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <p>Cargando...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="admin-dashboard-container">
      <main className="dashboard-main">
        <h1>Bienvenido al Panel Administrativo</h1>
        <p>Aquí puedes gestionar las funcionalidades del casino.</p>

        <div>
          <label htmlFor="balance">Balance Actual:</label>
          <input
            type="text"
            id="balance"
            value={currentUser?.balance ?? 'No disponible'}
            readOnly
            disabled
          />
        </div>

        <div className="wallet-toggle-container">
          <label htmlFor="telepagosToggle">Habilitar/Deshabilitar Autogestión Telepagos</label>
          <label className="switch">
            <input
              type="checkbox"
              id="telepagosToggle"
              checked={telepagosEnabled}
              onChange={toggleTelepagos}
            />
            <span className="slider round"></span>
          </label>
        </div>

        <div className="wallet-toggle-container">
          <label htmlFor="transfersToggle">Habilitar/Deshabilitar Transferencias Entre Jugadores</label>
          <label className="switch">
            <input
              type="checkbox"
              id="transfersToggle"
              checked={transfersEnabled}
              onChange={toggleTransfers}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </main>
    </div>
  );
};

export default AdminDashboard;
