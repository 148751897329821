// components/Navbar.js
import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link, redirect, useNavigate, useLocation  } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/NavBar.css';
import Modal from 'react-modal';
import logo from '../assets/images/logo4kloboallado.png';
import miperfil from '../assets/images/miperfil.png';
import { useUser } from '../contexts/UserContext'; // Asume que tienes un UserProvider definido
import DepositoModal from './DepositoModal';
import RetiroModal from './RetiroModal';
import TransferirModal from './TransferirModal';
import RetiroModalUser from './RetiroModalUser';
import ProfileModal from './ProfileModal';
import { getPlayers, createPlayer, loginUnified } from '../services/UserServices/player';
import { getPermissionByName } from '../services/RoleServices/permissions';
import { fetchEvenBetRedirectUrl } from '../services/evenBetService';
import { toast } from 'react-toastify';


// Aseguramos que react-modal tenga el elemento root
Modal.setAppElement('#root');

const Navbar = () => {
  const location = useLocation();
  const isVIPPage = location.pathname.startsWith('/vip');
  const { user, login, logout } = useUser(); // Contexto para manejar usuario
  const navigate = useNavigate();
  const showVIPLink = user && user.balance >= 1000000;
  const [showRegister, setShowRegister] = useState(false); // Estado para modal de registro
  const [showLogin, setShowLogin] = useState(false); // Estado para modal de inicio de sesión
  const [showWalletOptions, setShowWalletOptions] = useState(false); // Estado para modal de inicio de sesión
  const [showDeposito, setShowDeposito] = useState(false); // Estado para modal de depósito
  const [showRetiro, setShowRetiro] = useState(false); // Estado para modal de retiro
  const [showTransferir, setShowTransferir] = useState(false); // Estado para modal de trasnferir
  const [telepagosEnabled, setTelepagosEnabled] = useState(null);
  const [transfersEnabled, setTransfersEnabled] = useState(null);
  const [showProfile, setShowProfile] = useState(false); // Estado para modal de perfil
  const [formData, setFormData] = useState({}); // Estado para formulario
  const [players, setPlayers] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isCreatePlayerOpen, setIsCreatePlayerOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [showRetiroModal, setShowRetiroModal] = useState(false);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showMaintenanceMessage, setShowMaintenanceMessage] = useState(false);
  const tooltipTimeoutRef = useRef(null);
  const [newPlayerData, setNewPlayerData] = useState({
      cuil: '',
      player_name: '',
      password: '',
      phone: '',
      balance: 0,
      cashier_id: 1
  });
  const [newPlayerDatafromCashier, setNewPlayerDatafromCashier] = useState({
    cuil: '',
    player_name: '',
    password: '',
    phone: '',
    balance: 0,
    cashier_id: ''
});
  const [credentials, setCredentials] = useState({
      identifier: '',
      password: ''
  });
  
  const [error, setError] = useState('');

  const profileIconRef = useRef(null);

  const getLoggedInCashierId = () => {
    // Supongamos que el estado global contiene información del usuario
    const cashier = JSON.parse(localStorage.getItem('loggedInCashier'));
    return cashier?.id || null; // Devuelve el ID del cajero actual
  };

  const fetchPlayers = async () => {
    try {
      const playersData = await getPlayers();
      setPlayers(playersData);
    } catch (error) {
      console.error('Error al obtener jugadores:', error);
    }
  };


  // Manejo de tooltip
  const handleFocus = () => {
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current); // Evita que el tooltip se cierre si sigue en el campo
    }
    setShowTooltip(true);
  };
  
  const handleBlur = () => {
    tooltipTimeoutRef.current = setTimeout(() => {
      setShowTooltip(false);
    }, 500); // Agrega un pequeño retraso antes de ocultarlo
  };


  const handleMouseEnter = () => {
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current); // No cerrar si el usuario pasa sobre el tooltip
    }
  };
  
  const handleMouseLeave = () => {
    tooltipTimeoutRef.current = setTimeout(() => {
      setShowTooltip(false);
    }, 500); // Espera medio segundo antes de cerrar
  };


  // Alternar entre modales
  const toggleModals = () => {
    setIsLoginModalOpen((prev) => !prev);
    setIsCreateModalOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!isLoginModalOpen) {
      setName('');
      setPassword('');
      setErrors({});
      setServerError('');
    }
  }, [isLoginModalOpen]);

    // Obtener el estado de los permisos al abrir la wallet
    useEffect(() => {
      const fetchPermissions = async () => {
        if (!showWalletOptions) return; // Solo ejecuta cuando se muestra la wallet
        try {
          setLoading(true);
          const telepagos = await getPermissionByName('TELEPAGOS');
          const transfers = await getPermissionByName('TRANSFERS');
          setTelepagosEnabled(telepagos.status);
          setTransfersEnabled(transfers.status);
        } catch (err) {
          setError('Error al cargar los permisos');
        } finally {
          setLoading(false);
        }
      };
  
      fetchPermissions();
    }, [showWalletOptions]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };


  const resetLoginFields = () => {
    setCredentials({ identifier: '', password: '' });
    setShowPassword(false);
    setError('');
    setServerError('');
  };

  const handleCloseLoginModal = () => {
    resetLoginFields(); // 🔹 Limpiar campos antes de cerrar
    setIsLoginModalOpen(false);
  };

  //LOGIN USUARIOS
  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    
    try {
      const user = await loginUnified(credentials);
      console.log('Login successful:', user);
  
      // Si el usuario tiene role_id === 4 (player), obtiene la URL de EvenBet
      if (user.role_id === 4) {
        const redirectUrl = await fetchEvenBetRedirectUrl(user.id, user.player_name);
        console.log('URL obtenida para el usuario:', redirectUrl);
        login(user, redirectUrl); // Guarda el usuario junto con la URL en el contexto
      } else {
        login(user); // Guarda solo los datos del usuario
      }
  
      setIsLoginModalOpen(false);
      resetLoginFields();
  
      if (user.role_id === 3) {
        navigate('/cashier'); // Redirige a la ruta cashier
      } else {
        navigate('/'); 
      }
  
      // Mostrar mensaje de éxito con toast
      toast.success(`Inicio de sesion exitoso. \nBienvenido, ${user.player_name || user.cashier_name}!`);
  
    } catch (err) {
      console.error('Login error:', err);
  
      // Detectar tipo de error y mostrar mensaje adecuado
      let errorMessage = 'Error al iniciar sesión. Inténtalo de nuevo.';
      if (err.response && err.response.status === 401) {
        errorMessage = 'Contraseña incorrecta.';
      } else if (err.response && err.response.status === 404) {
        errorMessage = 'Usuario no encontrado.';
      } else if (err.message) {
        errorMessage = err.message; // Mostrar mensaje de error del servidor si existe
      }
  
      setError(errorMessage);
      toast.error(errorMessage); // Mostrar mensaje de error con toast
    }
  };

  
  // REGISTRO DE JUGADORES
  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
    setNewPlayerData({ cuil: '', player_name: '', password: '', phone: '', balance: 0, cashier_id: 1 });
    window.location.reload();
  };

  const handleCreatePlayer = async () => {
      try {
        // Aquí deberías implementar la lógica para enviar los datos del nuevo jugador a la API
        const newPlayer = await createPlayer(newPlayerData); // Reemplaza `createPlayer` con tu función para la creación de jugadores.
        setPlayers([...players, newPlayer]);
        
        handleCloseCreateModal();
        toast.success("REGISTRO EXITOSO. Ahora puede INICIAR SESION.")
        setIsCreateModalOpen(false);
      } catch (err) {
        toast.error('REGISTRO FALLIDO.');
      }
  };


  //CREAR NUEVO JUGADOR COMO CAJERO
  const closeCreatePlayer = () => {
    const currentCashierId = getLoggedInCashierId();
    setIsCreatePlayerOpen(false);
    setNewPlayerDatafromCashier({ cuil: '', player_name: '', password: '', phone: '', balance: 0, cashier_id: currentCashierId });
    window.location.reload();
  };

  const handleCreatePlayerCashier = async () => {
      try {
        // Aquí deberías implementar la lógica para enviar los datos del nuevo jugador a la API
        const newPlayer = await createPlayer(newPlayerDatafromCashier); // Reemplaza `createPlayer` con tu función para la creación de jugadores.
        setPlayers([...players, newPlayer]);
        
        closeCreatePlayer();
        toast.success('JUGADOR CREADO EXITOSAMENTE')
        setIsCreatePlayerOpen(false);
      } catch (err) {
        toast.error('Error al crear jugador.');
      }
  };


  

  return (
    <>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
      <link href="https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap" rel="stylesheet"></link>
      <nav className={`navbar ${isVIPPage ? 'vip-navbar' : ''}`}>
        
          <div className="navbar-icon-and-logo">
              {/* Toggle button for mobile */}
              <button
                className="navbar-icon"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <li className='logocontainer'>
                {/* Logo */}
                <Link  to="/">
                  <img className="logo" src={logo} alt="Logo PlayBet" />
                </Link>
              </li> 
          </div>   
          
          <div className="navbar-options">
            {/* Links */}
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {user && user.role_id === 3 ? (
                  <>
                      {/* OPCIONES CAJEROS */}
                      <button
                        className="wallet-button text"
                        onClick={() => setIsCreatePlayerOpen(true)} // Abre el modal
                      >
                        Nuevo Jugador
                      </button>

                      {/* Modal de Crear Jugador */}
                      <Modal
                        isOpen={isCreatePlayerOpen}
                        onRequestClose={() => closeCreatePlayer()}
                        contentLabel="Crear Jugador"
                        className="custom-modal"
                        overlayClassName="custom-overlay"
                        style={{
                          content: {
                            backgroundColor: 'rgb(0, 155, 155)',
                            borderRadius: '10px',
                            padding: '20px',
                            width: '400px',
                            maxWidth: '90%',
                            margin: 'auto',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            fontFamily: '"Lexend", sans-serif',
                            border: '2px solid rgb(1, 97, 97)',
                          },
                          overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 1050,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          },
                        }}
                      >
                        <button className='p-close' onClick={() => setIsCreatePlayerOpen(false)}>X</button>
                        <h5>Crear Jugador</h5> 
                        <form onSubmit={(e) => { e.preventDefault(); handleCreatePlayerCashier(); }}>
                        <label>(*) Campos Obligatorios</label>
                            <label>
                              Nombre de usuario*:
                              <input
                                type="text"
                                value={newPlayerData.player_name}
                                onChange={(e) => setNewPlayerData({ ...newPlayerData, player_name: e.target.value })}
                                required
                              />
                            </label>
                            <label>
                              Contraseña*:
                              <input
                                type={showPassword ? 'text' : 'password'}
                                value={newPlayerData.password}
                                onChange={(e) => setNewPlayerData({ ...newPlayerData, password: e.target.value })}
                                required
                              />
                            </label>
                            <button
                                      type="button-show"
                                      className="ocultar-password"
                                      onClick={() => setShowPassword(!showPassword)}
                                  >
                                      {showPassword ? 'Ocultar' : 'Mostrar'}
                                  </button>
                            <label>
                              CUIL*:
                              <input
                                type="text"
                                placeholder='Ingrese CUIL sin puntos ni espacios'
                                value={newPlayerData.cuil}
                                onChange={(e) => setNewPlayerData({ ...newPlayerData, cuil: e.target.value })}
                                required
                              />
                            </label>
                            <label>
                              Teléfono:
                              <input
                                type="text"
                                value={newPlayerData.phone}
                                onChange={(e) => setNewPlayerData({ ...newPlayerData, phone: e.target.value })}
                              />
                            </label>
                            <button className="botonConfirmar" type="submit">Crear Jugador</button>
                            
                          </form>
                      </Modal>
                      <button
                        className="wallet-button text"
                        onClick={() => setShowTransferir(true)} // Abre el modal
                      >
                        Depositar
                      </button>
                      <button
                        className="wallet-button text"
                        onClick={() => setShowRetiroModal(true)} // Abre el modal
                      >
                        Retiro
                      </button>
                      {/*MODAL RETIRO USUARIOS*/}
                      <RetiroModalUser show={showRetiroModal} handleClose={() => setShowRetiroModal(false)} fetchPlayers={fetchPlayers} />
                  </>
                ) : (
                  <>
                    {/* Muestra las opciones estándar si el rol no es 3 */}
                    <li className="nav-item">
                      <Link className="nav-link" to="/">
                        Inicio
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/tragamonedas">
                        CASINO
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/promociones">
                        Promociones
                      </Link>
                    </li>
                  {/* Mostrar el enlace VIP solo si el usuario cumple la condición */}
                  {showVIPLink && (
                    <li className="nav-item">
                      <Link to="/vip" className="vip-link">
                        SALON V.I.P.
                      </Link>
                    </li>
                  )}
                  </>
                )}
              </ul>
            </div>  
          </div>
        
          
          {/* Auth buttons */}
          <div className="navbar-auth">
            {user ? (
              <>
                
                <div className="wallet-container">
                  <button
                    className="wallet-button text"
                    onClick={() => setShowWalletOptions(true)} // Abre el modal
                  >
                    Billetera
                  </button>
                  <div className="wallet-balance">
                    ${user.balance}
                  </div>
                </div>
                {user.role_id === 3 ? (
                  <span id='saludo' className="text me-2">Hola, {user.cashier_name}</span>
                ):(
                  <span id='saludo' className="text me-2">Hola, {user.player_name}</span>
                )}
                
              </>
            ) : (
              <>
                <button className="button me-2 text" onClick={() => setIsCreateModalOpen(true)}>Registro</button>
                <button className="buttonIS" onClick={() => setIsLoginModalOpen(true)}>Iniciar Sesión</button>
              </>
            )}
            {showMaintenanceMessage && (
              <div className="maintenance-message">
                La billetera actualmente se encuentra en mantenimiento.
              </div>
            )}

            {/* Modal para Depósito y Retiro */}
            {showWalletOptions && (
              <div className="wallet-overlay" onClick={() => setShowWalletOptions(false)}>
                <div className="wallet-content" onClick={(e) => e.stopPropagation()}>
                  <h2>Billetera</h2>

                {telepagosEnabled !== false && ( 
                  <>
                    <button className="buttonDeposito text" onClick={() => setShowDeposito(true)}>Depositar</button>
                    <button className="buttonRetiro text" onClick={() => setShowRetiro(true)}>Retirar</button>
                  </>
                )}  
                
                {transfersEnabled !== false && user.role_id !== 3 && (
                  <button className="buttonTransferir text" onClick={() => setShowTransferir(true)}>Transferir</button>
                )}
                  <button className="buttonCS text" onClick={() => setShowWalletOptions(false)}>Cerrar</button>
                </div>
              </div>
            )}
            {/* Ícono de perfil (solo aparece si el usuario está autenticado) */}
            {user && (
                <li>
                  {/* MiPerfil */}
                  <button ref={profileIconRef} className="buttonPerfil" onClick={() => setShowProfile(true)}>
                    {window.innerWidth <= 768 ? <span>|||</span> : 
                    <img src={miperfil} alt="Miperfil" className="miperfil"/>} 
                    
                  </button>
                </li>
            )}
          </div>
        
      </nav>

      {/* Modal de Inicio de Sesión */}
      <Modal
        isOpen={isLoginModalOpen}
        onRequestClose={handleCloseLoginModal}
        contentLabel="Formulario de Ingreso"
        className="custom-modal"
        overlayClassName="custom-overlay"
        style={{
          content: {
            color: "white",
            backgroundColor: 'rgb(0, 155, 155)',
            borderRadius: '10px',
            padding: '20px',
            width: '400px',
            maxWidth: '90%',
            margin: 'auto',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            fontFamily: '"Lexend", sans-serif',
            border: '2px solid rgb(1, 97, 97)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1050,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        
        <div>
          <h5>Iniciar Sesion</h5>
          <form onSubmit={handleLogin}>
            <label>
              Nombre de Usuario <span className="required">*</span>
            </label>
            <input
            type="text"
            id="identifier"
            name="identifier"
            placeholder='Nombre de usuario'
            value={credentials.identifier}
            onChange={handleChange}
            required
          />
            {errors.name && <small className="error-message">{errors.name}</small>}

            <label>
              Contraseña <span className="required">*</span>
            </label>
            <div className="password-container">
            <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                placeholder='Ingrese Contraseña'
                value={credentials.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="ocultar-password"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? 'Ocultar' : 'Mostrar'}
              </button>
            </div>
            {serverError && <small className="error-message">{serverError}</small>}

            <button type="submit" className="modal-button" disabled={loading}>
              {loading ? 'Cargando...' : 'Ingresa'}
            </button>
          </form>
          <button className="boton-link mt-2" onClick={toggleModals}>
          ¿No tienes cuenta? Registrate
        </button>
        </div>
      </Modal>

      {/* Modal de Registro */}
      <Modal
        isOpen={isCreateModalOpen}
        onRequestClose={() => handleCloseCreateModal()}
        contentLabel="Registro"
        className="custom-modal"
        overlayClassName="custom-overlay"
        style={{
          content: {
            backgroundColor: 'rgb(0, 155, 155)',
            borderRadius: '10px',
            padding: '20px',
            width: '400px',
            maxWidth: '90%',
            margin: 'auto',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            fontFamily: '"Lexend", sans-serif',
            border: '2px solid rgb(1, 97, 97)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1050,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <h5>Regístrate</h5>
        <form onSubmit={(e) => { e.preventDefault(); handleCreatePlayer(); }}>
            <label>(*) Campos Obligatorios</label>
            <label>
              Nombre de usuario*:
              <input
                type="text"
                value={newPlayerData.player_name}
                onChange={(e) => setNewPlayerData({ ...newPlayerData, player_name: e.target.value })}
                required
              />
            </label>
            <label>
              Contraseña*:
              <input
                type={showPassword ? 'text' : 'password'}
                value={newPlayerData.password}
                onChange={(e) => setNewPlayerData({ ...newPlayerData, password: e.target.value })}
                required
              />
            </label>
            <button
                type="button-show"
                className="ocultar-password"
                onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? 'Ocultar' : 'Mostrar'}
            </button>
            <label>
              CUIL*:
              <div className="tooltip-container">
                <input
                  type="text"
                  placeholder="Ingrese CUIL sin puntos ni espacios"
                  value={newPlayerData.cuil}
                  onChange={(e) => setNewPlayerData({ ...newPlayerData, cuil: e.target.value })}
                  required
                  onFocus={handleFocus}  // Mostrar tooltip
                  onBlur={handleBlur}  // Ocultar tooltip
                />
                {showTooltip && (
                  <div 
                  className="tooltip"
                  onMouseEnter={handleMouseEnter} // No cerrar si pasa sobre el tooltip
                  onMouseLeave={handleMouseLeave} // Cerrar si el usuario sale del tooltip
                  >
                    ⚠️ Advertencia: El CUIL que ingrese debe coincidir con el de la cuenta desde donde hará las transferencias.  
                    Una vez registrado, no podrá editarlo.
                  </div>
                )}
              </div>
            </label>
            <label>
              Teléfono:
              <input
                type="text"
                value={newPlayerData.phone}
                onChange={(e) => setNewPlayerData({ ...newPlayerData, phone: e.target.value })}
              />
            </label>
            <button className="botonConfirmar" type="submit">Registrar</button>
          </form>
        <button className="boton-link mt-2" onClick={toggleModals}>
          ¿Ya tienes una cuenta? Inicia Sesión
        </button>
      </Modal>

      {/* Modal de Depósito */}
      <DepositoModal show={showDeposito} handleClose={() => setShowDeposito(false)} className="custom-modal"  overlayClassName="custom-overlay"
          style={{
            content: {
              backgroundColor: 'rgb(0, 155, 155)', // Aplica color de fondo
              borderRadius: '10px',
              padding: '20px',
              width: '400px',
              maxWidth: '90%',
              margin: 'auto',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              fontFamily: '"Lexend", sans-serif',
              border: '2px solid rgb(1, 97, 97)',
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo de overlay
              zIndex: 1050, // Asegura la capa
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}/>

      {/* Modal de Retiro */}
      <RetiroModal show={showRetiro} handleClose={() => setShowRetiro(false)} />

      {/* Modal de Transferir */}
      <TransferirModal show={showTransferir} handleClose={() => setShowTransferir(false)} fetchPlayers={fetchPlayers} />

      {/* Modal de Profile */}
      <ProfileModal 
        isOpen={showProfile}
        onClose={() => setShowProfile(false)} 
        user={user}
        logout={logout}
        profileIconRef={profileIconRef}/>  
    </>
  );
};

export default Navbar;
