import React, { useState } from 'react';
import { UserProvider, useUser } from '../contexts/UserContext';
import { Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './NavBar';
import Footer from './FooterApp';
import Home from '../pages/home';
import Deportes from '../pages/deportes';
import Tragamonedas from '../pages/tragamonedas';
import PokerOnline from '../pages/pokerOnline';
import MiPerfil from '../pages/miperfil';
import CashierPage from '../pages/CashierPage';
import CashierProfile from '../pages/CashierProfile';
import RegisterModal from '../pages/RegisterModal';
import Mayor18 from './Mayor18';
import ProtectedRoute from '../components/ProtectedRoute'; // Asegúrate de que la ruta sea correcta
import { ToastContainer } from 'react-toastify';
import ReglasDeportivas from './footerRoutes/ReglasDeportivas';
import LavadoDinero from './footerRoutes/LavadoDinero';
import TerminosCondiciones from './footerRoutes/TerminosYCondiciones';
import Privacidad from './footerRoutes/Privacidad';
import ValidacionApuestas from './footerRoutes/ValidacionApuestas';
import ReglasJuego from './footerRoutes/ReglasJuego';
import Glosario from './footerRoutes/Glosario';
import JuegoResponsable from './footerRoutes/JuegoResponsable';
import QuienesSomos from './footerRoutes/QuienesSomos';
import Promociones from './Promociones';
import ScrollToTopButton from "./ScrollToTopButton";
import VIPLayout from '../layouts/VIPLayout';
import VIPRoom from '../pages/VIPRoom';

const AppRoutes = () => {
  const { user } = useUser(); // Accede al usuario autenticado desde el contexto

  return (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/deportes" element={<Deportes />} />
          <Route path="/tragamonedas" element={<Tragamonedas />} />
          <Route path="/pokerOnline" element={<PokerOnline />} />

          <Route path="/promociones" element={<Promociones />} />

          <Route path="/register" element={<RegisterModal />} />

          {/* Rutas footer*/}
          <Route path="/reglas-deportivas" element={<ReglasDeportivas />} />
          <Route path="/lavado-de-dinero" element={<LavadoDinero />} />
          <Route path="/terminos-y-condiciones" element={<TerminosCondiciones />} />
          <Route path="/privacidad" element={<Privacidad />} />
          <Route path="/validacion-apuestas" element={<ValidacionApuestas />} />
          <Route path="/reglas-del-juego" element={<ReglasJuego />} />
          <Route path="/glosario" element={<Glosario />} />
          <Route path="/juego-responsable" element={<JuegoResponsable />} />
          <Route path="/quienes-somos" element={<QuienesSomos />} />




          {/* Rutas protegidas según rol */}
          <Route
            path="/miperfil"
            element={
              <ProtectedRoute isAuthenticated={!!user} requiredRole={4}>
                <MiPerfil />
              </ProtectedRoute>
            }
          />
          
          <Route
            path="/cashier"
            element={
              <ProtectedRoute isAuthenticated={!!user} requiredRole={3}>
                <CashierPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/cashier/cashierProfile"
            element={
              <ProtectedRoute isAuthenticated={!!user} requiredRole={3}>
                <CashierProfile />
              </ProtectedRoute>
            }
          />

          <Route
            path="/vip"
            element={
              <VIPLayout>
                <VIPRoom />
              </VIPLayout>
            }
          />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    
  );
};

function App() { 
  return (
    <UserProvider>
      <ToastContainer position="top-right" autoClose={3000} />
      <Navbar />
      <Mayor18 />
      <AppRoutes />
      <Footer />
      <ScrollToTopButton />
    </UserProvider>
  );
}

export default App;