// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* adminLayout.css */
.admin-layout {
    display: flex;
}
  
.admin-content {
  margin-left: 0;
  padding: 20px;
  flex: 1 1;
  background-color: #f8f9fa;
  transition: margin-left 0.3s ease-in-out;
}


/* Botón de menú tipo hamburger */
.hamburger-menu {
  position: fixed;
  top: 20px;
  left: 20px;
  background: #2c3e50;
  color: white;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1100;
  /*display: none;  Por defecto, oculto en escritorio */
}
  

@media (min-width: 768px) {
  .admin-content {
      margin-left: 250px;
  }
  
   /*.hamburger-menu {
      display: none; 
  } */
}

/* En pantallas pequeñas (móviles), oculta la barra lateral */
@media (max-width: 768px) {
  .hamburger-menu {
    display: block; /* Mostrar el botón en móviles */
  }
  
  .admin-content {
    margin-left: 0; /* Asegura que el contenido ocupe todo el ancho */
  }
}
  `, "",{"version":3,"sources":["webpack://./src/playbet-admin/assets/css/adminLayout.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,aAAa;AACjB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,SAAO;EACP,yBAAyB;EACzB,wCAAwC;AAC1C;;;AAGA,iCAAiC;AACjC;EACE,eAAe;EACf,SAAS;EACT,UAAU;EACV,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,aAAa;EACb,qDAAqD;AACvD;;;AAGA;EACE;MACI,kBAAkB;EACtB;;GAEC;;KAEE;AACL;;AAEA,6DAA6D;AAC7D;EACE;IACE,cAAc,EAAE,gCAAgC;EAClD;;EAEA;IACE,cAAc,EAAE,iDAAiD;EACnE;AACF","sourcesContent":["/* adminLayout.css */\n.admin-layout {\n    display: flex;\n}\n  \n.admin-content {\n  margin-left: 0;\n  padding: 20px;\n  flex: 1;\n  background-color: #f8f9fa;\n  transition: margin-left 0.3s ease-in-out;\n}\n\n\n/* Botón de menú tipo hamburger */\n.hamburger-menu {\n  position: fixed;\n  top: 20px;\n  left: 20px;\n  background: #2c3e50;\n  color: white;\n  border: none;\n  font-size: 1.5rem;\n  cursor: pointer;\n  z-index: 1100;\n  /*display: none;  Por defecto, oculto en escritorio */\n}\n  \n\n@media (min-width: 768px) {\n  .admin-content {\n      margin-left: 250px;\n  }\n  \n   /*.hamburger-menu {\n      display: none; \n  } */\n}\n\n/* En pantallas pequeñas (móviles), oculta la barra lateral */\n@media (max-width: 768px) {\n  .hamburger-menu {\n    display: block; /* Mostrar el botón en móviles */\n  }\n  \n  .admin-content {\n    margin-left: 0; /* Asegura que el contenido ocupe todo el ancho */\n  }\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
