import React from 'react';

const VIPRoom = () => {
  return (
    <div>
      <h2>Bienvenido a la Sala VIP</h2>
      <p>Disfruta de beneficios exclusivos.</p>
    </div>
  );
};

export default VIPRoom;
