import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { creditBalancePlayer, debitBalancePlayer, getPlayers } from '../services/UserServices/player';
import { toast } from 'react-toastify';
import { debitBalanceCashier } from '../services/UserServices/cashier';
import { createMovimiento } from '../services/ReportServices/movimientos';
import { useUser } from '../contexts/UserContext';
import { jsPDF } from "jspdf"; 
import '../assets/css/Modales.css';

const TransferirModal = ({ show, handleClose }) => {
  const { user, isAuthenticated, setUser } = useUser(); // Usuario actual, estado de autenticación y función para actualizar el usuario
  const [inputValue, setInputValue] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [players, setPlayers] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false); // Control del modal de confirmación
  const [showDownloadModal, setShowDownloadModal] = useState(false);  
  const pdfRef = useRef(null);

  // Obtener y filtrar jugadores al mostrar el modal
  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const allPlayers = await getPlayers();

        if (user.role_id === 3) {
          const filteredPlayers = allPlayers.filter(
            (player) => player.cashier_id === user.id
          );
          setPlayers(filteredPlayers);
        } else {
          setPlayers(allPlayers);
        }
      } catch (error) {
        console.error('Error al obtener jugadores:', error);
      }
    };

    if (show) fetchPlayers();
  }, [show, user]);

  // Limpiar los campos al cerrar el modal
  const resetFields = () => {
    setInputValue('');
    setSelectedUser(null);
    setAmount('');
    setPlayers([]);
    setLoading(false);
    setShowConfirmationModal(false); // Oculta el modal de confirmación
  };


  // Efecto para limpiar los campos al cerrar sesión
  useEffect(() => {
    if (!isAuthenticated) {
      resetFields();
    }
  }, [isAuthenticated]);

  const handleModalClose = () => {
    resetFields();
    handleClose(); // Cierra el modal
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    const filteredUsers = players.filter(
      (player) =>
        player.player_name.toLowerCase().includes(value.toLowerCase()) ||
        player.cuil.includes(value)
    );

    setSelectedUser(filteredUsers.length === 1 ? filteredUsers[0] : null);
  };

  const openConfirmationModal = () => {
    if (!selectedUser || !amount || parseFloat(amount) <= 0) {
      toast.error('Por favor, complete todos los campos correctamente.');
      return;
    }

    if (parseFloat(user.balance) < parseFloat(amount)) {
      toast.error('Saldo insuficiente para la transferencia.');
      return;
    }

    setShowConfirmationModal(true);
  };

  const handleTransfer = async () => {
    setLoading(true);
    try {
      const transferAmount = parseFloat(amount);
      let updatedBalanceSender;
      let updatedBalanceReceiver = parseFloat(selectedUser.balance) + transferAmount;


      // Diferenciar la lógica según el role_id del usuario emisor
      if (user.role_id === 3) {
        // Usuario es un cajero: usar debitBalanceCashier
        updatedBalanceSender = parseFloat(user.balance) - transferAmount;
        await debitBalanceCashier(user.id, updatedBalanceSender);
      } else {
        // Usuario no es cajero: usar debitBalancePlayer
        updatedBalanceSender = parseFloat(user.balance) - transferAmount;
        await debitBalancePlayer(user.id, updatedBalanceSender);
      }

      // Actualizar el balance en el contexto
      setUser((prevUser) => ({ ...prevUser, balance: updatedBalanceSender }));

      // Acreditar saldo al receptor
      await creditBalancePlayer(selectedUser.id, updatedBalanceReceiver);

      const baseTransactionId = `TX-${Date.now()}`;
      const transactionIdRealizada = `${baseTransactionId}-R`; // Para realizada
      const transactionIdRecibida = `${baseTransactionId}-B`; // Para recibida

      // Registrar movimiento de "TRANSFERENCIA REALIZADA"
      const transferenciaRealizada = {
          id_sender: user.id,
          id_receptor: selectedUser.id,
          id_cashier: user.role_id === 3 ? user.user_id : user.cashier_id, // Si el usuario es cajero
          amount: transferAmount,
          balance_before: parseFloat(user.balance),
          balance_after: updatedBalanceSender,
          transaction_id: transactionIdRealizada,
          description: "TRANSFERENCIA REALIZADA",
          fecha_hora: new Date().toISOString(),
          sender_type: user.role_id === 3 ? "cashier" : "player", // Tipo del emisor
          receiver_type: "player", // Tipo del receptor
      };

      await createMovimiento(transferenciaRealizada);

      // Registrar movimiento de "TRANSFERENCIA RECIBIDA"
      const transferenciaRecibida = {
          id_sender: user.id,
          id_receptor: selectedUser.id,
          id_cashier: selectedUser.cashier_id,
          amount: transferAmount,
          balance_before: parseFloat(selectedUser.balance),
          balance_after: updatedBalanceReceiver,
          transaction_id: transactionIdRecibida,
          description: "TRANSFERENCIA RECIBIDA",
          fecha_hora: new Date().toISOString(),
          sender_type: user.role_id === 3 ? "cashier" : "player", // Tipo del emisor
          receiver_type: "player", // Tipo del receptor
      };

      await createMovimiento(transferenciaRecibida);


      toast.success('Transferencia realizada con éxito.');
      handleModalClose();
      pdfRef.current = generatePDF();

      setShowDownloadModal(true); //
    } catch (error) {
      console.error('Error al transferir saldo:', error);
      toast.error('Error al realizar la transferencia. Intente nuevamente.');
    } finally {
      setLoading(false);
      setShowConfirmationModal(false); // Oculta el modal de confirmación
    }
  };


  // 📌 Función para generar y descargar el PDF
  const generatePDF = () => {
    const doc = new jsPDF();
    const date = new Date().toLocaleString();

    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text("Comprobante de Transferencia", 20, 20);

    doc.setFontSize(12);
    doc.text(`Fecha y Hora: ${date}`, 20, 35);
    doc.text(`Monto Transferido: $${parseFloat(amount).toFixed(2)}`, 20, 45);

    doc.setFont("helvetica", "normal");
    doc.text("Emisor:", 20, 60);
    doc.text(`Nombre de usuario: ${user.player_name || user.cashier_name}`, 20, 70);
    doc.text(`CUIL: ${user.cuil || 'N/A'}`, 20, 80);

    doc.text("Receptor:", 20, 100);
    doc.text(`Nombre de usuario: ${selectedUser?.player_name}`, 20, 110);
    doc.text(`CUIL: ${selectedUser?.cuil}`, 20, 120);

    return doc;
  };

  const savePDF = () => {
    if (pdfRef.current) {
      pdfRef.current.save(`Comprobante_Transferencia_${Date.now()}.pdf`);
    }
    setShowDownloadModal(false); // Cerrar modal después de guardar
  };



  return (
    <>
      <Modal show={show} onHide={handleModalClose} className="Forms">
        <Modal.Header className="FormsHeader" closeButton>
          <Modal.Title>Transferir</Modal.Title>
        </Modal.Header>
        <Modal.Body className="FormsBody">
          <Form>
            <Form.Group controlId="formCbuAlias">
              <Form.Label>Usuario a Transferir</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese el nombre de usuario o CUIL"
                value={inputValue}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formUserInfo">
              <Form.Label>Datos del Usuario</Form.Label>
              <Form.Control
                type="text"
                readOnly
                value={
                  loading
                    ? 'Buscando usuario...'
                    : selectedUser
                    ? `Usuario: ${selectedUser.player_name} | CUIL: ${selectedUser.cuil}`
                    : 'No se encontraron coincidencias'
                }
              />
            </Form.Group>
            <Form.Group controlId="formAmount">
              <Form.Label>Monto</Form.Label>
              <Form.Control
                type="number"
                placeholder="Ingrese el monto a transferir"
                value={amount}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!isNaN(value) && parseFloat(value) >= 0) {
                    setAmount(value);
                  }
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="FormsFooter">
          <Button className="botonCancelar gradient-borderCancel" onClick={handleModalClose}>
            Cancelar
          </Button>
          <Button
            className="botonConfirmar"
            onClick={openConfirmationModal}
            disabled={loading || !selectedUser || !amount}
          >
            {loading ? 'Procesando...' : 'Transferir'}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de Confirmación */}
      <Modal
        
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        centered
        className="Forms"
      >
        <Modal.Header className="FormsHeader" closeButton>
          <Modal.Title>Confirmar Transferencia</Modal.Title>
        </Modal.Header>
        <Modal.Body className="FormsBody">
          <p>
            ¿Está seguro de que desea transferir{' '}
            <strong>
              {new Intl.NumberFormat("es-AR", {
                style: "currency",
                currency: "ARS",
              }).format(amount)}
            </strong>{' '}
            a {selectedUser?.player_name}?
          </p>
        </Modal.Body>
        <Modal.Footer className="FormsFooter">
          <Button className="botonCancelar gradient-borderCancel" onClick={() => setShowConfirmationModal(false)}>
            Cancelar
          </Button>
          <Button className="botonConfirmar" onClick={handleTransfer}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de Descarga */}
      <Modal show={showDownloadModal} onHide={() => setShowDownloadModal(false)} centered className="Forms">
        <Modal.Header className="FormsHeader" closeButton>
          <Modal.Title>Comprobante de Transferencia</Modal.Title>
        </Modal.Header>
        <Modal.Body className="FormsBody">
          <p>¿Desea descargar un comprobante de la transferencia?</p>
        </Modal.Body>
        <Modal.Footer className="FormsFooter">
          <Button className="botonCancelar gradient-borderCancel" onClick={() => setShowDownloadModal(false)}>Cancelar</Button>
          <Button className="botonConfirmar" onClick={savePDF}>Sí, Descargar</Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default TransferirModal;
