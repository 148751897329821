// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-staff {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 90%;
  }
  
  .admin-staff h2 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
  }
  
  table th,
  table td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }
  
  table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .error-message {
    color: red;
    text-align: center;
    font-size: 16px;
  }
  `, "",{"version":3,"sources":["webpack://./src/playbet-admin/assets/css/adminStaff.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,cAAc;EAChB;;EAEA;;IAEE,sBAAsB;IACtB,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,UAAU;IACV,kBAAkB;IAClB,eAAe;EACjB","sourcesContent":[".admin-staff {\n    background-color: white;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n    margin: 20px auto;\n    max-width: 90%;\n  }\n  \n  .admin-staff h2 {\n    margin-bottom: 20px;\n    text-align: center;\n    font-size: 24px;\n  }\n  \n  table {\n    width: 100%;\n    border-collapse: collapse;\n    margin: 10px 0;\n  }\n  \n  table th,\n  table td {\n    border: 1px solid #ddd;\n    text-align: left;\n    padding: 8px;\n  }\n  \n  table th {\n    background-color: #f4f4f4;\n    font-weight: bold;\n  }\n  \n  table tr:nth-child(even) {\n    background-color: #f9f9f9;\n  }\n  \n  .error-message {\n    color: red;\n    text-align: center;\n    font-size: 16px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
