import React, { useEffect, useState } from 'react';
import { useUser } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

const Tragamonedas = () => {
  const [showModal, setShowModal] = useState(false); // Estado para controlar el modal
  const { user, isAuthenticated, login } = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('Estado del usuario en Tragamonedas:', { user, isAuthenticated });

    if (!user && !isAuthenticated) {
      // Intenta cargar desde sessionStorage si el contexto está vacío
      const storedUser = sessionStorage.getItem('currentUser');
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        console.log('Usuario recuperado desde sessionStorage:', parsedUser); // Log para depuración
        login(parsedUser, parsedUser.redirectUrl); // Recupera datos manualmente
        setLoading(false);
      } else {
        setLoading(false)
        setShowModal(true);
      }
    } else if (isAuthenticated && user?.redirectUrl) {
      setLoading(false);
    }
  }, [user, isAuthenticated, navigate, login]);

  const handleCloseModal = () => {
    setShowModal(false); // Cierra el modal
    navigate('/'); // Redirige al inicio
  };

  if (loading) {
    return <p>Cargando...</p>;
  }

  return (
    <div>
      {user?.redirectUrl ? (
        <iframe
          src={user.redirectUrl}
          title="EvenBet Casino"
          style={{
            marginTop: '20px',
            width: '100%',
            height: '80vh',
            border: 'none',
          }}
        />
      ) : (
        <p>No se encontró la URL de redirección. Por favor, intente de nuevo.</p>
      )}

      {/* Modal para usuarios no autenticados */}
      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        contentLabel="Acceso Denegado"
        style={{
          content: {
            width: '400px',
            height: '200px',
            margin: 'auto',
            textAlign: 'center',
            borderRadius: '10px',
            padding: '20px',
            backgroundColor: '#212121', // Gris oscuro para el fondo del contenido
            color: '#ffffff', // Texto blanco para contraste
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
        }}
      >
        <h2>Debes iniciar sesión</h2>
        <p>Para ingresar al casino, por favor inicia sesión.</p>
        <button onClick={handleCloseModal} style={{ padding: '10px 20px', marginTop: '20px', backgroundColor: "" }}>
          Cerrar
        </button>
      </Modal>

    </div>
  );
};

export default Tragamonedas;
