import React from 'react';
import '../../assets/css/footer.css';

const QuienesSomos = () => {
  return (
    <div className="footer-page">
        <div className='footer-page-title'>
            <h1>QUIÉNES SOMOS</h1>
        </div>
        <div className='footer-page-content'>  
            <p> 
                Play.bet.ar es un corredor de apuestas deportivas. Si usted está oyendo acerca de los corredores de apuestas plazo (corredores de apuestas para abreviar) por primera vez, un corredor de apuestas es una empresa o una persona que toma las apuestas y paga ganancias dependiendo de los resultados.
                Es todo acerca de la negociación en los resultados de varios encuentros deportivos. Esto depende de los resultados de los partidos. Cada partido y el resultado tienen sus propios precios (llamados odds).
                Ofrecemos una amplia gama de deportes en nuestra plataforma como el fútbol, el baloncesto y el tenis y plataformas Play.bet.ar.

                Play.bet.ar es administrado de forma segura y con el respaldo de un fuerte proveedor de soluciones de juego y no tienes nada de qué preocuparse.
                Play.bet.ar ofrece usted valora, la seguridad y el control y nuestro personal especializado se aseguran siempre de que nuestros clientes reciben un trato de primera clase porque nuestra política es responder siempre a todos los clientes las preguntas dentro de 24 horas desde el momento del primer contacto con nosotros.
                Puede consultar nuestra sección de preguntas más frecuentes (FAQ) si tiene más preguntas.
                Con el fin de unirse y utilizar Play.bet.ar, usted está obligado a confirmar que no está por debajo de la edad de 18 años.
                Estamos mirando adelante a oír de usted siempre. Tienen un tiempo fantástico usando nuestros servicios.
            </p>  
        </div>
      
    </div>
  );
};

export default QuienesSomos;