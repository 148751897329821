import React, { useEffect, useState, useContext } from 'react';
import { getUsers, deleteUser, editUser, createUser, getUserNameById, updateUserBalance } from '../../services/UserServices/user';
import Modal from 'react-modal';
import { UserContext } from './UserContext';
import '../assets/css/adminStaff.css';

Modal.setAppElement('#root');

const AdminStaff = () => {
  const { currentUser } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [formData, setFormData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [balance, setBalance] = useState(0);
  const [newUserData, setNewUserData] = useState({
    name: '',
    email: '',
    password: '',
    role_id: 0,
    parent_id: 1,
  });


  useEffect(() => {
    const fetchCashiersWithUsers = async () => {
      try {
        const usersData = await getUsers();
  
        // Carga los nombres de los cajeros para cada jugador
        const UsersWithParentsNames = await Promise.all(
          usersData.map(async (user) => {
            try {
              const ParentName = await getUserNameById(user.parent_id);
              return { ...user, parent_name: ParentName };
            } catch {
              return { ...user, parent_name: 'Sin Antepasado' };
            }
          })
        );
  
        setUsers(UsersWithParentsNames);
      } catch (err) {
        setError('Error al cargar los jugadores.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchCashiersWithUsers();
  }, []);

  const filteredUsers = users.filter((user) => {
    const term = searchTerm.toLowerCase();

    return (
        (user.id?.toString() || '').toLowerCase().includes(term) ||
        (user.email?.toLowerCase() || '').includes(term) ||
        (user.name?.toLowerCase() || '').includes(term) ||
        (user.parent_name?.toLowerCase() || '').includes(term) ||
        (user.role_id?.toString() || '').toLowerCase().includes(term)
      );
  });

  const formatDate = (isoDate) => {
    if (!isoDate) return 'N/A'; // Manejar valores nulos o indefinidos
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleOpenModal = (type, user) => {
    setModalType(type);
    setSelectedUser(user || null);
    setFormData(user || {});
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
    setFormData({});
    window.location.reload();
  };

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
    setNewUserData({ name: '', email: '', role_id: '' });
  };

  const handleCreateUser = async () => {
    try {
      const dataToSend = { ...newUserData };
      console.log(dataToSend);

      if (!dataToSend.name || !dataToSend.email || !dataToSend.password || !dataToSend.role_id || !dataToSend.parent_id) {
        alert('Todos los campos son obligatorios.');
        return;
      }

      const newUser = await createUser(dataToSend);
      setUsers([...users, newUser]);
      handleCloseCreateModal();
    } catch (err) {
      alert('Error al crear usuario.');
    }
  };

  const handleEditUser = async () => {
    try {
      const dataToSend = { ...formData };
      const updatedUser = await editUser(selectedUser.id, dataToSend);
      setUsers(users.map((user) => (user.id === selectedUser.id ? updatedUser : user)));
    } catch (err) {
      alert('Error al editar usuario.');
    } finally {
      handleCloseModal();
    }
  };

  const handleDeleteUser = async () => {
    try {
      await deleteUser(selectedUser.id);
      setUsers(users.filter((user) => user.id !== selectedUser.id));
    } catch (err) {
      alert('Error al eliminar usuario.');
    } finally {
      handleCloseModal();
    }
  };

  const handleBalanceChange = async (type) => {
    console.log("Entrando a handleBalanceChange. Tipo:", type, "Cajero seleccionado:", selectedUser);
    console.log("Usuario STAFF actual: ", currentUser);
    try {
      if (!selectedUser || !selectedUser.id) {
        console.error("Error: Cajero no seleccionado o falta ID.");
        alert("Seleccione un cajero válido antes de proceder.");
        return;
      }

      const currentUserBalance = parseFloat(selectedUser.balance || 0);
      console.log("Saldo actual del cajero:", currentUserBalance);

      // Convertir el balance ingresado a número
      const inputBalance = parseFloat(balance);
      if (isNaN(inputBalance) || inputBalance <= 0) {
        throw new Error("El monto ingresado debe ser un número válido mayor a 0.");
      }

      let updatedBalance = currentUserBalance;

      if (type === 'credit') {
        updatedBalance += balance; // Sumar el monto ingresado
      } else if (type === 'debit') {
        updatedBalance -= balance; // Restar el monto ingresado
        if (updatedBalance < 0) {
          throw new Error("El saldo no puede ser negativo."); // Validar que no sea negativo
        }
      } else {
        console.error("Tipo de operación inválido:", type);
        return;
      }

      console.log("Nuevo saldo calculado:", updatedBalance);

      if (type === 'credit') {
          await updateUserBalance(selectedUser.id, updatedBalance)
      }
      if (type === 'debit') {
          await updateUserBalance(selectedUser.id, updatedBalance)
      }

      const updatedUsers = await getUsers();
      setUsers(updatedUsers);
    } catch (err) {
      console.error("Error capturado en handleBalanceChange:", err.message, err);
      alert(err.message || 'Error al actualizar saldo.');
    } finally {
      handleCloseModal();
    }
  };

  return (
    <div className="admin-staff-container">
      <h1>Gestión de Usuarios (Admins y Staff)</h1>
      {loading && <p>Cargando usuarios...</p>}
      {error && <p className="error-message">{error}</p>}
      {!loading && !error && (
        <>
          <button className="action-button create" onClick={handleOpenCreateModal}>
            Crear Usuario
          </button>
          <input
            type="text"
            placeholder="Buscar por ID, Nombre o Email"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          {filteredUsers.length > 0 ? (
            <table className="users-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Email</th>
                  <th>Rol</th>
                  <th>Saldo</th>
                  <th>Fecha de Creación</th>
                  <th>Antepasado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
              {filteredUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.role_id === 1 ? 'Admin' : 'Staff'}</td>
                    <td>{user.role_id === 1 ? 'Infinito' : user.balance}</td>
                    <td>{formatDate(user.created_at)}</td>
                    <td>{user.parent_name}</td>
                    <td>
                        {/* Botones disponibles para TODOS los usuarios */}
                        <button className="action-button edit" onClick={() => handleOpenModal('edit', user)}>
                            Editar
                        </button>
                        <button
                            title="Cambiar Contraseña"
                            className="action-button changePassword"
                            onClick={() => handleOpenModal('changePassword', user)}
                        >
                            🔒
                        </button>

                        {/* Botones exclusivos para usuarios con role_id 2 (Staff) */}
                        {user.role_id === 2 && (
                            <>
                            <button className="action-button delete" onClick={() => handleOpenModal('delete', user)}>
                                Eliminar
                            </button>
                            <button className="action-button credit" onClick={() => handleOpenModal('credit', user)}>
                                Acreditar
                            </button>
                            <button className="action-button debit" onClick={() => handleOpenModal('debit', user)}>
                                Retirar
                            </button>
                            </>
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No se encontraron usuarios que coincidan con "{searchTerm}".</p>
          )}
        </>
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="modal-content">
          {modalType === 'edit' && (
            <>
              <h2>Editar Usuario</h2>
              <form onSubmit={(e) => { e.preventDefault(); handleEditUser(); }}>
                <label>
                  Nombre:
                  <input
                    type="text"
                    value={formData.name || ''}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  />
                </label>
                <label>
                  Email:
                  <input
                    type="email"
                    value={formData.email || ''}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  />
                </label>
                <button className="confirm-button" type="submit">Guardar</button>
              </form>
            </>
          )}
          {modalType === 'delete' && (
            <>
              <p>¿Estás seguro de que deseas eliminar al usuario "{selectedUser?.name}"?</p>
              <button className="confirm-button" onClick={handleDeleteUser}>Confirmar</button>
            </>
          )}
        {modalType === 'changePassword' && (
        <>
            <h2>Editar Contraseña</h2>
            <form onSubmit={(e) => { e.preventDefault(); handleEditUser(); }}>

            <label>
                Contraseña:
                <input
                value={formData.password || ''}
                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                />
            </label>

            <button className="confirm-button" type="submit">Guardar</button>
            </form>
        </>
        )}
                  {(modalType === 'credit' || modalType === 'debit') && (
            <>
              <h2>{modalType === 'credit' ? 'Acreditar Saldo' : 'Retirar Saldo'}</h2>
              <form onSubmit={(e) => { e.preventDefault(); handleBalanceChange(modalType); }}>
                <label>
                    Saldo actual: 
                    <span>
                    {selectedUser?.balance !== undefined ? ` $${selectedUser.balance} pesos` : 'Cargando...'}
                    </span>
                </label>
                <label>
                  Monto a {(modalType === 'credit' ? 'Acreditar' : 'Retirar')}:
                  <input
                    type='text'
                    value={balance}
                    onChange={(e) => setBalance(Number(e.target.value))}
                  />
                </label>
                <button className="confirm-button" type="submit">Confirmar</button>
              </form>
            </>
          )}
          <button className="close-button" onClick={handleCloseModal}>Cerrar</button>
        </div>
      </Modal>
      <Modal
        isOpen={isCreateModalOpen}
        onRequestClose={handleCloseCreateModal}
        contentLabel="Crear Usuario"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="modal-content">
          <h2>Crear Usuario</h2>
          <form onSubmit={(e) => { e.preventDefault(); handleCreateUser(); }}>
            <label>
              Nombre:
              <input
                type="text"
                value={newUserData.name}
                onChange={(e) => setNewUserData({ ...newUserData, name: e.target.value })}
                required
              />
            </label>
            <label>
              Email:
              <input
                type="email"
                value={newUserData.email}
                onChange={(e) => setNewUserData({ ...newUserData, email: e.target.value })}
                required
              />
            </label>
            <label>
              Contraseña:
              <input
                type="text"
                value={newUserData.password}
                onChange={(e) => setNewUserData({ ...newUserData, password: e.target.value })}
                required
              />
            </label>
            <label>
              Rol:
              <select
                value={newUserData.role_id}
                onChange={(e) => setNewUserData({ ...newUserData, role_id: parseInt(e.target.value, 10) })}
              >
                <option value={1}>Admin</option>
                <option value={2}>Staff</option>
              </select>
            </label>
            <label>
              Antepasado:
              <select
                value={newUserData.parent_id}
                onChange={(e) => setNewUserData({ ...newUserData, parent_id: parseInt(e.target.value, 10) })}
              >
                <option value={1}>Admin</option>
                <option value={2}>CRISAR</option>
              </select>
            </label>
            <button className="confirm-button" type="submit">Crear</button>
          </form>
          <button className="close-button" onClick={handleCloseCreateModal}>Cerrar</button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminStaff;
