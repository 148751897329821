import React from 'react';
import '../../assets/css/footer.css';

const LavadoDinero = () => {
  return (
    <div className="footer-page">
        <div className='footer-page-title'>
            <h1>CONTRA EL LAVADO DE DINERO</h1>
        </div>
        <div className='footer-page-content'>
            <h4>"EL LAVADO DE DINERO ES UN DELITO GRAVE PERSEGUIDO SEVERAMENTE POR LA LEY"</h4>

            <h5>Fútbol</h5>
            <p> 
                De acuerdo con las directrices internacionales para la prevención del blanqueo de capitales, www.play.bet.ar utilizará todos los medios a su alcance para identificar cualquier intento de blanquear dinero, participando cuando sea necesario con la comunicación inmediata con las autoridades competentes. Dado que la Directiva 2001/97/CE relativa a la prevención de la utilización del sistema financiero para el fin de blanquear las ganancias desde actividades ilícitas, extiende el ámbito de las obligaciones relativas a la identificación del cliente, registro y almacenamiento de datos y reporte de transacciones sospechosas, también para aquellos que ofrecen el juego a través de Internet y otras redes informáticas o de telecomunicaciones, juegos de azar, apuestas o competiciones con premios en efectivo, se disponen las siguientes.
            </p>
            <h5>Registro</h5>
            <p>
            A la luz de esa legislación, para tener acceso a la oferta de la empresa, es condición esencial registrarse como usuario; no se puede abrir una cuenta de apuestas a menores de edad o de forma anónima. La empresa necesariamente procederá a registrar y adquirir información sobre:
            Datos de identificación declarados por el cliente cuando se abren cuentas de juego o de la solicitud de las credenciales de acceso a los juegos en línea:
                * CUIL 
                Fecha de apertura y recarga de cuentas de juego y cobro de las mismas cuentas; el valor de las operaciones anteriores y los medios de pago que se utilizan.
                Dirección IP, la fecha, hora y duración de las conexiones telematicas en el curso de la cual el cliente, que accede al sistema del gestor de la casa de juego en línea, da lugar a este tipo de operaciones. La empresa se reserva el derecho de excluir el usuario de la participación en la oferta y de rechazar el pago de cualquier ganancia en caso de que la información sea incorrecta. Se requiere que el usuario, si la Compañía lo solicita, presente una copia de un documento de identidad con fotografía (pasaporte, licencia, tarjeta de identidad) o proporcione información adicional (copia de usuario, copia de los extractos bancarios, etc ...). El usuario sólo podrá ser titular de una cuenta. No se permite a los usuarios ya registrados de volver a registrarse como un nuevo usuario con un nombre diferente o con una dirección de correo electrónico diferente. En el caso de violación de esta regla, la Compañía tiene derecho a cancelar el caso de las apuestas realizadas. En sospecha de fraude o manipulación, así como la violación de estos términos y condiciones, en particular en el caso de participaciones simultáneas en juegos o aberturas de más de una cuenta, la Compañía tiene el poder a evitar al usuario interesado de cerrar nuevas apuestas o participar en los juegos

            </p>
            <h5>Pagos</h5>
            <p>
                Para poder recibir un crédito de la cuenta, el usuario está obligado a remitir a la Sociedad una copia legible del documento de identidad y foto como prueba de la exactitud de los datos comunicados en el momento de la inscripción. www.play.bet.ar se reserva el derecho a solicitar al usuario toda la documentación relacionada con el origen de las transacciones de depósitos realizados en la cuenta de juego. En caso de no coincidencia de los datos, todas las apuestas concluidas por el usuario y juegos de apuestas serán cancelados de inmediato y las ganancias no serán pagadas, salvo que el usuario no pruebe su derecho de crédito de otras maneras. Si el usuario ha abierto varias cuentas, en este caso la empresa podrá rechazar el pago del saldo de estas cuentas
                Los miembros de las asociaciones de usuarios tendrán que ajustar su situación jurídica entre ellos mismos. Las ganancias se acreditan, sin embargo, sólo bajo el nombre del titular de la cuenta en cuestión.
                El usuario se declara dispuesto a revelar detalles de su cuenta, de la que tiene la intención de hacer apuestas. El usuario autoriza a la Empresa a utilizar tales datos para el pago de dinero para el usuario
                La empresa se reserva el derecho de cambiar el tipo de retiro en función de los casos individuales (por ejemplo, a través del cargo su tarjeta de crédito).
            </p>
            <h5>Ingresar a la cuenta</h5>
            <p>
                La posibilidad de acceso a la cuenta está sujeta a la utilización por el usuario del código elegido por el usuario en el momento de la apertura de la cuenta (nombre de usuario y contraseña). La Compañía no tiene ninguna responsabilidad por el uso ilegal de una cuenta por terceros no autorizados, en el caso de que sean capaces de indicar el nombre de usuario y contraseña.
            </p>
            <h5>Deber de divulgación - Informes</h5>
            <p>
                La empresa, en virtud de esa ley, está obligada a informar a las autoridades competentes con el fin de reportar cualquier transacción sospechosa, la cuenta de juego será suspendida hasta el final de la investigación.
            </p>
        </div>
      
    </div>
  );
};

export default LavadoDinero;