// userServices/user.js

import httpClient from '../httpClient';

// Obtener todos los usuarios
export const getUsers = async () => {
  const response = await httpClient.get('/users');
  return response.data;
};

// Crear un usuario
export const createUser = async (userData) => {
  const response = await httpClient.post('/users', userData);
  return response.data;
};

// Editar un usuario
export const editUser = async (id, userData) => {
  const response = await httpClient.put(`/users/${id}`, userData);
  return response.data;
};

// Actualizar balance de un usuario
export const updateUserBalance = async (id, amount) => {
  const response = await httpClient.patch(`/users/${id}/balance`, { amount });
  return response.data;
};

// Iniciar sesión de usuario
export const findUserByIdentifier = async (identifier, password) => {
  const response = await httpClient.post('/users/login', { identifier, password });
  return response.data;
};

// Obtener usuarios por parent_id
export const getUsersByParent = async (parentId) => {
  const response = await httpClient.get(`/users/parent/${parentId}`);
  return response.data;
};

// Obtener usuario por ID
export const getUserById = async (userId) => {
  const response = await httpClient.get(`/users/${userId}`);
  return response.data;
};

// Verificar el rol de un usuario
export const checkUserRole = async (userId) => {
  const response = await httpClient.get(`/users/${userId}`);
  return response.data.role_id;
};

export const getUserNameById = async (userId) => {
  try {
    const response = await httpClient.get(`/users/${userId}`);
    return response.data.name; // Se espera que devuelva 'ADMIN' o 'STAFF'
  } catch (error) {
    console.error('Error fetching user role:', error.response?.data || error.message);
    throw error;
  }
};

// Función para iniciar sesión con un identificador (email o nombre) y contraseña
export const loginUser = async ({ identifier, password }) => {
  try {
    const response = await httpClient.post('/users/login', { identifier, password });
    return response.data; // Devuelve el usuario encontrado o información relevante
  } catch (error) {
    console.error('Error logging in:', error.response?.data || error.message);
    throw error;
  }
};

export const deleteUser = async ({ id }) => {
  const response = await httpClient.delete(`/users/${id}`);
  return response.data;
};