import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { creditBalanceCashier } from '../services/UserServices/cashier';
import { debitBalancePlayer, getPlayers } from '../services/UserServices/player';
import { createMovimiento } from '../services/ReportServices/movimientos';
import { useUser } from '../contexts/UserContext';
import { toast } from 'react-toastify';
import '../assets/css/Modales.css';

const RetiroModalUser = ({ show, handleClose }) => {
  const { user, isAuthenticated, setUser } = useUser(); // Cajero que realiza el retiro
  const [inputValue, setInputValue] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [players, setPlayers] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);


  // Efecto para limpiar los campos al cerrar sesión
    useEffect(() => {
      if (!isAuthenticated) {
        resetFields();
      }
    }, [isAuthenticated]);

  // Obtener jugadores al abrir el modal
  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const allPlayers = await getPlayers();
        const filteredPlayers = allPlayers.filter(player => player.cashier_id === user.id);
        setPlayers(filteredPlayers);
      } catch (error) {
        console.error('Error al obtener jugadores:', error);
      }
    };

    if (show) fetchPlayers();
  }, [show, user]);

  // Resetea los campos cuando se cierra el modal
  const resetFields = () => {
    setInputValue('');
    setSelectedUser(null);
    setAmount('');
    setPlayers([]);
    setLoading(false);
    setShowConfirmationModal(false);
  };

  // Manejar cierre del modal
  const handleModalClose = () => {
    resetFields();
    handleClose();
  };

  // Maneja la búsqueda de usuarios
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    const userFound = players.find(
      (player) =>
        player.player_name.toLowerCase().includes(value.toLowerCase()) ||
        player.cuil.includes(value)
    );
    setSelectedUser(userFound || null);
  };


  const openConfirmationModal = () => {
      if (!selectedUser || !amount || parseFloat(amount) <= 0) {
        toast.error('Por favor, complete todos los campos correctamente.');
        return;
      }
  
      if (parseFloat(selectedUser.balance) < parseFloat(amount)) {
        toast.error('Saldo insuficiente para el retiro.');
        return;
      }
  
      setShowConfirmationModal(true);
  };

  const handleWithdrawal = async () => {
    if (!selectedUser || !amount || parseFloat(amount) <= 0) {
      toast.error('Por favor, complete todos los campos correctamente.');
      return;
    }

    if (parseFloat(selectedUser.balance) < parseFloat(amount)) {
      toast.error('Saldo insuficiente para el retiro.');
      return;
    }


    setLoading(true);
    try {
      const withdrawAmount = parseFloat(amount);

      // 1️⃣ **Debitar saldo del jugador**
      const newPlayerBalance = parseFloat(selectedUser.balance) - withdrawAmount;
      await debitBalancePlayer(selectedUser.id, newPlayerBalance);

      // 2️⃣ **Acreditar saldo al cajero**
      const newCashierBalance = parseFloat(user.balance) + withdrawAmount;
      await creditBalanceCashier(user.id, newCashierBalance);

      // 3️⃣ **Generar un ID único para la transacción**
      const baseTransactionId = `TX-${Date.now()}`;
      const transactionIdRetiro = `${baseTransactionId}-R`; // Para el retiro
      const transactionIdRecibido = `${baseTransactionId}-B`; // Para el recibido

      // 4️⃣ **Registrar movimiento de "RETIRO TELEPAGOS" (realizado por el jugador)**
      const movimientoRetiro = {
          id_sender: selectedUser.id,
          id_receptor: user.id,
          id_cashier: user.id, // Cajero involucrado en el retiro
          amount: withdrawAmount,
          balance_before: parseFloat(selectedUser.balance),
          balance_after: newPlayerBalance,
          transaction_id: transactionIdRetiro,
          description: "RETIRO POR CAJERO",
          fecha_hora: new Date().toISOString(),
          sender_type: "player", // Tipo del emisor
          receiver_type: "cashier", // Tipo del receptor
      };

      await createMovimiento(movimientoRetiro);

      // 5️⃣ **Registrar movimiento de "RETIRO TELEPAGOS" (recibido por el cajero)**
      const movimientoRecibido = {
          id_sender: selectedUser.id,
          id_receptor: user.id,
          id_cashier: user.id, // Cajero involucrado en el retiro
          amount: withdrawAmount,
          balance_before: parseFloat(user.balance),
          balance_after: newCashierBalance,
          transaction_id: transactionIdRecibido,
          description: "ACREDITACION RETIRO CAJERO",
          fecha_hora: new Date().toISOString(),
          sender_type: "player", // Tipo del emisor
          receiver_type: "cashier", // Tipo del receptor
      };

      await createMovimiento(movimientoRecibido);

      // 6️⃣ **Actualizar localmente los balances**
      setUser((prevUser) => ({ ...prevUser, balance: newCashierBalance }));

      toast.success(`Retiro de $${amount} realizado con éxito.`);
      handleModalClose();
    } catch (error) {
      console.error('Error al retirar saldo:', error);
      toast.error('Error al realizar el retiro. Intente nuevamente.');
    } finally {
      setLoading(false);
      setShowConfirmationModal(false);
    }
  };

  return (
    <>
    
    <Modal show={show} onHide={handleModalClose} className="Forms">
      <Modal.Header className="FormsHeader" closeButton>
        <Modal.Title>Retirar Saldo</Modal.Title>
      </Modal.Header>
      <Modal.Body className="FormsBody">
        <Form>
          {/* CAMPO DE BÚSQUEDA */}
          <Form.Group controlId="formUserSearch">
            <Form.Label>Usuario a Retirar</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese el nombre de usuario o CUIL"
              value={inputValue}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* DATOS DEL USUARIO SELECCIONADO */}
          <Form.Group controlId="formUserInfo">
            <Form.Label>Datos del Usuario</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={
                selectedUser
                  ? `Usuario: ${selectedUser.player_name} | CUIL: ${selectedUser.cuil}`
                  : 'No se encontraron coincidencias'
              }
            />
          </Form.Group>

          {/* SALDO DEL USUARIO (READONLY) */}
          <Form.Group controlId="formUserBalance">
            <Form.Label>Saldo del Usuario</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={selectedUser ? `$${selectedUser.balance}` : '---'}
            />
          </Form.Group>

          {/* MONTO A RETIRAR */}
          <Form.Group controlId="formAmount">
            <Form.Label>Monto a Retirar</Form.Label>
            <Form.Control
              type="number"
              placeholder="Ingrese el monto"
              value={amount}
              onChange={(e) => {
                const value = e.target.value;
                if (!isNaN(value) && parseFloat(value) >= 0) {
                  setAmount(value);
                }
              }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="FormsFooter">
        <Button className="botonCancelar gradient-borderCancel" onClick={handleModalClose}>
          Cancelar
        </Button>
        <Button
          className="botonConfirmar"
          onClick={openConfirmationModal}
          disabled={loading || !selectedUser || !amount}
        >
          {loading ? 'Procesando...' : 'Retirar'}
        </Button>
      </Modal.Footer>
    </Modal>

    {/* Modal de Confirmación */}
    <Modal
        
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        centered
        className="Forms"
      >
        <Modal.Header className="FormsHeader" closeButton>
          <Modal.Title>Confirmar Retiro</Modal.Title>
        </Modal.Header>
        <Modal.Body className="FormsBody">
          <p>
            ¿Está seguro de que desea retirar{' '}
            <strong>
              {new Intl.NumberFormat("es-AR", {
                style: "currency",
                currency: "ARS",
              }).format(amount)}
            </strong>{' '}
            a {selectedUser?.player_name}?
          </p>
        </Modal.Body>
        <Modal.Footer className="FormsFooter">
          <Button className="botonCancelar gradient-borderCancel" onClick={() => setShowConfirmationModal(false)}>
            Cancelar
          </Button>
          <Button className="botonConfirmar" onClick={handleWithdrawal}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>


    </>
  );
};

export default RetiroModalUser;
