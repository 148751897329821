import httpClient from './httpClient';

export const fetchEvenBetRedirectUrl = async (userId, username, currency = 'ARS') => {
  try {
    const response = await httpClient.post(
      '/evenbet/login',
      { userId, username, currency },
      { skipAuth: true } // Omitir el token para esta llamada
    );

    if (response.data && response.data.data && response.data.data.attributes) {
      return response.data.data.attributes['redirect-url'];
    } else {
      throw new Error('No se pudo obtener la URL de redirección de EvenBet.');
    }
  } catch (error) {
    console.error('Error en fetchEvenBetRedirectUrl:', error.response?.data || error.message);
    throw error;
  }
};
