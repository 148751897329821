import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import '../assets/css/NavBar.css'; 
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createPlayer } from '../services/UserServices/player';

const RegisterModal = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const referrerId = searchParams.get('referrer_id'); // Obtiene el ID del cajero de la URL
    const [isModalOpen, setIsModalOpen] = useState(true); // El modal se abre automáticamente
    const [newPlayerData, setNewPlayerData] = useState({
        cuil: '',
        player_name: '',
        password: '',
        phone: '',
        balance: 0,
        cashier_id: referrerId || '' // Asigna automáticamente el cajero referidor
    });

    useEffect(() => {
        if (!referrerId) {
            console.warn('No se proporcionó un ID de cajero en la URL.');
        }
    }, [referrerId]);

    const handleCloseModal = () => {
        setIsModalOpen(false);
        navigate('/'); // Redirige al home si el usuario cierra el modal
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            await createPlayer(newPlayerData);
            toast.success("REGISTRO EXITOSO. Ahora puede INICIAR SESION.");
            navigate('/'); // Redirige al home tras registro exitoso
        } catch (error) {
            toast.error('REGISTRO FALLIDO.');
        }
    };

    return (
        <>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                contentLabel="Registro"
                className="custom-modal"
                overlayClassName="custom-overlay"
            >
                <h5>Regístrate</h5>
                <form onSubmit={handleRegister}>
                    <label>
                        Nombre de usuario:
                        <input
                            type="text"
                            value={newPlayerData.player_name}
                            onChange={(e) => setNewPlayerData({ ...newPlayerData, player_name: e.target.value })}
                            required
                        />
                    </label>
                    <label>
                        Contraseña:
                        <input
                            type={showPassword ? 'text' : 'password'}
                            value={newPlayerData.password}
                            onChange={(e) => setNewPlayerData({ ...newPlayerData, password: e.target.value })}
                            required
                        />
                    </label>
                    <button
                                    type="button-show"
                                    className="ocultar-password"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? 'Ocultar' : 'Mostrar'}
                                </button>
                    <label>
                        CUIL:
                        <input
                            type="text"
                            value={newPlayerData.cuil}
                            onChange={(e) => setNewPlayerData({ ...newPlayerData, cuil: e.target.value })}
                            required
                        />
                    </label>
                    <label>
                        Teléfono:
                        <input
                            type="text"
                            value={newPlayerData.phone}
                            onChange={(e) => setNewPlayerData({ ...newPlayerData, phone: e.target.value })}
                        />
                    </label>
                    <button className="botonConfirmar" type="submit">Registrar</button>
                </form>
                <button className="boton-link mt-2" onClick={handleCloseModal}>
                    Cerrar
                </button>
            </Modal>
        </>
    );
};

export default RegisterModal;