// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Contenedor principal de la sección */
.promociones-container {
    text-align: center;
    background-color: rgba(0, 47, 47, 0.703); /* Color de fondo oscuro */
    padding: 50px 0;
}

/* Estilo del texto gigante "PROMOCIONES" */
.promociones-title {
    font-size: 100px; /* Tamaño grande */
    font-weight: bold;
    color: transparent;
    text-transform: uppercase;
    -webkit-text-stroke: 2px rgb(255, 255, 255); 
    text-shadow: 
        0 0 10px #59b0b6, 
        0 0 20px #5994b6,
        0 0 30px #59a8b6,
        0 0 40px #42b2c1, /* Efecto neón más fuerte */
        0 0 70px #42c1b9;
    letter-spacing: 10px;
    margin-bottom: 50px;
}

/* Contenedor del banner */
.promociones-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

/* Imagen del banner */
.banner-image {
    width: 500px; /* Ajusta el tamaño según sea necesario */
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.2); /* Efecto de brillo */
    transition: transform 0.3s ease-in-out;
}

/* Efecto al pasar el mouse sobre el banner */
.banner-image:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.4);
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/Promociones.css"],"names":[],"mappings":"AAAA,uCAAuC;AACvC;IACI,kBAAkB;IAClB,wCAAwC,EAAE,0BAA0B;IACpE,eAAe;AACnB;;AAEA,2CAA2C;AAC3C;IACI,gBAAgB,EAAE,kBAAkB;IACpC,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;IACzB,2CAA2C;IAC3C;;;;;wBAKoB;IACpB,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA,0BAA0B;AAC1B;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA,sBAAsB;AACtB;IACI,YAAY,EAAE,yCAAyC;IACvD,YAAY;IACZ,mBAAmB;IACnB,iDAAiD,EAAE,qBAAqB;IACxE,sCAAsC;AAC1C;;AAEA,6CAA6C;AAC7C;IACI,sBAAsB;IACtB,iDAAiD;AACrD","sourcesContent":["/* Contenedor principal de la sección */\n.promociones-container {\n    text-align: center;\n    background-color: rgba(0, 47, 47, 0.703); /* Color de fondo oscuro */\n    padding: 50px 0;\n}\n\n/* Estilo del texto gigante \"PROMOCIONES\" */\n.promociones-title {\n    font-size: 100px; /* Tamaño grande */\n    font-weight: bold;\n    color: transparent;\n    text-transform: uppercase;\n    -webkit-text-stroke: 2px rgb(255, 255, 255); \n    text-shadow: \n        0 0 10px #59b0b6, \n        0 0 20px #5994b6,\n        0 0 30px #59a8b6,\n        0 0 40px #42b2c1, /* Efecto neón más fuerte */\n        0 0 70px #42c1b9;\n    letter-spacing: 10px;\n    margin-bottom: 50px;\n}\n\n/* Contenedor del banner */\n.promociones-banner {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 20px;\n}\n\n/* Imagen del banner */\n.banner-image {\n    width: 500px; /* Ajusta el tamaño según sea necesario */\n    height: auto;\n    border-radius: 10px;\n    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.2); /* Efecto de brillo */\n    transition: transform 0.3s ease-in-out;\n}\n\n/* Efecto al pasar el mouse sobre el banner */\n.banner-image:hover {\n    transform: scale(1.05);\n    box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
