// ProfileModal.js
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/css/ProfileModal.css';

function ProfileModal({ isOpen, onClose, user, logout, profileIconRef }) {
  const modalRef = useRef(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [showClass, setShowClass] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const navigate = useNavigate();

  // Posicionar el modal y activar la animación de entrada
  useEffect(() => {
    if (isOpen && profileIconRef?.current) {
      const iconRect = profileIconRef.current.getBoundingClientRect();
      const modalWidth = 150; // Cambia esto al ancho aproximado de tu modal
      const screenWidth = window.innerWidth;  

      let left = iconRect.left + window.scrollX;
        if (left + modalWidth > screenWidth) {
        left = screenWidth - modalWidth - 30; // Ajusta para que no salga de la pantalla
        }
      setPosition({
        top: iconRect.bottom + window.scrollY,
        left,
      });

      // Activar la animación de entrada
      setTimeout(() => setShowClass(true), 10);
    } else {
      setShowClass(false);
    }
  }, [isOpen, profileIconRef]);

  // Manejar el clic fuera del modal para cerrar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        initiateClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  // Iniciar la animación de salida
  const initiateClose = () => {
    setIsExiting(true);
    setShowClass(false); // Remover la clase de "show" para iniciar la transición de cierre
    setTimeout(() => {
      setIsExiting(false);
      onClose();
    }, 300); // Espera el tiempo de la transición antes de desmontar
  };

  const handleLogout = () => {
    logout(); // Llama a la función de cierre de sesión
    navigate('/'); // Redirige a la pantalla de inicio
    initiateClose(); // Cierra el modal
  };

  if (!isOpen && !isExiting) return null;

  return (
    <div
      ref={modalRef}
      className={`profile-modal-container ${showClass ? 'show' : ''} ${isExiting ? 'hide' : ''}`}
      style={{ top: position.top, left: position.left }}
    >
      <ul className="profile-options">
        <Link className="buttonlink" to={user?.role_id === 3 ? '/cashier/cashierProfile' : '/miperfil'} onClick={initiateClose}>Perfil</Link>
        <div className="close-session" onClick={handleLogout}>
          <span>Cerrar Sesion</span>
        </div>
      </ul> 
    </div>
  );
}

export default ProfileModal;
