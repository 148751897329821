import React from 'react';
import CarousselPromociones from '../components/CarousselPromociones';
import CarousselJuegos from '../components/CarousselJuegos';
import BannersRedirecciones from '../components/BannersRedirecciones';
import CarousselElements from '../components/CarousselElements';
import lobo from '../assets/images/bienvenidos.png'
import '../assets/css/home.css';

const Home = () => {
  return (
    <div className="home">
      
      {/* Carrusel de JUEGOS */}
      <div className="home-carousel">
        <CarousselJuegos />
      </div>

      {/* Carrusel de Promociones */}
      <div className="home-carousel">
        <CarousselPromociones />
      </div>

      {/* IMAGEN LOBO */}
      <img src={lobo} className='lobo-image'></img>

      

      
    </div>
  );
};

export default Home;
