// userServices/cashier.js

import httpClient from '../httpClient';

// Obtener todos los cajeros
export const getCashiers = async () => {
  const response = await httpClient.get('/cashiers');
  return response.data;
};

// Crear un cajero
export const createCashier = async (cashierData) => {
  const response = await httpClient.post('/cashiers', cashierData);
  return response.data;
};

// Obtener cajero por ID
export const getCashierById = async (cashierId) => {
  const response = await httpClient.get(`/cashiers/${cashierId}`);
  return response.data;
};

// Buscar cajeros por nombre
export const searchCashiers = async (searchTerm) => {
  const response = await httpClient.get('/cashiers/search', {
    params: { searchTerm },
  });
  return response.data;
};

// Obtener cajero por CUIL
export const findCashierByCUIL = async (cuil) => {
  const response = await httpClient.get(`/cashiers/cuil/${cuil}`);
  return response.data;
};

// Actualizar la última sesión de un cajero
export const updateCashierLastSession = async (cashierId, lastSession) => {
  try {
    const response = await httpClient.put(`/cashiers/${cashierId}/last-session`, {
      last_session: lastSession,
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating last session for cashier with ID ${cashierId}:`, error.response?.data || error.message);
    throw error;
  }
};

// Editar un cajero
export const editCashier = async (id, cashierData) => {
  const response = await httpClient.put(`/cashiers/${id}`, cashierData);
  return response.data;
};

// Actualizar saldo del cajero
export const updateCashierBalance = async (id, amount) => {
  const response = await httpClient.patch(`/cashiers/${id}/balance`, { amount });
  return response.data;
};

// Eliminar un cajero
export const deleteCashier = async (id) => {
  const response = await httpClient.delete(`/cashiers/${id}`);
  return response.data;
};

// Iniciar sesión cajero
export const loginCashier = async (identifier, password) => {
  const response = await httpClient.post('/cashiers/login', { identifier, password });
  return response.data;
};

// Función para acreditar saldo a un jugador
export const creditBalanceCashier = async (cashierId, balance) => {
    try {
      const response = await httpClient.put(`/cashiers/${cashierId}`, { balance });
      return response.data;
    } catch (error) {
      console.error(`Error crediting balance to cashier with ID ${cashierId}:`, error.response?.data || error.message);
      throw error;
    }
};
  
// Función para retirar saldo de un jugador
export const debitBalanceCashier = async (cashierId, balance) => {
    try {
      const response = await httpClient.put(`/cashiers/${cashierId}`, { balance });
      return (response.data);
    } catch (error) {
      console.error(`Error debiting balance from player with ID ${cashierId}:`, error.response?.data || error.message);
      throw error;
    }
};

// Función para obtener cajero (cashier) desde la API
export const getCashierNameById = async (cashier_id) => {
  try {
    const response = await httpClient.get(`/cashiers/${cashier_id}`); // Asegúrate de que esta ruta exista en tu API
    return response.data.cashier_name;
  } catch (error) {
    console.error('Error fetching cashiers:', error.response?.data || error.message);
    throw error;
  }
};  
