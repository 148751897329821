import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
import { updateCashierLastSession } from '../services/UserServices/cashier';
import { updatePlayerLastSession } from '../services/UserServices/player';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const timeoutIdRef = useRef(null); // Cambiamos de useState a useRef para almacenar el timeout ID

  useEffect(() => {
    try {
      const storedUser = sessionStorage.getItem("currentUser");

      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
        setIsAuthenticated(true);

        // Actualizar el campo `last_session`
        const lastSessionTime = new Date().toISOString();
        if (parsedUser.role_id === 3) { 
          updateCashierLastSession(parsedUser.id, lastSessionTime)
            .then(() => console.log('Last session updated for cashier'))
            .catch((err) => console.error('Error updating last session for cashier:', err));
        } else if (parsedUser.role_id === 4) { 
          updatePlayerLastSession(parsedUser.id, lastSessionTime)
            .then(() => console.log('Last session updated for player'))
            .catch((err) => console.error('Error updating last session for player:', err));
        }
      } else {
        setUser(null);
        setIsAuthenticated(false);
      }

    } catch (error) {
      console.error("Error al recuperar datos de almacenamiento:", error);
    }
    
  }, []);

  // Función para manejar el inicio de sesión
  const login = (userData, redirectUrl) => {
    const userWithRedirectUrl = { ...userData, redirectUrl };
    console.log('Usuario guardado con URL en sessionStorage:', userWithRedirectUrl); // Verifica datos antes de guardar
    setUser(userWithRedirectUrl);
    setIsAuthenticated(true);
    sessionStorage.setItem('currentUser', JSON.stringify(userWithRedirectUrl));

    // Actualizar el campo `last_session` al iniciar sesión
    const lastSessionTime = new Date().toISOString();
    if (userWithRedirectUrl.role_id === 3) { // Cashier
      updateCashierLastSession(userWithRedirectUrl.id, lastSessionTime)
        .then(() => console.log('Last session updated for cashier on login'))
        .catch((err) => console.error('Error updating last session for cashier on login:', err));
    } else if (userWithRedirectUrl.role_id === 4) { // Player
      updatePlayerLastSession(userWithRedirectUrl.id, lastSessionTime)
        .then(() => console.log('Last session updated for player on login'))
        .catch((err) => console.error('Error updating last session for player on login:', err));
    }

    resetInactivityTimer();
  };

  // Función para manejar el cierre de sesión
  const logout = () => {
    console.log("Cerrando sesión...");
    setUser(null);
    setIsAuthenticated(false);
    sessionStorage.removeItem("currentUser");
    clearTimeout(timeoutIdRef.current);
  };

  // Función para reiniciar el temporizador de inactividad
  const resetInactivityTimer = useCallback(() => {
    console.log("Reiniciando temporizador de inactividad...");
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(() => {
      console.log("Sesión cerrada por inactividad.");
      logout();
    }, 10 * 60 * 1000); // 10 minutos
  }, [logout]);

  // Detectar actividad del usuario
  useEffect(() => {
    const handleActivity = () => {
      resetInactivityTimer();
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("scroll", handleActivity);

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("scroll", handleActivity);
      clearTimeout(timeoutIdRef.current);
    };
  }, [resetInactivityTimer]);

  return (
    <UserContext.Provider value={{ user, isAuthenticated, role_id: user?.role_id, login, logout, setUser }}>
      {console.log('Estado actual del contexto:', { user, isAuthenticated })} {/* Verificar estado */}
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
