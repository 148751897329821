// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

function ProtectedRoute({ isAuthenticated, requiredRole, children }) {
  const { user } = useUser();

  if (!isAuthenticated || !user) {
    return <Navigate to="/" />;
  }

  if (requiredRole && user.role_id !== requiredRole) {
    return <Navigate to="/" />;
  }

  return children;
}

export default ProtectedRoute;