import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/MiPerfil.css';
import candado from '../assets/images/candado.png';
import Modal from 'react-modal';
import { getReportCashierById } from '../services/ReportServices/cashier';
import { getPlayers, getPlayerById } from '../services/UserServices/player';
import { editCashier, getCashierById } from '../services/UserServices/cashier';
import { getMovimientos } from '../services/ReportServices/movimientos';
import { getUserById } from '../services/UserServices/user';
import { useUser } from '../contexts/UserContext';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { QRCodeCanvas } from "qrcode.react";
import { toast } from 'react-toastify';


const CashierProfile = () => {
    const [selectedButton, setSelectedButton] = useState('boton1');
    const [selectedOption, setSelectedOption] = useState('datos-personales');
    const [selectedUserOption, setSelectedUserOption] = useState('buscar-usuario');
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalPasswordOpen, setIsModalPasswordOpen] = useState(false);
    const [newPhone, setNewPhone] = useState('');
    const [newPassword, setNewPasword] = useState('');
    const [sortOrder, setSortOrder] = useState(null); // null | 'asc' | 'desc'
    const [players, setPlayers] = useState([]);
    const [selectedUser, setSelectedUser ] = useState(null);
    const [movementState, setMovementState] = useState("");
    const [movementProvider, setMovementProvider] = useState("");
    const { user, setUser } = useUser();
    const [reportData, setReportData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const cashierId = user.id;
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredPlayers, setFilteredPlayers] = useState(players);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newUserPassword, setNewUserPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [movements, setMovements] = useState([]);
    const [allMovements, setAllMovements] = useState([]); 

    const referrerUrl = `https://clubet.net/register?referrer_id=${user.id}`;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentPlayers = filteredPlayers
     // Filtrar jugadores por cajero logueado
    .filter(player => player.cashier_id === user.id)
    .slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredPlayers.length / itemsPerPage);

    const goToNextPage = () => {
    if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
    }
    };

    const goToPrevPage = () => {
    if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
    }
    };

    const goToLastPage = () => {
    setCurrentPage(totalPages);
    };

    const goToFirstPage = () => {
    setCurrentPage(1);
    };

    const formatDate = (isoString) => {
        const date = new Date(isoString);
    
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
    
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
    
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const extractDate = (datetimeString) => {
        return datetimeString.split(' ')[0]; // Devuelve solo la parte de la fecha "YYYY-MM-DD"
    };

    const fetchNameByType = async (id, type) => {
        const typeToFunctionMap = {
            player: getPlayerById,
            cashier: getCashierById,
            user: getUserById,
        };
    
        const fetchFunction = typeToFunctionMap[type];
        if (!fetchFunction || !id) {
            return "N/A"; // Retornar "N/A" si el tipo o ID no es válido
        }
    
        try {
            const response = await fetchFunction(id); // Llama a la función correspondiente
            console.log(`Respuesta para id=${id}, type=${type}:`, response);
            return (
                response.player_name || // Para jugadores
                response.cashier_name || // Para cajeros
                response.name || // Para usuarios
                "N/A"
            );
        } catch (error) {
            console.error(`Error fetching name for type: ${type}, id: ${id}`, error);
            return "N/A";
        }
    };

    const fetchMovements = async () => {
        setLoading(true);
        setError('');
        setMovements([]); // Limpia movimientos previos mientras carga
        setAllMovements([]); // Limpia el estado de todos los movimientos
    
        try {
            const response = await getMovimientos();
            console.log('Movimientos obtenidos del servicio:', response);
    
            if (!response || response.length === 0) {
                setError('No se encontraron movimientos.');
                return;
            }
    
            // 📌 Filtrar movimientos que SÍ debe ver el cajero
            const filteredMovements = response.filter((item) => {
                const esTransferencia = item.description === "TRANSFERENCIA REALIZADA";
                const esAcreditacionRetiro = item.description === "ACREDITACION RETIRO CAJERO";
                const esRetiroTelepagos = item.description === "RETIRO TELEPAGOS";

                // ✅ REGLA 1: Incluir "TRANSFERENCIA REALIZADA" SOLO si:
                // - sender_type es "cashier"
                // - id_sender es el usuario actual (user.id)
                if (esTransferencia && item.sender_type === "cashier" && item.id_sender === user.id) {
                    return true;
                }

                // ✅ REGLA 2: Incluir "ACREDITACION RETIRO CAJERO" (Siempre se muestra)
                if (esAcreditacionRetiro) {
                    return true;
                }

                // ✅ REGLA 3: Incluir "RETIRO TELEPAGOS" SOLO si:
                // - sender_type es "cashier"
                // - id_sender es el usuario actual (user.id)
                if (esRetiroTelepagos && item.sender_type === "cashier" && item.id_sender === user.id) {
                    return true;
                }

                // ❌ NO incluir cualquier transferencia sin receptor (evitamos traer movimientos no relacionados)
                return false;
            });
            
            // Mapeo dinámico para obtener nombres según sender_type y receiver_type
            const movementsWithUsers = await Promise.all(
                filteredMovements.map(async (item) => {
                    const senderName = 
                    item.sender_type
                        ? await fetchNameByType(item.id_sender, item.sender_type) // Prioridad al sender_type
                        : item.description?.toUpperCase().includes('TELEPAGOS')
                        ? 'TELEPAGOS'
                        : 'N/A'; // Si no hay sender_type ni TELEPAGOS

                    const receptorName = 
                    item.receiver_type
                        ? await fetchNameByType(item.id_receptor, item.receiver_type) // Prioridad al receiver_type
                        : item.description?.toUpperCase().includes('TELEPAGOS')
                        ? 'TELEPAGOS'
                        : 'N/A'; // Si no hay receiver_type ni TELEPAGOS
    
                    return {
                        ...item,
                        fecha_hora: formatDate(item.fecha_hora),
                        senderName,
                        receptorName,
                    };
                })
            );
    
            setAllMovements(movementsWithUsers); // Guarda todos los movimientos
            setMovements(movementsWithUsers); // Inicializa con todos los movimientos
        } catch (err) {
            console.error('Error al obtener movimientos:', err);
            setError('Error al cargar las transferencias. Intenta nuevamente.');
        } finally {
            setLoading(false);
        }
    };

    const applyFilters = () => {
        let filtered = [...allMovements];
    
        // Filtro por fecha "Desde"
        if (fromDate) {
            filtered = filtered.filter(
                (item) => extractDate(item.fecha_hora) >= fromDate
            );
        }

        // Filtro por fecha "Hasta"
        if (toDate) {
            filtered = filtered.filter(
                (item) => extractDate(item.fecha_hora) <= toDate
            );
        }
    
        // Filtro por tipo de movimiento
        if (movementState) {
            filtered = filtered.filter((item) => item.description === movementState);
        }
    
        setMovements(filtered);
    };

    useEffect(() => {
        if (selectedOption === 'transferencias') {
            fetchMovements();
        }
    }, [selectedOption]);



    //MOSTRAR TARJETAS EN TELEFONO
    const renderUserCards = () => {
        return (
          <div className="user-cards-container">
            {currentPlayers.map((player, index) => (
              <div key={index} className="user-card">
                {/* Sección izquierda - Usuario y CUIL */}
                <div className="user-info">
                  <h4>{player.player_name}</h4>
                  <p>{player.cuil}</p>
                  <p><strong>Saldo:</strong> {player.balance}</p>
                </div>
      
                {/* Sección central - Datos adicionales */}
                <div className="user-details">                
                  <p><strong>Teléfono:</strong> {player.phone}</p>
                  <p><strong>Fecha de Creación:</strong> {new Date(player.created_at).toLocaleDateString()}</p>
                </div>
      
                {/* Sección derecha - Botones de acción */}
                <div className="user-actions">
                  <button onClick={() => openEditModal(player)} className="edit-button">Editar</button>
                  <button onClick={() => openEditPasswordModal(player)} className="password-button">
                    <img className="logocandado" src={candado} alt="Cambiar contraseña" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        );
    };


    //CAMBIAR CONTRASEÑA USUARIO
    const handlePasswordChange = async () => {
        // Reset messages
        setErrorMessage('');
        setSuccessMessage('');

        // Validar si la contraseña actual es correcta
        if (currentPassword !== user.password) {
            toast.error('La contraseña actual es incorrecta.');
            return;
        }

        // Validar si la nueva contraseña y su confirmación coinciden
        if (newUserPassword !== confirmNewPassword) {
            toast.error('La nueva contraseña y su confirmación no coinciden.');
            return;
        }

        // Validar si la contraseña no es la misma a la actual
        if ((newUserPassword || confirmNewPassword) === user.password) {
            toast.error('La nueva contraseña debe ser distinta a la anterior.');
            return;
        }

        // Validar que las contraseñas no estén vacías
        if (!newUserPassword || !confirmNewPassword) {
            toast.error('La nueva contraseña no puede estar vacía.');
            return;
        }

        const updatedFormData = { password: newUserPassword };
        await editCashier(user.id, updatedFormData);

        // Si todo está correcto, realizar la actualización
        toast.success('¡Contraseña cambiada exitosamente!')
        setSuccessMessage('');
        setCurrentPassword('');
        setNewUserPassword('');
        setConfirmNewPassword('');

        setUser({ ...user, password: newUserPassword });
        localStorage.setItem(
            'currentUser',
            JSON.stringify({ ...user, password: newUserPassword })
        );

        
    };


    //FILTRAR TABLA USUARIOS
    const handleSearch = () => {
        const term = searchTerm.toLowerCase();
    
        const filtered = players.filter((player) => 
          (player.id?.toString() || '').toLowerCase().includes(term) ||
          (player.cuil?.toLowerCase() || '').includes(term) ||
          (player.player_name?.toLowerCase() || '').includes(term)
        );
    
        setFilteredPlayers(filtered); // Actualiza los resultados filtrados
    };
    
    const handleReset = () => {
        setSearchTerm(''); // Limpia el cuadro de búsqueda
        setFilteredPlayers(players); // Restablece los jugadores originales
    };


    useEffect(() => {
        // Función para obtener el reporte del cashier
        const fetchReportData = async () => {
          try {
            const data = await getReportCashierById(cashierId);
            setReportData(data); // Guarda los datos en el estado
          } catch (err) {
            console.error('Error al obtener el reporte:', err);
            setError('Error al cargar el reporte del cajero. Intente de nuevo.');
          }
        };
    
        fetchReportData(); // Llama a la función cuando el componente se monta
    }, [cashierId]); 

    // Fetch data on component mount
    useEffect(() => {
        const loadPlayers = async () => {
        try {
            const data = await getPlayers(); // Llama al servicio
            setPlayers(data); // Guarda los datos en el estado
        } catch (err) {
            console.error('Error fetching users:', err);
            setError('Error al cargar los usuarios. Intente de nuevo.');
        }
        };

        loadPlayers();
    }, []);

    // filtros historial jugadas
    /*const applyFilters = () => {
        let filtered = jugadas;
        if (fromDate) filtered = filtered.filter((item) => new Date(item.fechaHora) >= new Date(fromDate));
        if (toDate) filtered = filtered.filter((item) => new Date(item.fechaHora) <= new Date(toDate));
        if (movementType) filtered = filtered.filter((item) => item.tipo === movementType);
        if (movementState) filtered = filtered.filter((item) => item.estado === movementState);
        if (movementProvider) filtered = filtered.filter((item) => item.proveedor === movementProvider);
        setFilteredData(filtered);
    }; 

    // Restablecer filtros historial jugadas
    const resetFilters = () => {
        setFromDate("");
        setToDate("");
        setMovementType("");
        setFilteredData([]);
    };

    // filtros trasnferencias
    const applyTransferFilters = () => {
        let filtered = transferencias;
        if (fromDate) filtered = filtered.filter((item) => new Date(item.fechaMovimiento) >= new Date(fromDate));
        if (toDate) filtered = filtered.filter((item) => new Date(item.fechaMovimiento) <= new Date(toDate));
        setFilteredTransferencias(filtered);
    };

    // Restablecer filtros trasnferencias
    const resetTransferFilters = () => {
        setFromDate("");
        setToDate("");
        setFilteredTransferencias([]);
    };*/ 

    //MODAL EDITAR TELEFONO
    const openEditModal = (user) => {
        setSelectedUser(user);
        setNewPhone(user.phone); // Inicializa con el teléfono actual
        setIsModalOpen(true);
    };
    
    // Cierra el modal
    const closeEditModal = () => {
        setIsModalOpen(false);
        setSelectedUser(null);
    };

    // Guarda los cambios
    const handleSaveChanges = () => {
        if (selectedUser) {
          selectedUser.phone = newPhone; // Actualiza el teléfono localmente
          toast.success('Cambio exitoso')
          closeEditModal();
        } else{
            toast.error('Algo fue mal')
        }
    };

    //MODAL CAMBIO CONTRASEÑA
    const openEditPasswordModal = (user) => {
        setSelectedUser(user);
        setNewPasword(user.password); // Inicializa con contraseña actual
        setIsModalPasswordOpen(true);
    };

    const closeEditPasswordModal = () => {
        setIsModalPasswordOpen(false);
        setSelectedUser(null);
    };

    // Guarda los cambios
    const handleSaveChangesP = () => {
        if (selectedUser) {
          selectedUser.password = newPassword; // Actualiza la contraseña
          toast.success('Cambio exitoso')
          closeEditPasswordModal();
        } else{
            toast.error('Algo fue mal')
        }
    };


    //funcionalidad quick filters fecha
    const handleQuickFilter = (filter) => {
        const today = new Date();
        let start, end;
    
        switch (filter) {
          case "Hoy":
            start = today;
            end = today;
            break;
          case "Ayer":
            start = new Date(today);
            start.setDate(today.getDate() - 1);
            end = start;
            break;
          case "Semana actual":
            start = new Date(today);
            start.setDate(today.getDate() - today.getDay());
            end = today;
            break;
          case "Mes actual":
            start = new Date(today.getFullYear(), today.getMonth(), 1);
            end = today;
            break;
          case "La semana pasada":
            start = new Date(today);
            start.setDate(today.getDate() - today.getDay() - 7);
            end = new Date(start);
            end.setDate(start.getDate() + 6);
            break;
          case "El mes pasado":
            start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            end = new Date(today.getFullYear(), today.getMonth(), 0);
            break;
          default:
            start = null;
            end = null;
            break;
        }
    
        setStartDate(start);
        setEndDate(end);
      };


    
      

    //ORDENAR ASC DESC SEGUN SALDOS
    const handleSortByBalance = () => {
        let newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'; // Alterna el orden
        setSortOrder(newSortOrder);
      
        const sorted = [...players].sort((a, b) => {
          const balanceA = parseFloat(a.balance.replace(/[$,]/g, '')) || 0; // Elimina símbolos y comas
          const balanceB = parseFloat(b.balance.replace(/[$,]/g, '')) || 0;
      
          if (newSortOrder === 'asc') {
            return balanceA - balanceB;
          } else {
            return balanceB - balanceA;
          }
        });
      
        setPlayers(sorted);
    };


    const renderContent1 = () => {

          switch (selectedUserOption){
              case'buscar-usuario':
                  return (
                      <div className="user-network-container">
                          <h3>Buscar un usuario en la red</h3>
                          <div className="filters-container">
                              <div className="filters">
                                  <div className="filter-group">
                                      <label>Buscar Usuario</label>
                                      <input
                                            type="text"
                                            placeholder="Buscar por ID, CUIL, Nombre"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className="search-input"
                                      />
                                  </div>
                                  <div className="filter-group">
                                      <label>Estado</label>
                                      <select>
                                          <option value="">Todos</option>
                                          <option value="Activo">Activo</option>
                                          <option value="Inactivo">Inactivo</option>
                                      </select>
                                  </div>
                              </div>
                              <div className="filter-buttons">
                                  <button className="search-button" onClick={handleSearch}>Buscar</button>
                                  <button className="reset-button" onClick={handleReset}>Limpiar</button>
                              </div>
                          </div>
                          <div className="users-display-options">
                                <label>Mostrar:</label>
                                <select value={itemsPerPage} onChange={(e) => setItemsPerPage(parseInt(e.target.value))}>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                </select>
                          </div>
                          {/* Mostrar tarjetas en móvil y tabla en escritorio */}
                            <div className="user-list">
                                {window.innerWidth <= 768 ? renderUserCards() : (
                                <table className="info-table">
                                    <thead>
                                        <tr>
                                            <th>Nombre de usuario</th>
                                            <th>CUIL</th>
                                            <th onClick={handleSortByBalance} style={{ cursor: 'pointer' }}>
                                            Saldo {sortOrder === 'asc' ? '↑' : sortOrder === 'desc' ? '↓' : ''}
                                            </th>
                                            <th>Teléfono</th>
                                            <th>Fecha Creación</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPlayers.map((player, index) => (
                                            <tr key={index}>
                                            <td>{player.player_name}</td>
                                            <td>{player.cuil}</td>
                                            <td>{player.balance}</td>
                                            <td>{player.phone}</td>
                                            <td>{new Date(player.created_at).toLocaleDateString()}</td>
                                            <td className="td-acciones-usuarios">
                                                <button onClick={() => openEditModal(player)}>Editar</button>
                                                <button onClick={() => openEditPasswordModal(player)}>
                                                <img className="logocandado" src={candado} alt="Cambiar contraseña" />
                                                </button>
                                            </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                )}
                            </div>
                            <div className="pagination-controls">
                                <button onClick={goToFirstPage} disabled={currentPage === 1}><span>« Primera</span></button>
                                <button onClick={goToPrevPage} disabled={currentPage === 1}><span>‹ Anterior</span></button>
                                <span>Página {currentPage} de {totalPages}</span>
                                <button onClick={goToNextPage} disabled={currentPage === totalPages}><span>Siguiente ›</span></button>
                                <button onClick={goToLastPage} disabled={currentPage === totalPages}><span>Última »</span></button>
                            </div>

                          {/* Modal */}
                            <Modal
                                isOpen={isModalOpen}
                                onRequestClose={closeEditModal}
                                shouldCloseOnOverlayClick = {true}
                                contentLabel="Editar Teléfono"
                                className="EditModal-content"
                                overlayClassName="EditModal-overlay"
                            >
                                <h2>Editar Número de Teléfono</h2>
                                {selectedUser && (
                                <div>
                                    <p>
                                    Editando teléfono de <strong>{selectedUser.player_name}</strong>
                                    </p>
                                    <input
                                    type="text"
                                    value={newPhone}
                                    onChange={(e) => setNewPhone(e.target.value)}
                                    placeholder="Nuevo número de teléfono"
                                    />
                                    <div className="EditModal-buttons">
                                    <button onClick={handleSaveChanges}>Guardar Cambios</button>
                                    <button onClick={closeEditModal}>Cancelar</button>
                                    </div>
                                </div>
                                )}
                            </Modal>

                            {/* Modal */}
                            <Modal
                                isOpen={isModalPasswordOpen}
                                onRequestClose={closeEditPasswordModal}
                                shouldCloseOnOverlayClick = {true}
                                contentLabel="Cambiar Contraseña"
                                className="EditModal-content"
                                overlayClassName="EditModal-overlay"
                            >
                                <h2>Cambiar Contraseña</h2>
                                {selectedUser && (
                                <div>
                                    <p>
                                    Cambiando contraseña de <strong>{selectedUser.player_name}</strong>
                                    </p>
                                    <input
                                    type={showPassword ? 'text' : 'password'}
                                    value={newPassword}
                                    onChange={(e) => setNewPasword(e.target.value)}
                                    placeholder="Nueva contraseña"
                                    />
                                    <button
                                        type="button-show"
                                        className="ocultar-password"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? 'Ocultar' : 'Mostrar'}
                                    </button>
                                    <div className="EditModal-buttons">
                                    <button onClick={handleSaveChangesP}>Guardar Cambios</button>
                                    <button onClick={closeEditPasswordModal}>Cancelar</button>
                                    </div>
                                </div>
                                )}
                            </Modal>
                      </div>
                  );
              case 'transferir-usuario':
                  return (
                      <div className="transfer-user-container">
                          <h3>Transferir a un usuario de la red</h3>
                          <div className="filters-container">
                              <div className='filters'>
                                  <div className="filter-group">
                                      <label>Nombre de usuario</label>
                                      <select>
                                          <option value="">Seleccione un usuario</option>
                                          <option value="usuario1">usuario1</option>
                                          <option value="usuario2">usuario2</option>
                                      </select>
                                  </div>
                                  <div className="filter-group">
                                      <label>Importe de la transacción</label>
                                      <input type="number" placeholder="Importe" />
                                  </div>
                              </div>
                              <div className="filter-buttons">
                                  <button className="reset-button">Limpiar</button>
                                  <button className="confirmar-button">Realizar</button>
                              </div>
                          </div>
                      </div>
                  );
              default:
                  return <h3>Seleccione una opción</h3>;
          }
         
  };


    // Contenido del recuadro grande basado en la opción seleccionada
    const renderContent = () => {
        switch (selectedOption) {
        case 'datos-personales':
            return (
            <div>
                <h3>Generales</h3>
                <div className="form-row">
                    <label className='label'>Nombre de Usuario</label>
                    <input type="text" readOnly value={user.cashier_name} />
                </div>
                <div className="form-row">
                    <label className='label'>CUIL</label>
                    <input type="text" readOnly value={user.cuil} />
                </div>
                {/* Número de Teléfono  <div className="security-box">
                            <h4>Número de teléfono</h4>
                            <div className="form-row">
                                <label>Móvil</label>
                                <input type="text" placeholder={user.phone} />
                            </div>
                </div>*/}
                
                <button className="save-button">Guardar Cambios</button>
            </div>
            );
        case 'seguridad':
            return (
                <div className="seguridad-container">
                    <h3>Seguridad</h3>
                    <div className="security-sections">
                        {/* Cambiar Contraseña */}
                        <div className="security-box">
                            <h4>Cambiar la contraseña</h4>
                            <div className="form-row1">
                                <label>Contraseña actual</label>
                                <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Ingrese su contraseña actual"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                
                                />
                                <button
                                    type="button-show"
                                    className="ocultar-password"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? 'Ocultar' : 'Mostrar'}
                                </button>
                            </div>
                            <div className="form-row1">
                                <label>Nueva contraseña</label>
                                <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Ingrese su nueva contraseña"
                                value={newUserPassword}
                                onChange={(e) => setNewUserPassword(e.target.value)}
                                />
                                <button
                                    type="button-show"
                                    className="ocultar-password"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? 'Ocultar' : 'Mostrar'}
                                </button>
                            </div>
                            <div className="form-row1">
                                <label>Confirmar nueva contraseña</label>
                                <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Confirme su nueva contraseña"
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                />
                                <button
                                    type="button-show"
                                    className="ocultar-password"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? 'Ocultar' : 'Mostrar'}
                                </button>
                            </div>
                            <button className="security-button" onClick={handlePasswordChange}>
                                Confirmar
                            </button>
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                            {successMessage && <p className="success-message">{successMessage}</p>}
                        </div>
                    </div>
                </div>
            );
            case 'transferencias':
                return (
                    <div className="transfer-container">
                        <h3>Transferencias</h3>
                        <div className="filters-container">
                            <div className="filters">
                                <div className="filter-group">
                                    <label>Desde</label>
                                    <input
                                        type="date"
                                        value={fromDate}
                                        onChange={(e) => setFromDate(e.target.value)}
                                    />
                                </div>
                                <div className="filter-group">
                                    <label>Hasta</label>
                                    <input
                                        type="date"
                                        value={toDate}
                                        onChange={(e) => setToDate(e.target.value)}
                                    />
                                </div>
                                <div className="filter-group">
                                    <label>Tipo de Movimiento</label>
                                    <select
                                        value={movementState}
                                        onChange={(e) => setMovementState(e.target.value)}
                                    >
                                        <option value="">Todos</option>
                                        <option value="DEPOSITO TELEPAGOS">Deposito</option>
                                        <option value="RETIRO TELEPAGOS">Retiros</option>
                                        <option value="TRANSFERENCIA REALIZADA">Transferencia Realizada</option>
                                        <option value="TRANSFERENCIA RECIBIDA">Transferencia Recibida</option>
                                        <option value="RETIRO POR CAJERO">Retiros del Cajero</option>
                                        <option value="ACREDITACION RETIRO CAJERO">Retiros realizados</option>
                                    </select>
                                </div>
                            </div>
                            <div className="filter-buttons">
                                <button className="search-button" onClick={applyFilters}>
                                    Buscar
                                </button>
                                <button className="reset-button" onClick={() => { setFromDate(''); setToDate(''); setMovementState(''); setMovements(allMovements); }}>
                                    Limpiar
                                </button>
                            </div>
                        </div>
                        <div className='tableT-container'>
                            <table className="infoT-table">
                                <thead>
                                    <tr>
                                        <th>Fecha y Hora</th>
                                        <th>Usuario Emisor</th>
                                        <th>Usuario Receptor</th>
                                        <th>Tipo</th>
                                        <th>Importe</th>
                                        <th>Saldo Anterior</th>
                                        <th>Saldo Final</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {movements.length > 0 ? (
                                        movements.map((item, index) => (
                                            <tr key={index}>
                                                    <td>{item.fecha_hora}</td>
                                                    <td>{item.senderName}</td>
                                                    <td>{item.receptorName}</td>
                                                    <td>{item.description}</td>
                                                    <td>{item.amount}</td>
                                                    <td>{item.balance_before}</td>
                                                    <td>{item.balance_after}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7">No hay datos disponibles</td>
                                            </tr>
                                    )}
                                </tbody>
                            </table>

                        </div>
                        
                    </div>
                );
        case 'lista-de-trasnferencias-red':
          return (
            <div className="transfer-container">
                <h3>Transferencias</h3>
                <div className="filters-container">
                    <div className="filters">
                        <div className="filter-group">
                            <label>Desde</label>
                            <input
                                type="date"
                                value={fromDate}
                                onChange={(e) => setFromDate(e.target.value)}
                            />
                        </div>
                        <div className="filter-group">
                            <label>Hasta</label>
                            <input
                                type="date"
                                value={toDate}
                                onChange={(e) => setToDate(e.target.value)}
                            />
                        </div>
                        <div className="filter-group">
                            <label>Id de usuario</label> {/* Cambié "Estado" por "Id de usuario" */}
                            <input
                                type="text"
                                placeholder="Ingrese Id de usuario"
                                value={movementState}
                                onChange={(e) => setMovementState(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="filter-buttons">
                        <button className="search-button" >
                            Buscar
                        </button>
                        <button className="reset-button" >
                            Limpiar
                        </button>
                    </div>
                </div>
                <table className="info-table">
                        <thead>
                            <tr>
                                <th>Fecha y Hora</th>
                                <th>Usuario</th>
                                <th>Tipo</th>
                                <th>Importe</th>
                                <th>Saldo Final</th>
                            </tr>
                        </thead>
                        <tbody>
                                    <tr >
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                        
                        </tbody>
                </table>
            </div>
        );
        case 'Invitar-usuarios':
            return (

                <div className="referral-section">
                    <h3>Invita a nuevos jugadores</h3>
                    
                    {/* Código QR */}
                    <div className="qr-container">
                    <QRCodeCanvas value={referrerUrl} size={128} />
                    </div>

                    {/* Link de Referido */}
                    <p>Comparte tu link de referido:</p>
                    <input type="text" value={referrerUrl} readOnly />
                    <button onClick={() => navigator.clipboard.writeText(referrerUrl)}>
                        Copiar
                    </button>
                </div>
            );
        default:
            return <div><h3>Seleccione una opción</h3></div>;
        }
    };



    return (
        <div className="container-miperfil">
        
          <div className="profile-buttons">
                <button
                className={`profile-button ${selectedButton === 'boton1' ? 'active' : ''}`}
                onClick={() => setSelectedButton('boton1')}
                >
                Mi Perfil
                </button>
                <button
                className={`profile-button ${selectedButton === 'boton2' ? 'active' : ''}`}
                onClick={() => setSelectedButton('boton2')}
                >
                Historial de Jugadas
                </button>
                <button
                className={`profile-button ${selectedButton === 'boton3' ? 'active' : ''}`}
                onClick={() => {
                    setSelectedButton('boton3'); 
                    setFilteredPlayers(players);
                }}
                >
                Usuarios
                </button>
                <button
                className={`profile-button ${selectedButton === 'boton4' ? 'active' : ''}`}
                onClick={() => setSelectedButton('boton4')}
                >
                Informe General y Comision
                </button>
          </div>

          {/*OPCIONES MI PERFIL*/}  
          {selectedButton === 'boton1' && (
          <div className="profile-container">
                {/* Recuadro pequeño con opciones */}
                <div className="myprofile-options">
                    <button
                        className={`option-button ${selectedOption === 'datos-personales' ? 'active' : ''}`}
                        onClick={() => setSelectedOption('datos-personales')}
                    >
                        Datos personales
                    </button>
                    <button
                        className={`option-button ${selectedOption === 'seguridad' ? 'active' : ''}`}
                        onClick={() => setSelectedOption('seguridad')}
                    >
                        Seguridad
                    </button>
                    <button
                        className={`option-button ${selectedOption === 'transferencias' ? 'active' : ''}`}
                        onClick={() => setSelectedOption('transferencias')}
                    >
                        Transferencias
                    </button>
                    <button
                        className={`option-button ${selectedOption === 'lista-de-trasnferencias-red' ? 'active' : ''}`}
                        onClick={() => setSelectedOption('lista-de-trasnferencias-red')}
                    >
                        Lista de Transferencias Red
                    </button>
                    <button
                        className={`option-button ${selectedOption === 'Invitar-usuarios' ? 'active' : ''}`}
                        onClick={() => setSelectedOption('Invitar-usuarios')}
                    >
                        Invitar Usuarios
                    </button>
                </div>
    
                {/* Recuadro grande con contenido */}
                <div className="profile-content">
                    {renderContent()}
                </div>
          </div>


          )}  

          {/* TABLA  JUGADAS*/}
          {selectedButton === 'boton2' && (
            <div className="table-container">
                {/* Contenedor de Filtros */}
                <div className="filtersT-container">
                <h3>JUGADAS</h3>
                <div className="filtersT">
                    <div className="filterT-group">
                    <label>Desde</label>
                    <input
                        type="date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                    />
                    </div>
                    <div className="filterT-group">
                    <label>Hasta</label>
                    <input
                        type="date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                    />
                    </div>
                    <div className="filterT-group">
                    <label>Estado</label>
                    <select
                        value={movementState}
                        onChange={(e) => setMovementState(e.target.value)}
                    >
                        <option value="">Todos</option>
                        <option value="Pendiente">Pendiente</option>
                        <option value="Completado">Completado</option>
                    </select>
                    </div>
                    <div className="filterT-group">
                    <label>Proveedores</label>
                    <select
                        value={movementProvider}
                        onChange={(e) => setMovementProvider(e.target.value)}
                    >
                        <option value="">Todos</option>
                        <option value="Proveedor1">Proveedor1</option>
                        <option value="Proveedor2">Proveedor2</option>
                    </select>
                    </div>
                    <div className="filterT-group">
                    <label>Nombre Usuario</label>
                    <input
                        type="text"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                    />
                    </div>
                    <div className="filterT-group">
                    <label>CUIL</label>
                    <input
                        type="number"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                    />
                    </div>
                </div>
                <div className="filterT-buttons">
                    <button className="search-button" >
                    Buscar
                    </button>
                    <button className="reset-button" >
                    Limpiar
                    </button>
                </div>
                </div>
                <table className="info-table">
                    <thead>
                        <tr>
                            <th>Fecha y Hora</th>
                            <th>Nombre del Usuario</th>
                            <th>Proveedor</th>
                            <th>ID del Movimiento</th>
                            <th>Monto</th>
                            <th>Tipo</th>
                            <th>Repeticion</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <Link></Link>
                                </td>
                            </tr>
                        
                    </tbody>
                </table>
            </div>
            )}
            

                
            {selectedButton === 'boton3' && (
                <div className="profile-container">
                    {/* Opciones en el recuadro izquierdo */}
                    <div className="myprofile-options">
                        
                            <>
                                <button
                                    className={`option-button ${selectedUserOption === 'buscar-usuario' ? 'active' : ''}`}
                                    onClick={() => setSelectedUserOption('buscar-usuario')}
                                >
                                    Buscar un usuario en la red
                                </button>
                                <button
                                    className={`option-button ${selectedUserOption === 'transferir-usuario' ? 'active' : ''}`}
                                    onClick={() => setSelectedUserOption('transferir-usuario')}
                                >
                                    Transferir a un usuario de la red
                                </button>
                                
                            </>
                        
                    </div>
                    {/* Contenido dinámico en el recuadro derecho */}
                    <div className="profile-content">{renderContent1()}</div>
                </div>
            )}

            {selectedButton === 'boton4' && (
            <div className="report-container">
                <div className='filters-container'>
                        {/* Slider de Quick Filters */}
                        <div className="quick-filters">
                            <button className="quick-button" onClick={() => handleQuickFilter("Hoy")}>Hoy</button>
                            <button className="quick-button" onClick={() => handleQuickFilter("Ayer")}>Ayer</button>
                            <button className="quick-button" onClick={() => handleQuickFilter("Semana actual")}>Semana actual</button>
                            <button className="quick-button" onClick={() => handleQuickFilter("Mes actual")}>Mes actual</button>
                            <button className="quick-button" onClick={() => handleQuickFilter("La semana pasada")}>Semana pasada</button>
                            <button className="quick-button" onClick={() => handleQuickFilter("El mes pasado")}>El mes pasado</button>
                        </div>

                        {/* Filtros de fecha debajo */}
                        <div className="date-filters">
                            <div>
                                <label>Fecha Desde:</label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Seleccionar fecha"
                                    maxDate={new Date()} 
                                />
                            </div>
                            <div>
                                <label>Fecha Hasta:</label>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Seleccionar fecha"
                                    minDate={startDate} 
                                    maxDate={new Date()} 
                                />
                            </div>
                        </div>

                        {/* Botón de aplicar filtro */}
                        <button className="apply-button" onClick={() => console.log("Aplicando filtro", { startDate, endDate })}>
                                Aplicar
                        </button>

                </div>

                <div>
                    {/* Sección de resumen */}
                    <div className="balance-section">
                        <h4>Balance General</h4>
                        <div className="balance-cards">
                        <div className="balance-card">
                            <h5>Recuento de apuestas</h5>
                            <p>10</p> {/* Valor directo */}
                        </div>
                        <div className="balance-card">
                            <h5>Monto de la apuesta</h5>
                            <p>6000 ARS</p>
                        </div>
                        <div className="balance-card">
                            <h5>Cantidad ganada</h5>
                            <p>2000 ARS</p>
                        </div>
                        <div className="balance-card">
                            <h5>Netwin</h5>
                            <p>4000 ARS</p>
                        </div>
                        <div className="balance-card">
                            <h5>Comisión</h5>
                            <p>2000 ARS</p>
                        </div>
                        </div>
                    </div>

                    {/* Tabla de detalles */}
                    <div className="table-container">
                        <h4>Detalles por Proveedor</h4>
                        <table className="info-table">
                        <thead>
                            <tr>
                            <th>Proveedor</th>
                            <th>Recuento de apuestas</th>
                            <th>Monto de la apuesta</th>
                            <th>Cantidad ganada</th>
                            <th>Netwin</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>PRAGMATIC</td>
                            <td>10</td>
                            <td>6000 ARS</td>
                            <td>2000 ARS</td>
                            <td>4000 ARS</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
            )}
            

        </div>
        
      );
};
    


export default CashierProfile;