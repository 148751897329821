import React from 'react';
import '../../assets/css/footer.css';

const Glosario = () => {
  return (
    <div className="footer-page">
        <div className='footer-page-title'>
            <h1>GLOSARIO DE APUESTAS</h1>
        </div>
        <div className='footer-page-content'>
            Aquí abajo se detallan los diferentes tipos de pronósticos que están disponibles para los usuarios según el deporte al cual desee apostar un usuario.
Fútbol Soccer
1X2
El Jugador apuesta al resultado final del partido.
Apuesta: 1 - Descripción: El equipo local ganará
Apuesta: X - Descripción: Empate
Apuesta: 2 - Descripción: El equipo visitante ganará
Doble oportunidad
Hay que pronosticar el ganador del partido eligiendo una de las opciones disponibles.
Apuesta: 1X - Descripción: El equipo local ganará el partido o el resultado será empate
Apuesta: 12 - Descripción: El equipo local o el equipo visitante ganará el partido
Apuesta: X2 - Descripción: El equipo visitante ganará el partido o el resultado será empate.
Doble Oportunidad – Primer Tiempo
Hay que pronosticar el ganador del partido eligiendo una de las opciones disponibles.
Apuesta: 12 HT - Descripción: El equipo local o el equipo visitante ganará el partido en el primer tiempo.
Apuesta: 1X HT - Descripción: El equipo local ganará el partido o el resultado será empate en el primer tiempo.
Apuesta: X2 HT - Descripción: El equipo visitante ganará el partido o el resultado será empate en el primer tiempo.
Doble Oportunidad – Segundo Tiempo
Hay que predecir qué equipo ganará el segundo tiempo, disponiendo de dos resultados de tres. Sólo se considerarán los goles marcados en el segundo tiempo.
Apuesta: 12 ST - Descripción: El equipo local o el equipo visitante ganará el partido en el segundo tiempo.
Apuesta: 1X ST - Descripción: El equipo local ganará el partido o el resultado será empate en el segundo tiempo.
Apuesta: X2 ST - Descripción: El equipo visitante ganará el partido o el resultado será empate en el segundo tiempo.
Primer Tiempo/Resultado Final
Hay que pronosticar el resultado al final del primer tiempo y al final del partido en la misma apuesta, eligiendo una de las opciones disponibles.
Apuesta: 1/1 - Descripción: El equipo local ganará al final del primer tiempo y al final del partido.
Apuesta: 1/X - Descripción: El equipo local ganará al final del primer tiempo y el resultado será empate al final del partido.
Apuesta: 1/2 - Descripción: El equipo local ganará al final del primer tiempo y el equipo visitante ganará al final del partido.
Apuesta: X/1 - Descripción: El resultado será empate al final del primer tiempo y el equipo local ganará al final del partido.
Apuesta: X/X - Descripción: El resultado será empate al final del primer tiempo y el resultado será empate al final del partido.
Apuesta: X/2 - Descripción: El resultado será empate al final del primer tiempo y el equipo visitante ganará al final del partido.
Apuesta: 2/1 - Descripción: El equipo visitante ganará al final del primer tiempo y el equipo local ganará al final del partido.
Apuesta: 2/X - Descripción: El equipo visitante ganará al final del primer tiempo y el resultado será empate al final del partido.
Apuesta: 2/2 - Descripción: El equipo visitante ganará al final del primer tiempo y al final del partido.
Primer tiempo
Hay que pronosticar el resultado del primer tiempo:
Apuesta: 1 HT - Descripción: Equipo local ganará el primer tiempo
Apuesta: X HT - Descripción: Ningún equipo ganará el primer tiempo
Apuesta: 2 HT - Descripción: Equipo visitante ganará el primer tiempo
Segundo tiempo
Hay que pronosticar el equipo que ganará el segundo tiempo eligiendo entre las diferentes opciones. Sólo los goles marcados en el segundo tiempo serán considerados válidos.
Apuesta: 1 ST - Descripción: Equipo local ganará el segundo tiempo
Apuesta: X ST - Descripción: Ningún equipo ganará el segundo tiempo
Apuesta: 2 ST - Descripción: Equipo visitante ganará el segundo tiempo
Resultado Exacto
Hay que pronosticar el resultado exacto del encuentro.
Apuesta: 0:0
Apuesta: 1:1
Apuesta: 2:2
Apuesta: 1:0
Apuesta: 2:0
Apuesta: 3:0
Apuesta: 0:1
Apuesta: 0:2
Apuesta: 0:3
Apuesta: 2:1
Apuesta: 3:1
Apuesta: 3:2
Apuesta: 1:2
Apuesta: 1:3
Apuesta: 2:3
Apuesta: Otros
Apuesta: 4:0
Apuesta: 0:4
Apuesta: 4:1
Apuesta: 1:4
Apuesta: 4:2
Apuesta: 2:4
Apuesta: 4:3
Apuesta: 3:4
Apuesta: 5:0
Apuesta: 0:5
Apuesta: 5:1
Apuesta: 1:5
Apuesta: 5:2
Apuesta: 2:5
Apuesta: 6:0
Apuesta: 0:6
Apuesta: 6:1
Apuesta: 7:0
Apuesta: 7:1
Apuesta: 8:0
Apuesta: 8:1
Apuesta: 9:0
Apuesta: 10:0
Apuesta: 3:3
Apuesta: 4:4
Apuesta: 0:7
Apuesta: 1:6
Resultado Exacto - Primer tiempo
Hay que pronosticar el resultado exacto del primer tiempo:
Apuesta: 0:0 HT
Apuesta: 0:1 HT
Apuesta: 0:2 HT
Apuesta: 1:0 HT
Apuesta: 1:1 HT
Apuesta: 1:2 HT
Apuesta: 2:0 HT
Apuesta: 2:1 HT
Apuesta: 2:2 HT
Apuesta: Others HT
Resultado Exacto (2) - Primer tiempo
Se debe pronosticar el resultado exacto con el cual termina el primer tiempo del encuentro. 
Apuesta: 3:0 - Descripción: Equipo local ganará 3:0 Primer Tiempo 
Apuesta: 0:3 - Descripción: Equipo visitante ganará 0:3 Primer Tiempo 
Apuesta: 4:0 - Descripción: Equipo local ganará 4:0 Primer Tiempo 
Apuesta: 3:1 - Descripción: Equipo local ganará 3:1 Primer Tiempo 
Apuesta: 1:3 - Descripción: Equipo visitante ganará 1:3 Primer Tiempo 
Apuesta: 0:4 - Descripción: Equipo visitante ganará 0:4 Primer Tiempo 
Apuesta: 5:0 - Descripción: Equipo local ganará 5:0 Primer Tiempo 
Apuesta: 0:5 - Descripción: Equipo visitante ganará 0:5 Primer Tiempo 
Apuesta: Other - Descripción: Se apuesta a un resultado al finalizar el primer tiempo, distinto a los incluidos en la lista (uno de los equipos marcará al menos 6 goles) 
Resultado Exacto - Segundo tiempo
Hay que pronosticar el resultado exacto del segundo tiempo: para la definición del resultado se consideran sólo los goles marcados en el segundo tiempo, los marcados en el primer tiempo no serán considerados.
Apuesta: 0:0 ST
Apuesta: 0:1 ST
Apuesta: 0:2 ST
Apuesta: 1:0 ST
Apuesta: 1:1 ST
Apuesta: 1:2 ST
Apuesta: 2:0 ST
Apuesta: 2:1 ST
Apuesta: 2:2 ST
Apuesta: Others ST
Resultado exacto (2) - Segundo tiempo
Se debe pronosticar el resultado exacto del segundo tiempo del encuentro. Para la definición del resultado se tienen en cuenta sólo y exclusivamente los goles marcados en el segundo tiempo del encuentro. Los goles marcados en el primer tiempo no serán considerados. 
Apuesta: 3:0 - Descripción: Equipo local ganará 3:0 Segunbdo Tiempo. 
Apuesta: 0:3 - Descripción: Equipo visitante ganará 0:3 Segundo Tiempo. 
Apuesta: 4:0 - Descripción: Equipo local ganará 4:0 Segunbdo Tiempo. 
Apuesta: 3:1 - Descripción: Equipo local ganará 3:1 Segunbdo Tiempo. 
Apuesta: 1:3 - Descripción: Equipo visitante ganará 1:3 Segundo Tiempo. 
Apuesta: 0:4 - Descripción: Equipo visitante ganará 0:4 Segundo Tiempo. 
Apuesta: 5:0 - Descripción: Equipo local ganará 5:0 Segunbdo Tiempo. 
Apuesta: 0:5 - Descripción: Equipo visitante ganará 0:5 Segundo Tiempo. 
Apuesta: Other - Descripción: Se apuesta a un resultado considerando sólo los goles marcados en el segundo tiempo, diferente a los incluidos en la lista. 
Cantidad Total de goles
Hay que pronosticar el número total de goles marcados durante el partido eligiendo uno de los tipos de apuesta disponibles.
Apuesta: TG 1 - Descripción: Total de goles en el partido será 1.
Apuesta: TG 2 - Descripción: Total de goles en el partido será 2.
Apuesta: TG 3 - Descripción: Total de goles en el partido será 3.
Apuesta: TG 4 - Descripción: Total de goles en el partido será 4.
Apuesta: TG 5 - Descripción: Total de goles en el partido será 5.
Apuesta: TG 6 - Descripción: Total de goles en el partido será 6.
Apuesta: TG 0 - Descripción: No se marcarán goles en el partido
Multigoles
Hay que pronosticar el número total de goles marcados durante el partido eligiendo una de las opciones disponibles.
Apuesta: 2-3 Goles - Descripción: Total de goles en el partido será entre 2 y 3.
Apuesta: 4-6 Goles - Descripción: Total de goles en el partido será entre 4 y 6.
Apuesta: 7+ Goles - Descripción: Total de goles en el partido será 7 o más.
Apuesta: 1-2 Goles - Descripción: Total de goles en el partido será entre 1 y 2.
Apuesta: 1-3 Goles - Descripción: Total de goles en el partido será entre 1 y 3.
Apuesta: 1-4 Goles - Descripción: Total de goles en el partido será entre 1 y 4.
Apuesta: 1-5 Goles - Descripción: Total de goles en el partido será entre 1 y 5.
Apuesta: 1-6 Goles - Descripción: Total de goles en el partido será entre 1 y 6.
Apuesta: 2-4 Goles - Descripción: Total de goles en el partido será entre 2 y 4.
Apuesta: 2-5 Goles - Descripción: Total de goles en el partido será entre 2 y 5.
Apuesta: 2-6 Goles - Descripción: Total de goles en el partido será entre 2 y 6.
Apuesta: 3-4 Goles - Descripción: Total de goles en el partido será entre 3 y 4.
Apuesta: 3-5 Goles - Descripción: Total de goles en el partido será entre 3 y 5.
Apuesta: 3-6 Goles - Descripción: Total de goles en el partido será entre 3 y 6.
Apuesta: 4-5 Goles - Descripción: Total de goles en el partido será entre 4 y 5.
Apuesta: 5-6 Goles - Descripción: Total de goles en el partido será entre 5 y 6.
Par/Impar
Hay que pronosticar si la suma total de los goles de un encuentro será par (even) o impar (odd). El resultado de 0-0 se considera par.
Apuesta: Even - Descripción: Par
Apuesta: Odd - Descripción: Impar
Par/ Impar - Primer Tiempo
Hay que pronosticar si la suma total de los goles en el primer tiempo será par (Even) o impar (Odd). El resultado de 0-0 en el primer tiempo se considera par (Even).
Apuesta: Even HT - Descripción: Par HT
Apuesta: Odd HT - Descripción: Impar HT
Par/ Impar - Segundo Tiempo
Hay que pronosticar si la suma total de los goles en el segundo tiempo será par (Even) o impar (Odd). El resultado de 0-0 en el segundo tiempo se considera par (Even). Para la definición del resultado, los goles marcados en el primer tiempo no serán considerados.
Apuesta: Even ST - Descripción: Par ST
Apuesta: Odd ST - Descripción: Impar ST
Goles – Más/Menos de 0.5
Hay que pronosticar si la suma de los goles será mayor (Over) o menor (Under) que el número indicado.
Apuesta: Under 0.5 - Descripción: Menos 0.5
Apuesta: Over 0.5 - Descripción: Más 0.5
Goles – Más/Menos de 1.5
Hay que pronosticar si la suma de los goles será mayor (Over) o menor (Under) que el número indicado.
Apuesta: Under 1.5 - Descripción: Menos 1.5
Apuesta: Over 1.5 - Descripción: Más 1.5
Goles – Más/Menos de 2.5
Hay que pronosticar si la suma de los goles será mayor (Over) o menor (Under) que el número indicado.
Apuesta: Over - Descripción: Más 2.5
Apuesta: Under - Descripción: Menos 2.5
Goles – Más/Menos de 3.5
Hay que pronosticar si la suma de los goles será mayor (Over) o menor (Under) que el número indicado.
Apuesta: Under 3.5 - Descripción: Menos 3.5
Apuesta: Over 3.5 - Descripción: Más 3.5
Goles – Más/Menos de 4.5
Hay que pronosticar si la suma de los goles será mayor (Over) o menor (Under) que el número indicado.
Apuesta: Under 4.5 - Descripción: Menos 4.5
Apuesta: Over 4.5 - Descripción: Más 4.5
Goles – Más/Menos de 5.5
Hay que pronosticar si la suma de los goles será mayor (Over) o menor (Under) que el número indicado.
Apuesta: Under 5.5 - Descripción: Menos 5.5
Apuesta: Over 5.5 - Descripción: Más 5.5
Primer tiempo - Goles – Más/Menos de 0.5
Hay que pronosticar si la suma de los goles del primer tiempo será mayor (Over) o menor (Under) que el número (spread) indicado.
Apuesta: UN HT 0.5 - Descripción: Menos 0.5 HT
Apuesta: OV HT 0.5 - Descripción: Más 0.5 HT
Primer tiempo - Goles – Más/Menos de  1.5
Hay que pronosticar si la suma de los goles del primer tiempo será mayor (Over) o menor (Under) que el número (spread) indicado.
Apuesta: UN HT 1.5 - Descripción: Menos 1.5 HT
Apuesta: OV HT 1.5 - Descripción: Más 1.5 HT
Primer Tiempo - Goles – Más/Menos de 2.5 
Hay que pronosticar si la suma de los goles del primer tiempo será mayor (Over) o menor (Under) que el número (spread) indicado. 
Apuesta: UN HT 2.5 - Descripción: Menos 2.5 HT 
Apuesta: OV HT 2.5 - Descripción: Más 2.5 HT 
Segundo Tiempo Goles – Más/Menos de 0.5
Hay que pronosticar si la suma de los goles del segundo tiempo será mayor (Over) o menor (Under) que el número (spread) indicado
Apuesta: UN ST 0.5 - Descripción: Menos 0.5 ST
Apuesta: OV ST 0.5 - Descripción: Más 0.5 ST
Segundo Tiempo - Goles – Más/Menos de 1.5
Hay que pronosticar si la suma de los goles del segundo tiempo será mayor (Over) o menor (Under) que el número (spread) indicado
Apuesta: UN ST 1.5 - Descripción: Menos 1.5 ST
Apuesta: OV ST 1.5 - Descripción: Más 1.5 ST
Segundo Tiempo - Goles – Más/Menos de 2.5 
Hay que pronosticar si la suma de los goles del segundo tiempo será mayor (Over) o menor (Under) que el número (spread) indicado 
Apuesta: UN ST 2.5 - Descripción: Menos 2.5 ST 
Apuesta: OV ST 2.5 - Descripción: Más 2.5 ST 
Marcan goles SI/NO
Hay que pronosticar si el equipo seleccionado (local o visitante) conseguirá marcar al menos un gol o no.
Apuesta: Home Score - Descripción: Equipo Local: SI
Apuesta: Home No Score - Descripción: Equipo Local: NO
Apuesta: Away Score - Descripción: Equipo Visitante: SI
Apuesta: Away No Score - Descripción: Equipo Visitante: NO
Ambos Equipos Marcan SI/NO
Dos resultados posibles:
Apuesta: GG - Descripción: Ambos equipos marcan al menos 1 gol.
Apuesta: NG - Descripción: Un equipo o ambos no consiguen marcar goles.
Ambos Equipos Marcan SI/NO - Primer tiempo
Hay que pronosticar si ambos equipos marcarán en el primer tiempo o no.
Apuesta: GG HT - Descripción: Ambos equipos marcarán por lo menos un gol en el primer tiempo.
Apuesta: NG HT - Descripción: Por lo menos un equipo no marcará en el primer tiempo.
Ambos Equipos Marcan SI/NO - Segundo Tiempo
Hay que pronosticar si ambos equipos marcarán en el segundo tiempo o no.
Apuesta: GG ST - Descripción: Ambos equipos marcarán por lo menos un gol en el segundo tiempo.
Apuesta: NG ST - Descripción: Por lo menos un equipo no marcará en el segundo tiempo.
Ambos Equipos Marcan SI/NO - Primer tiempo / Final
Hay que pronosticar si ambos equipos marcarán o no tanto en el primer tiempo como en el segundo. Hay cuatro posibles resultados:
Apuesta: GG AND GG - Descripción: Ambos equipos marcarán por lo menos un gol en el primer y en el segundo tiempo.
Apuesta: GG AND NG - Descripción: Ambos equipos marcarán por lo menos un gol en el primer tiempo, pero por lo menos uno no marcará en el segundo tiempo.
Apuesta: NG AND GG - Descripción: Por lo menos un equipo no marcará en el primer tiempo y ambos equipos marcarán por lo menos un gol en el segundo tiempo.
Apuesta: NG AND NG - Descripción: Por lo menos uno de los equipos no marcará en el primer tiempo y por lo menos un equipo no marcará en el segundo tiempo.
Tiempo con mayor cantidad de goles
Hay que pronosticar cuál será la parte de juego (primer  o segundo tiempo) en la que se marcará el mayor número de goles. En caso de un mismo número de goles marcados en el primer y en el segundo tiempo, o en caso de que no se haya ningún gol en los 2 tiempos, la solución ganadora será "X".
Apuesta: HT Half More G - Descripción: Si en el primer tiempo se marcan más goles que el segundo, la solución ganadora será "1".
Apuesta: No Half More G - Descripción: Si en el primer tiempo y en el segundo tiempo se marcan el mismo número de goles, o si no se marcan goles en ambos tiempos, la solución ganadora será "X".
Apuesta: ST Half More G - Descripción: Si en el segundo tiempo se marcan más goles que en el primero, la solución ganadora será "2".
Equipo Local - Goles – Más/Menos de 0.5
Hay que pronosticar si la suma total de goles marcados por el equipo local será mayor (Más) o menor (Menos) que el número (spread) indicado
Apuesta: Home UN 0.5 - Descripción: Local Menos 0.5
Apuesta: Home OV 0.5 - Descripción: Local Más 0.5
Equipo Visitante - Goles – Más/Menos de 0.5
Hay que pronosticar si la suma total de goles marcados por el equipo visitante será mayor (Más) o menor (Menos) que el número (spread) indicado
Apuesta: Away UN 0.5 - Descripción: Visitante Menos 0.5
Apuesta: Away OV 0.5 - Descripción: Visitante Más 0.5
Equipo Local - Goles – Más/Menos de 1.5
Hay que pronosticar si la suma total de goles marcados por el equipo local será mayor (Más) o menor (Menos) que el número (spread) indicado
Apuesta: Home UN 1.5 - Descripción: Local Menos 1.5
Apuesta: Home OV 1.5 - Descripción: Local Más 1.5
Equipo Visitante - Goles – Más/Menos de 1.5
Hay que pronosticar si la suma total de goles marcados por el equipo visitante será mayor (Más) o menor (Menos) que el número (spread) indicado
Apuesta: Away UN 1.5 - Descripción: Visitante Menos 1.5
Apuesta: Away OV 1.5 - Descripción: Visitante Más 1.5
Equipo Local Gana 0
El Equipo local gana 0
Apuesta: Home Win 0 Yes - Descripción: El equipo local gana sin sufrir un gol.
Apuesta: Home Win 0 No - Descripción: El equipo local gana sufriendo goles o no gana.
Equipo Visitante Gana 0
El Equipo visitante gana 0
Apuesta: Away Win 0 Yes - Descripción: El equipo visitante gana sin sufrir goles.
Apuesta: Away Win 0 No - Descripción: El equipo visitante gana sufriendo goles o no gana.
Combo
Hay que pronosticar el resultado del partido, apostando por dos tipos de apuestas conectados.
Apuesta: 1 AND TG2- - Descripción: El equipo local ganará y el número total de goles será de 2 o menos.
Apuesta: 1 AND TG3+ - Descripción: El equipo local ganará y el número total de goles será de 3 o más.
Apuesta: 1 AND TG3- - Descripción: El equipo local ganará y el número total de goles será de 3 o menos.
Apuesta: 1 AND TG4+ - Descripción: El equipo local ganará y el número total de goles será de 4 o más.
Apuesta: 1 AND GG - Descripción: El equipo local ganará y ambos equipos marcarán por lo menos 1 gol.
Apuesta: 1 AND NG - Descripción: El equipo local ganará y al menos un equipo no marcará.
Apuesta: 2 AND TG2- - Descripción: El equipo visitante ganará y el número total de goles será de 2 o menos.
Apuesta: 2 AND TG3+ - Descripción: El equipo visitante ganará y el número total de goles será de 3 o más.
Apuesta: 2 AND TG3- - Descripción: El equipo visitante ganará y el número total de goles será de 3 o menos.
Apuesta: 2 AND TG4+ - Descripción: El equipo visitante ganará y el número total de goles será de 4 o más.
Apuesta: 2 AND GG - Descripción: El equipo visitante ganará y ambos equipos marcarán por lo menos 1 gol.
Apuesta: 2 AND NG - Descripción: El equipo visitante ganará y al menos un equipo no marcará.
Apuesta: GG AND TG2- - Descripción: Ambos equipos marcarán y el número total de goles será de 2 o menos.
Apuesta: GG AND TG3+ - Descripción: Ambos equipos marcarán y el número total de goles será de 3 o más.
Apuesta: NG AND TG2- - Descripción: Por lo menos un equipo no marcará, y el número total de goles será de 2 o menos.
Apuesta: NG AND TG3+ - Descripción: Por lo menos un equipo no marcará, y el número total de goles será de 3 o más.
Apuesta: X AND TG2- - Descripción: El resultado será empate y el número total de goles será de 2 o menos.
Apuesta: X AND TG3+ - Descripción: El resultado será empate y el número total de goles será de 3 o más.
Apuesta: X AND GG - Descripción: El resultado será empate y ambos equipos marcarán por lo menos 1 gol
Apuesta: X AND NG - Descripción: El resultado será empate y al menos un equipo no marcará.
Apuesta: 1 AND TG2+ - Descripción: El equipo local ganará el encuentro y la suma de los goles será de 2 o más goles.
Apuesta: 2 AND TG2+ - Descripción: El equipo visitante ganará el encuentro y la suma de los goles será de 2 o más goles.
Apuesta: X AND TG3- - Descripción: Los equipos empatarán y la suma de los goles será de 3 o menos.
Apuesta: X AND TG4+ - Descripción: Los equipos empatarán y la suma de los goles será de 4 o más.
Apuesta: 1 AND TG1- - Descripción: El equipo local ganará el partido y la suma de los goles será de 1
Apuesta: X AND TG1- - Descripción: Los equipos empatarán y la suma de los goles será 0.
Apuesta: 2 AND TG1- - Descripción: El equipo visitante ganará el partido y la suma de los goles será 1.
Apuesta: X AND TG2+ - Descripción: Los equipos empatarán y la suma de los goles será de 2 ó más.
Apuesta: 1 AND TG5+ - Descripción: El equipo local ganará el partido y la suma de los goles será de 5 o más.
Apuesta: X AND TG5+ - Descripción: Los equipos empatarán y la suma de los goles será de 5 o más.
Apuesta: 2 AND TG5+ - Descripción: El equipo visitante ganará el partido y la suma de los goles será de 5 o más.
Doble Oportunidad Combo
Doble Oportunidad Combo (DC Combo) le da la posibilidad de pronosticar el resultado del partido realizando una apuesta que representa la combinación de 2 apuestas relacionadas, una de las cuales siempre tiene que ser Doble Oportunidad.
Apuesta: 12 AND TG2- - Descripción: Uno de los equipos ganará el partido y el número total de goles será 2 o menos.
Apuesta: 12 AND TG3+ - Descripción: Uno de los equipos ganará el partido y el número total de goles será 3 o más goles.
Apuesta: 12 AND TG3- - Descripción: Uno de los equipos ganará el partido y el número total de goles será 3 o menos goles.
Apuesta: 12 AND TG4+ - Descripción: Uno de los equipos ganará el partido y el número total de goles será 4 o más goles.
Apuesta: 12 AND GG - Descripción: Uno de los equipos ganará el partido y ambos los equipos marcarán por lo menos un gol.
Apuesta: 12 AND NG - Descripción: Uno de los equipos ganará el partido y por lo menos uno de los equipos no marcará ningún gol.
Apuesta: 1X AND TG2- - Descripción: El equipo local ganará o empatará el partido, y el número total de goles será 2 o menos.
Apuesta: 1X AND TG3+ - Descripción: El equipo local ganará o empatará el partido, y el número total de goles será de 3 o más goles.
Apuesta: 1X AND TG3- - Descripción: El equipo local ganará o empatará el partido, y el número total de goles será de 3 o menos goles.
Apuesta: 1X AND TG4+ - Descripción: El equipo local ganará o empatará el partido, y el número total de goles será 4 o más goles.
Apuesta: 1X AND GG - Descripción: El equipo local ganará o empatará el partido, y ambos los equipos marcarán.
Apuesta: 1X AND NG - Descripción: El equipo local ganará o empatará el partido, y por lo menos uno de los equipos no marcará ningún gol.
Apuesta: X2 AND TG2- - Descripción: El equipo visitante ganará o empatará el partido, y el número total de los goles será 2 o menos.
Apuesta: X2 AND TG3+ - Descripción: El equipo visitante ganará o empatará el partido, y el número total de los goles será de 3 o más goles.
Apuesta: X2 AND TG3- - Descripción: El equipo visitante ganará o empatará el partido, y el número total de los goles será de 3 o menos goles.
Apuesta: X2 AND TG4+ - Descripción: El equipo visitante ganará o empatará el partido, y el número total de los goles será 4 o más goles.
Apuesta: X2 AND GG - Descripción: El equipo visitante ganará o empatará el partido, y ambos los equipos marcarán.
Apuesta: X2 AND NG - Descripción: El equipo visitante ganará o empatará el partido, y por lo menos uno de los dos equipos no marcará.
Apuesta: 1X AND TG2+ - Descripción: El equipo local ganará o empatará el encuentro y habrán 2 o más goles.
Apuesta: 1X AND TG1- - Descripción: El equipo local ganará o empatará el partido y habrá 1 o 0 goles.
Apuesta: X2 AND TG2+ - Descripción: El equipo visitante ganará o empatará el partido y habrá 2 o más goles
Apuesta: X2 AND TG1- - Descripción: El equipo visitante ganará o empatará el partido y habrá 1 o 0 goles.
Apuesta: 12 AND TG2+ - Descripción: Uno de los equipos ganará el partido y habrá 2 o más goles.
Apuesta: 12 AND TG1- - Descripción: Uno de los equipos ganará el partido y el número total de goles será 1.
Hándicap asiático 
El resultado de una apuesta con hándicap asiático se calcula sumando el hándicap al resultado final del encuentro (o sustrayéndolo). El valor expresado entre paréntesis se refiere siempre al equipo local, por consiguiente un número negativo significa que la desventaja se refiere al equipo local, mientras que un número positivo significa que la desventaja se refiere al equipo visitante. Apostando en 1AH se apuesta en la victoria del equipo local considerando el hándicap, mientras que apostando en 2AH se apuesta en la victoria del equipo visitante siempre considerando el hándicap. 
Apuesta: 1AH (-5.50) - Descripción: en caso de victoria del equipo local con 6 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 5 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-5.50) - Descripción: en caso de victoria del equipo local con 6 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 5 o menos goles de diferencia, de empate o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-4.75) - Descripción: en caso de victoria del equipo local con 6 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 5 goles exactos de diferencia la apuesta es ganadora por mitad. En caso de victoria del equipo local con 4 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-4.75) - Descripción: en caso de victoria del equipo local con 6 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 5 goles exactos de diferencia la apuesta es perdedora por mitad. En caso de victoria del equipo local con 4 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-4.50) - Descripción: en caso de victoria del equipo local con 5 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 4 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-4.50) - Descripción: en caso de victoria del equipo local con 5 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 4 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-4.25) - Descripción: en caso de victoria del equipo local con 5 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 4 goles exactos de diferencia la apuesta es perdedora por mitad. En caso de victoria del equipo local con 3 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-4.25) - Descripción: en caso de victoria del equipo local con 5 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 4 goles exactos de diferencia la apuesta es ganadora por mitad. En caso de victoria del equipo local con 3 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-4.00) - Descripción: en caso de victoria del equipo local con 5 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 4 goles exactos de diferencia la apuesta será devuelta. En caso de victoria del equipo local con 3 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-4.00) - Descripción: en caso de victoria del equipo local con 5 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 4 goles exactos de diferencia la apuesta será devuelta. En caso de victoria del equipo local con 3 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-3.75) - Descripción: en caso de victoria del equipo local con 5 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 4 goles exactos de diferencia la apuesta es ganadora por mitad. En caso de victoria del equipo local con 3 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-3.75) - Descripción: en caso de victoria del equipo local con 5 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 4 goles exactos de diferencia la apuesta es perdedora por mitad. En caso de victoria del equipo local con 3 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-3.50) - Descripción: en caso de victoria del equipo local con 4 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 3 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-3.50) - Descripción: en caso de victoria del equipo local con 4 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 3 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-3.25) - Descripción: en caso de victoria del equipo local con 4 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 3 goles exactos de diferencia la apuesta es perdedora por mitad. En caso de victoria del equipo local con 2 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-3.25) - Descripción: en caso de victoria del equipo local con 4 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 3 goles exactos de diferencia la apuesta es ganadora por mitad. En caso de victoria del equipo local con 2 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-3.00) - Descripción: en caso de victoria del equipo local con 4 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 3 goles exactos de diferencia la apuesta será devuelta. En caso de victoria del equipo local con 2 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-3.00) - Descripción: en caso de victoria del equipo local con 4 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 3 goles exactos de diferencia la apuesta será devuelta. En caso de victoria del equipo local con 2 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-2.75) - Descripción: en caso de victoria del equipo local con 4 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 3 goles exactos de diferencia la apuesta es ganadora por mitad. En caso de victoria del equipo local con 2 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-2.75) - Descripción: en caso de victoria del equipo local con 4 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 3 goles exactos de diferencia la apuesta es perdedora por mitad. En caso de victoria del equipo local con 2 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-2.50) - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 2 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-2.50) - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 2 o menos goles de diferencia, de empate o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-2.25) - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 2 goles exactos de diferencia la apuesta es perdedora por mitad. En caso de victoria del equipo local con 1 gol de diferencia, de empate o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-2.25) - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 2 goles exactos de diferencia la apuesta es ganadora por mitad. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-2.00) - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 2 goles exactos de diferencia la apuesta será devuelta. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-2.00) - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 2 goles exactos de diferencia la apuesta será devuelta. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-1.75) - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 2 goles exactos de diferencia la apuesta es ganadora por mitad. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-1.75) - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 2 goles exactos de diferencia la apuesta es perdedora por mitad. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-1.50) - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-1.50) - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-1.25) - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 1 gol exacto de diferencia la apuesta es perdedora por mitad. En caso de empate, o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-1.25) - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 1 gol exacto de diferencia la apuesta es ganadora por mitad. En caso de empate, o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-1.00) - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 1 gol exacto de diferencia la apuesta será devuelta. En caso de empate, o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-1.00) - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 1 gol exacto de diferencia la apuesta será devuelta. En caso de empate, o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-0.75) - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 1 gol exacto de diferencia la apuesta es ganadora por mitad. En caso de empate, o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-0.75) - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 1 gol exacto de diferencia la apuesta es perdedora por mitad. En caso de empate, o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-0.50) - Descripción: en caso de victoria del equipo local la apuesta es ganadora. En caso de empate o de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-0.50) - Descripción: en caso de victoria del equipo local la apuesta es perdedora. En caso de empate o de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (-0.25) - Descripción: en caso de victoria del equipo local la apuesta es ganadora. En caso de empate la apuesta es perdedora por mitad. En caso de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (-0.25) - Descripción: en caso de victoria del equipo local la apuesta es perdedora. En caso de empate la apuesta es ganadora por mitad. En caso de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (0.00) - Descripción: en caso de victoria del equipo local con 1 o más goles de diferencia la apuesta es ganadora. En caso de empate la apuesta será devuelta. En caso de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (0.00) - Descripción: en caso de victoria del equipo local con 1 o más goles de diferencia la apuesta es perdedora. En caso de empate la apuesta será devuelta. En caso de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (0.25) - Descripción: en caso de victoria del equipo local la apuesta es ganadora. En caso de empate la apuesta es ganadora por mitad. En caso de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (0.25) - Descripción: en caso de victoria del equipo local la apuesta es perdedora. En caso de empate la apuesta es perdedora por mitad. En caso de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (0.50) - Descripción: en caso de victoria del equipo local o de empate la apuesta es ganadora. En caso de victoria del equipo visitante, la apuesta es perdedora 
Apuesta: 2AH (0.50) - Descripción: en caso de victoria del equipo local o de empate la apuesta es perdedora. En caso de victoria del equipo visitante, la apuesta es ganadora 
Apuesta: 1AH (0.75) - Descripción: en caso de victoria del equipo local o de empate la apuesta es ganadora. En caso de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es perdedora por mitad. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es perdedora 
Apuesta: 2AH (0.75) - Descripción: en caso de victoria del equipo local o de empate la apuesta es perdedora. En caso de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es ganadora por mitad. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es ganadora 
Apuesta: 1AH (1.00) - Descripción: en caso de victoria del equipo local o de empate la apuesta es ganadora. En caso de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta será devuelta. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es perdedora 
Apuesta: 2AH (1.00) - Descripción: en caso de victoria del equipo local o de empate la apuesta es perdedora. En caso de victoria del equipo visitante con 1 gol exacto de diferencia la apuesta será devuelta. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es ganadora 
Apuesta: 1AH (1.25) - Descripción: en caso de victoria del equipo local, o de empate, la apuesta es ganadora. En caso de victoria del equipo visitante con 1 gol exacto de diferencia la apuesta es ganadora por mitad. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es perdedora 
Apuesta: 2AH (1.25) - Descripción: en caso de victoria del equipo local, o de empate, la apuesta es perdedora. En caso de victoria del equipo visitante con 1 gol exacto de diferencia la apuesta es perdedora por mitad. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (1.50) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (1.50) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (1.75) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 2 goles exactos de diferencia la apuesta es perdedora por mitad. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (1.75) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 2 goles exactos de diferencia la apuesta es ganadora por mitad. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (2.00) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 2 goles exactos de diferencia, la apuesta será devuelta. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (2.00) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 2 goles exactos de diferencia, la apuesta será devuelta. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (2.25) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 2 goles exactos de diferencia, la apuesta es ganadora por mitad. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (2.25) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 2 goles exactos de diferencia, la apuesta es perdedora por mitad. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (2.50) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 2 o menos goles de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (2.50) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 2 o menos goles de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (2.75) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 2 o menos goles de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 3 goles exactos de diferencia, la apuesta es perdedora por mitad. En caso de victoria del equipo visitante con 4 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (2.75) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 2 o menos goles de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 3 goles exactos de diferencia, la apuesta es ganadora por mitad. En caso de victoria del equipo visitante con 4 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (3.00) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 2 o menos goles de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 3 goles exactos de diferencia, la apuesta será devuelta. En caso de victoria del equipo visitante con 4 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (3.00) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 2 o menos goles de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 3 goles exactos de diferencia, la apuesta será devuelta. En caso de victoria del equipo visitante con 4 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (3.25) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 2 o menos goles de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 3 goles exactos de diferencia, la apuesta es ganadora por mitad. En caso de victoria del equipo visitante con 4 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (3.25) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 2 o menos goles de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 3 goles exactos de diferencia, la apuesta es perdedora por mitad. En caso de victoria del equipo visitante con 4 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (3.50) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 3 o menos goles de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 4 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (3.50) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 3 o menos goles de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 4 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (3.75) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 4 goles exactos de diferencia, la apuesta es perdedora por mitad. En caso de victoria del equipo visitante con 5 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (3.75) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 3 o menos goles de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 4 goles exactos de diferencia, la apuesta es ganadora por mitad. En caso de victoria del equipo visitante con 5 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (4.00) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 3 o menos goles de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 4 goles exactos de diferencia, la apuesta será devuelta. En caso de victoria del equipo visitante con 5 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (4.00) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 3 o menos goles de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 4 goles exactos de diferencia, la apuesta será devuelta. En caso de victoria del equipo visitante con 5 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (4.25) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 3 o menos goles de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 4 goles exactos de diferencia, la apuesta es ganadora por mitad. En caso de victoria del equipo visitante con 5 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (4.25) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 3 o menos goles de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 4 goles exactos de diferencia, la apuesta es perdedora por mitad. En caso de victoria del equipo visitante con 5 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (4.50) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 4 o menos goles de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 5 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (4.50) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 4 o menos goles de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 5 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (5.50) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 5 o menos goles de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 6 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (5.50) - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 5 o menos goles de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 6 o más goles de diferencia, la apuesta es ganadora.   
Hándicap asiático - Primer tiempo
Hándicap asiático primer tiempo: El resultado de una apuesta hándicap asiático - primer tiempo se calcula sumando el hándicap al resultado final del encuentro (o sustrayéndolo). El valor expresado entre paréntesis se refiere siempre al equipo local, por consiguiente un número negativo significa que la desventaja se refiere al equipo local, mientras que un número positivo significa que la desventaja se refiere al equipo visitante. Apostando en 1AH HT se apuesta en la victoria del equipo local considerando el hándicap, mientras que apostando en 2AH HT se apuesta en la victoria del equipo visitante siempre considerando el hándicap.  
Apuesta: 1AH (-2.50) HT - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 2 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-2.50) HT - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 2 o menos goles de diferencia, de empate o de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (-2.25) HT - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 2 goles exactos de diferencia la apuesta es perdedora por mitad. En caso de victoria del equipo local con 1 gol de diferencia, de empate o de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-2.25) HT - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 2 goles exactos de diferencia la apuesta es ganadora por mitad. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (-2.00) HT - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 2 goles exactos de diferencia la apuesta será devuelta. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-2.00) HT - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 2 goles exactos de diferencia la apuesta será devuelta. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (-1.75) HT - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 2 goles exactos de diferencia la apuesta es ganadora por mitad. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-1.75) HT - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 2 goles exactos de diferencia la apuesta es perdedora por mitad. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (-1.50) HT - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-1.50) HT - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (-1.25) HT - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 1 gol exacto de diferencia la apuesta es perdedora por mitad. En caso de empate, o de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-1.25) HT - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 1 gol exacto de diferencia la apuesta es ganadora por mitad. En caso de empate, o de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (-1.00) HT - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 1 gol exacto de diferencia la apuesta será devuelta. En caso de empate, o de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-1.00) HT - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 1 gol exacto de diferencia la apuesta será devuelta. En caso de empate, o de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (-0.75) HT - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 1 gol exacto de diferencia la apuesta es ganadora por mitad. En caso de empate, o de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-0.75) HT - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 1 gol exacto de diferencia la apuesta es perdedora por mitad. En caso de empate, o de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (-0.50) HT - Descripción: en caso de victoria del equipo local la apuesta es ganadora. En caso de empate o de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-0.50) HT - Descripción: en caso de victoria del equipo local la apuesta es perdedora. En caso de empate o de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (-0.25) HT - Descripción: en caso de victoria del equipo local la apuesta es ganadora. En caso de empate la apuesta es perdedora por mitad. En caso de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-0.25) HT - Descripción: en caso de victoria del equipo local la apuesta es perdedora. En caso de empate la apuesta es ganadora por mitad. En caso de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (0.00) HT - Descripción: en caso de victoria del equipo local con 1 o más goles de diferencia la apuesta es ganadora. En caso de empate la apuesta será devuelta. En caso de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (0.00) HT - Descripción: en caso de victoria del equipo local con 1 o más goles de diferencia la apuesta es perdedora. En caso de empate la apuesta será devuelta. En caso de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (0.25) HT - Descripción: en caso de victoria del equipo local la apuesta es ganadora. En caso de empate la apuesta es ganadora por mitad. En caso de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (0.25) HT - Descripción: en caso de victoria del equipo local la apuesta es perdedora. En caso de empate la apuesta es perdedora por mitad. En caso de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (0.50) HT - Descripción: en caso de victoria del equipo local o de empate la apuesta es ganadora. En caso de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (0.50) HT - Descripción: en caso de victoria del equipo local o de empate la apuesta es perdedora. En caso de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (0.75) HT - Descripción: en caso de victoria del equipo local o de empate la apuesta es ganadora. En caso de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es perdedora por mitad. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (0.75) HT - Descripción: en caso de victoria del equipo local o de empate la apuesta es perdedora. En caso de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es ganadora por mitad. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (1.00) HT - Descripción: en caso de victoria del equipo local o de empate la apuesta es ganadora. En caso de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta será devuelta. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (1.00) HT - Descripción: en caso de victoria del equipo local o de empate la apuesta es perdedora. En caso de victoria del equipo visitante con 1 gol exacto de diferencia la apuesta será devuelta. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (1.25) HT - Descripción: en caso de victoria del equipo local, o de empate, la apuesta es ganadora. En caso de victoria del equipo visitante con 1 gol exacto de diferencia la apuesta es ganadora por mitad. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (1.25) HT - Descripción: en caso de victoria del equipo local, o de empate, la apuesta es perdedora. En caso de victoria del equipo visitante con 1 gol exacto de diferencia la apuesta es perdedora por mitad. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (1.50) HT - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (1.50) HT - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (1.75) HT - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 2 goles exactos de diferencia la apuesta es perdedora por mitad. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (1.75) HT - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 2 goles exactos de diferencia la apuesta es ganadora por mitad. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (2.00) HT - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 2 goles exactos de diferencia, la apuesta será devuelta. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (2.00) HT - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 2 goles exactos de diferencia, la apuesta será devuelta. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (2.25) HT - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 2 goles exactos de diferencia, la apuesta es ganadora por mitad. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (2.25) HT - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 2 goles exactos de diferencia, la apuesta es perdedora por mitad. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (2.50) HT - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 2 o menos goles de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (2.50) HT - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 2 o menos goles de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es ganadora.  
Hándicap asiático - Segundo tiempo
El resultado de una apuesta hándicap asiático - segundo tiempo se calcula sumando el hándicap al resultado final del segundo tiempo (o sustrayéndolo). El valor expresado entre paréntesis se refiere siempre al equipo local, por consiguiente un número negativo significa que la desventaja se refiere al equipo local, mientras que un número positivo significa que la desventaja se refiere al equipo visitante. Apostando en 1AH ST se apuesta en la victoria del equipo local considerando el hándicap, mientras que apostando en 2AH Segundo tiempo se apuesta en la victoria del equipo visitante siempre considerando el hándicap.  
Apuesta: 1AH (-2.50) ST - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 2 o menos goles de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-2.50) ST - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 2 o menos goles de diferencia, de empate o de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (-2.25) ST - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 2 goles exactos de diferencia la apuesta es perdedora por mitad. En caso de victoria del equipo local con 1 gol de diferencia, de empate o de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-2.25) ST - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 2 goles exactos de diferencia la apuesta es ganadora por mitad. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (-2.00) ST - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 2 goles exactos de diferencia la apuesta será devuelta. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-2.00) ST - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 2 goles exactos de diferencia la apuesta será devuelta. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (-1.75) ST - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 2 goles exactos de diferencia la apuesta es ganadora por mitad. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-1.75) ST - Descripción: en caso de victoria del equipo local con 3 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 2 goles exactos de diferencia la apuesta es perdedora por mitad. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (-1.50) ST - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-1.50) ST - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 1 gol de diferencia, de empate, o de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (-1.25) ST - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 1 gol exacto de diferencia la apuesta es perdedora por mitad. En caso de empate, o de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-1.25) ST - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 1 gol exacto de diferencia la apuesta es ganadora por mitad. En caso de empate, o de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (-1.00) ST - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 1 gol exacto de diferencia la apuesta será devuelta. En caso de empate, o de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-1.00) ST - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 1 gol exacto de diferencia la apuesta será devuelta. En caso de empate, o de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (-0.75) ST - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es ganadora. En caso de victoria del equipo local con 1 gol exacto de diferencia la apuesta es ganadora por mitad. En caso de empate, o de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-0.75) ST - Descripción: en caso de victoria del equipo local con 2 o más goles de diferencia la apuesta es perdedora. En caso de victoria del equipo local con 1 gol exacto de diferencia la apuesta es perdedora por mitad. En caso de empate, o de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (-0.50) ST - Descripción: en caso de victoria del equipo local la apuesta es ganadora. En caso de empate o de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-0.50) ST - Descripción: en caso de victoria del equipo local la apuesta es perdedora. En caso de empate o de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (-0.25) ST - Descripción: en caso de victoria del equipo local la apuesta es ganadora. En caso de empate la apuesta es perdedora por mitad. En caso de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (-0.25) ST - Descripción: en caso de victoria del equipo local la apuesta es perdedora. En caso de empate la apuesta es ganadora por mitad. En caso de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (0.00) ST - Descripción: en caso de victoria del equipo local con 1 o más goles de diferencia la apuesta es ganadora. En caso de empate la apuesta será devuelta. En caso de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (0.00) ST - Descripción: en caso de victoria del equipo local con 1 o más goles de diferencia la apuesta es perdedora. En caso de empate la apuesta será devuelta. En caso de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (0.25) ST - Descripción: en caso de victoria del equipo local la apuesta es ganadora. En caso de empate la apuesta es ganadora por mitad. En caso de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (0.25) ST - Descripción: en caso de victoria del equipo local la apuesta es perdedora. En caso de empate la apuesta es perdedora por mitad. En caso de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (0.50) ST - Descripción: en caso de victoria del equipo local o de empate la apuesta es ganadora. En caso de victoria del equipo visitante, la apuesta es perdedora  
Apuesta: 2AH (0.50) ST - Descripción: en caso de victoria del equipo local o de empate la apuesta es perdedora. En caso de victoria del equipo visitante, la apuesta es ganadora  
Apuesta: 1AH (0.75) ST - Descripción: en caso de victoria del equipo local o de empate la apuesta es ganadora. En caso de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es perdedora por mitad. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (0.75) ST - Descripción: en caso de victoria del equipo local o de empate la apuesta es perdedora. En caso de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es ganadora por mitad. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (1.00) ST - Descripción: en caso de victoria del equipo local o de empate la apuesta es ganadora. En caso de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta será devuelta. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (1.00) ST - Descripción: en caso de victoria del equipo local o de empate la apuesta es perdedora. En caso de victoria del equipo visitante con 1 gol exacto de diferencia la apuesta será devuelta. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (1,25) ST - Descripción: en caso de victoria del equipo local, o de empate, la apuesta es ganadora. En caso de victoria del equipo visitante con 1 gol exacto de diferencia la apuesta es ganadora por mitad. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (1,25) ST - Descripción: en caso de victoria del equipo local, o de empate, la apuesta es perdedora. En caso de victoria del equipo visitante con 1 gol exacto de diferencia la apuesta es perdedora por mitad. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (1,50) ST - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (1,50) ST - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 2 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (1.75) ST - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 2 goles exactos de diferencia la apuesta es perdedora por mitad. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (1.75) ST - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 2 goles exactos de diferencia la apuesta es ganadora por mitad. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (2.00) ST - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 2 goles exactos de diferencia, la apuesta será devuelta. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (2.00) ST - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 2 goles exactos de diferencia, la apuesta será devuelta. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (2.25) ST - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 2 goles exactos de diferencia, la apuesta es ganadora por mitad. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (2.25) ST - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 1 gol exacto de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 2 goles exactos de diferencia, la apuesta es perdedora por mitad. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es ganadora  
Apuesta: 1AH (2.50) ST - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 2 o menos goles de diferencia, la apuesta es ganadora. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es perdedora  
Apuesta: 2AH (2.50) ST - Descripción: en caso de victoria del equipo local, de empate, o de victoria del equipo visitante con 2 o menos goles de diferencia, la apuesta es perdedora. En caso de victoria del equipo visitante con 3 o más goles de diferencia, la apuesta es ganadora.   
Asiático - Goles Más/Menos de 
Hay que pronosticar si el número total de los goles marcados será mayor (Over) o menor (Under) considerando el hándicap asiático indicado en la apuesta.  
Apuesta: A Un (0.75) - Descripción: en caso de 0 gol la apuesta es ganadora. En caso de 1 gol la apuesta es perdedora por mitad. En caso de 2 o más goles la apuesta es perdedora  
Apuesta: A Ov (0.75) - Descripción: en caso de 0 gol la apuesta es perdedora. En caso de 1 gol la apuesta es ganadora por mitad. En caso de 2 o más goles la apuesta es ganadora  
Apuesta: A Un (1.00) - Descripción: en caso de 0 gol la apuesta es ganadora. En caso de 1 gol la apuesta será devuelta. En caso de 2 o más goles la apuesta es perdedora  
Apuesta: A Ov (1.00) - Descripción: en caso de 0 gol la apuesta es perdedora. En caso de 1 gol la apuesta será devuelta. En caso de 2 o más goles la apuesta es ganadora  
Apuesta: A Un (1.25) - Descripción: en caso de 0 gol la apuesta es ganadora. En caso de 1 gol la apuesta es ganadora por mitad. En caso de 2 o más goles la apuesta es perdedora  
Apuesta: A Ov (1.25) - Descripción: en caso de 0 gol la apuesta es perdedora. En caso de 1 gol la apuesta es perdedora por mitad. En caso de 2 o más goles la apuesta es ganadora  
Apuesta: A Un (1.75) - Descripción: en caso de 1 o menos goles la apuesta es ganadora. En caso de 2 goles la apuesta es perdedora por mitad. En caso de 3 o más goles la apuesta es perdedora  
Apuesta: A Ov (1.75) - Descripción: en caso de 1 o menos goles la apuesta es perdedora. En caso de 2 goles la apuesta es ganadora por mitad. En caso de 3 o más goles la apuesta es ganadora  
Apuesta: A Un (2.00) - Descripción: en caso de 1 o menos goles la apuesta es ganadora. En caso de 2 goles la apuesta será devuelta. En caso de 3 o más goles la apuesta es perdedora  
Apuesta: A Ov (2.00) - Descripción: en caso de 1 o menos goles la apuesta es perdedora. En caso de 2 goles la apuesta será devuelta. En caso de 3 o más goles la apuesta es ganadora  
Apuesta: A Un (2.25) - Descripción: en caso de 1 o menos goles la apuesta es ganadora. En caso de 2 goles la apuesta es ganadora por mitad. En caso de 3 o más goles la apuesta es perdedora  
Apuesta: A Ov (2.25) - Descripción: en caso de 1 o menos goles la apuesta es perdedora. En caso de 2 goles la apuesta es perdedora por mitad. En caso de 3 o más goles la apuesta es ganadora  
Apuesta: A Un (2.75) - Descripción: en caso de 2 o menos goles la apuesta es ganadora. En caso de 3 goles la apuesta es perdedora por mitad. En caso de 4 o más goles la apuesta es perdedora  
Apuesta: A Ov (2.75) - Descripción: en caso de 2 o menos goles la apuesta es perdedora. En caso de 3 goles la apuesta es ganadora por mitad. En caso de 4 o más goles la apuesta es ganadora  
Apuesta: A Un (3.00) - Descripción: en caso de 2 o menos goles la apuesta es ganadora. En caso de 3 goles la apuesta será devuelta. En caso de 4 o más goles la apuesta es perdedora  
Apuesta: A Ov (3.00) - Descripción: en caso de 2 o menos goles la apuesta es perdedora. En caso de 3 goles la apuesta será devuelta. En caso de 4 o más goles la apuesta es ganadora  
Apuesta: A Un (3.25) - Descripción: en caso de 2 o menos goles la apuesta es ganadora. En caso de 3 goles la apuesta es ganadora por mitad. En caso de 4 o más goles la apuesta es perdedora  
Apuesta: A Ov (3.25) - Descripción: en caso de 2 o menos la apuesta es perdedora. En caso de 3 goles la apuesta es perdedora por mitad. En caso de 4 o más goles la apuesta es ganadora  
Apuesta: A Un (3.75) - Descripción: en caso de 3 o menos goles la apuesta es ganadora. En caso de 4 goles la apuesta es perdedora por mitad. En caso de 5 o más goles la apuesta es perdedora  
Apuesta: A Ov (3.75) - Descripción: en caso de 3 o menos goles la apuesta es perdedora. En caso de 4 goles la apuesta es ganadora por mitad. En caso de 5 o más goles la apuesta es ganadora  
Apuesta: A Un (4.00) - Descripción: en caso de 3 o menos goles la apuesta es ganadora. En caso de 4 goles la apuesta será devuelta. En caso de 5 o más goles la apuesta es perdedora  
Apuesta: A Ov (4.00) - Descripción: en caso de 3 o menos goles la apuesta es perdedora. En caso de 4 goles la apuesta será devuelta. En caso de 5 o más goles la apuesta es ganadora  
Apuesta: A Un (4.25) - Descripción: en caso de 3 o menos goles la apuesta es ganadora. En caso de 4 goles la apuesta es ganadora por mitad. En caso de 5 o más goles la apuesta es perdedora  
Apuesta: A Ov (4.25) - Descripción: en caso de 3 o menos goles la apuesta es perdedora. En caso de 4 goles la apuesta es perdedora por mitad. En caso de 5 o más goles la apuesta es ganadora  
Apuesta: A Un (4.75) - Descripción: en caso de 4 o menos goles la apuesta es ganadora. En caso de 5 goles la apuesta es perdedora por mitad. En caso de 6 o más goles la apuesta es perdedora  
Apuesta: A Ov (4.75) - Descripción: en caso de 4 o menos goles la apuesta es perdedora. En caso de 5 goles la apuesta es ganadora por mitad. En caso de 6 o más goles la apuesta es ganadora  
Apuesta: A Un (5.00) - Descripción: en caso de 4 o menos goles la apuesta es ganadora. En caso de 5 goles la apuesta será devuelta. En caso de 6 o más goles la apuesta es perdedora  
Apuesta: A Ov (5.00) - Descripción: en caso de 4 o menos goles la apuesta es perdedora. En caso de 5 goles la apuesta será devuelta. En caso de 6 o más goles la apuesta es ganadora  
Apuesta: A Un (5.25) - Descripción: en caso de 4 o menos goles la apuesta es ganadora. En caso de 5 goles la apuesta es ganadora por mitad. En caso de 6 o más goles la apuesta es perdedora  
Apuesta: A Ov (5.25) - Descripción: en caso de 4 o menos goles la apuesta es perdedora. En caso de 5 goles la apuesta es perdedora por mitad. En caso de 6 o más goles la apuesta es ganadora  
Apuesta: A Un (5.75) - Descripción: en caso de 5 o menos goles la apuesta es ganadora. En caso de 6 goles la apuesta es perdedora por mitad. En caso de 7 o más goles la apuesta es perdedora  
Apuesta: A Ov (5.75) - Descripción: en caso de 5 o menos goles la apuesta es perdedora. En caso de 6 goles la apuesta es ganadora por mitad. En caso de 7 o más goles la apuesta es ganadora  
Apuesta: A Un (6.00) - Descripción: en caso de 5 o menos goles la apuesta es ganadora. En caso de 6 goles la apuesta será devuelta. En caso de 7 o más goles la apuesta es perdedora  
Apuesta: A Ov (6.00) - Descripción: en caso de 5 o menos goles la apuesta es perdedora. En caso de 6 goles la apuesta será devuelta. En caso de 7 o más goles la apuesta es ganadora  
Apuesta: A Un (6.25) - Descripción: en caso de 5 o menos goles la apuesta es ganadora. En caso de 6 goles la apuesta es ganadora por mitad. En caso de 7 o más goles la apuesta es perdedora.  
Apuesta: A Ov (6.25) - Descripción: en caso de 5 o menos goles la apuesta es perdedora. En caso de 6 goles la apuesta es perdedora por mitad. En caso de 7 o más goles la apuesta es ganadora   
Asiático - Goles Más/Menos de - Primer tiempo
Hay que pronosticar si el número total de los goles marcados será mayor (Over) o menor (Under) considerando el hándicap asiático indicado en la apuesta.  
Apuesta: A Un (0.75) HT - Descripción: en caso de 0 gol en el primer tiempo la apuesta es ganadora. En caso de 1 gol en el primer tiempo la apuesta es perdedora por mitad. En caso de 2 o más goles en el primer tiempo la apuesta es perdedora  
Apuesta: A Ov (0.75) HT - Descripción: en caso de 0 gol en el primer tiempo la apuesta es perdedora. En caso de 1 en el primer tiempo la apuesta es ganadora por mitad. En caso de 2 o más goles en el primer tiempo la apuesta es ganadora  
Apuesta: A Un (1.00) HT - Descripción: en caso de 0 gol en el primer tiempo la apuesta es ganadora. En caso de 1 gol en el primer tiempo la apuesta será devuelta. En caso de 2 o más goles en el primer tiempo, la apuesta es perdedora  
Apuesta: A Ov (1.00) HT - Descripción: en caso de 0 gol en el primer tiempo la apuesta es perdedora. En caso de 1 gol en el primer tiempo la apuesta será devuelta. En caso de 2 o más goles en el primer tiempo, la apuesta es ganadora  
Apuesta: A Un (1.25) HT - Descripción: en caso de 0 gol en el primer tiempo la apuesta es ganadora. En caso de 1 gol en el primer tiempo la apuesta es ganadora por mitad. En caso de 2 o más goles en el primer tiempo la apuesta es perdedora  
Apuesta: A Ov (1.25) HT - Descripción: en caso de 0 gol en el primer tiempo la apuesta es perdedora. En caso de 1 gol en el primer tiempo la apuesta es perdedora por mitad. En caso de 2 o más goles en el primer tiempo la apuesta es ganadora  
Apuesta: A Un (1.75) HT - Descripción: en caso de 1 o menos goles en el primer tiempo la apuesta es ganadora. En caso de 2 goles en el primer tiempo la apuesta es perdedora por mitad. En caso de 3 o más goles en el primer tiempo la apuesta es perdedora  
Apuesta: A Ov (1.75) HT - Descripción: en caso de 1 o menos goles en el primer tiempo la apuesta es perdedora. En caso de 2 goles en el primer tiempo la apuesta es ganadora por mitad. En caso de 3 o más goles en el primer tiempo la apuesta es ganadora  
Apuesta: A Un (2.00) HT - Descripción: en caso de 1 o menos goles en el primer tiempo la apuesta es gandora. En caso de 2 goles en el primer tiempo la apuesta será devuelta. En caso de 3 o más goles en el primer tiempo, la apuesta es perdedora  
Apuesta: A Ov (2.00) HT - Descripción: en caso de 1 o menos goles en el primer tiempo la apuesta es perdedora. En caso de 2 goles en el primer tiempo la apuesta será devuelta. En caso de 3 o más goles en el primer tiempo, la apuesta es ganadora  
Apuesta: A Un (2.25) HT - Descripción: en caso de 1 o menos goles en el primer tiempo la apuesta es ganadora. En caso de 2 goles en el primer tiempo la apuesta es ganadora por mitad. En caso de 3 o más goles en el primer tiempo la apuesta es perdedora  
Apuesta: A Ov (2.25) HT - Descripción: en caso de 1 o menos goles en el primer tiempo la apuesta es perdedora. En caso de 2 en el primer tiempo la apuesta es perdedora por mitad. En caso de 3 o más goles en el primer tiempo la apuesta es ganadora  
Apuesta: A Un (2.75) HT - Descripción: en caso de 2 o menos goles en el primer tiempo la apuesta es ganadora. En caso de 3 goles en el primer tiempo la apuesta es perdedora por mitad. En caso de 4 o más goles en el primer tiempo la apuesta es perdedora  
Apuesta: A Ov (2.75) HT - Descripción: en caso de 2 o menos goles en el primer tiempo la apuesta es perdedora. En caso de 3 goles en el primer tiempo la apuesta es ganadora por mitad. En caso de 4 o más goles en el primer tiempo la apuesta es ganadora  
Apuesta: A Un (3.00) HT - Descripción: en caso de 2 o menos goles en el primer tiempo la apuesta es ganadora. En caso de 3 goles en el primer tiempo la apuesta será devuelta. En caso de 4 o más goles en el primer tiempo, la apuesta es perdedora  
Apuesta: A Ov (3.00) HT - Descripción: en caso de 2 o menos goles en el primer tiempo la apuesta es perdedora. En caso de 3 goles en el primer tiempo la apuesta será devuelta. En caso de 4 o más goles en el primer tiempo, la apuesta es ganadora  
Apuesta: A Un (3.25) HT - Descripción: en caso de 2 o menos goles en el primer tiempo la apuesta es ganadora. En caso de 3 goles en el primer tiempo la apuesta es ganadora por mitad. En caso de 4 o más goles en el primer tiempo la apuesta es perdedora.  
Apuesta: A Ov (3.25) HT - Descripción: en caso de 2 o menos goles en el primer tiempo la apuesta es perdedora. En caso de 3 goles en el primer tiempo la apuesta es perdedora por mitad. En caso de 4 o más goles en el primer tiempo la apuesta es ganadora  
Apuesta: A Un (0.25) HT - Descripción: en caso de 0 gol en el primer tiempo la apuesta es ganadora por mitad. En caso de 1 o más goles en el primer tiempo la apuesta es perdedora  
Apuesta: A Ov (0.25) HT - Descripción: en caso de 0 gol en el primer tiempo la apuesta es perdedora por mitad. En caso de 1 o más goles en el primer tiempo la apuesta es ganadora  
Apuesta: A Un (0.50) HT - Descripción: en caso de 0 gol la apuesta es ganadora. En caso de 1 o más goles la apuesta es perdedora  
Apuesta: A Ov (0.50) HT - Descripción: en caso de 0 gol la apuesta es perdedora. En caso de 1 o más goles la apuesta es ganadora   
Asiático - Goles Más/Menos de - Segundo tiempo 
Se debe predecir si el número de los goles marcados en el segundo tiempo será mayor (Over) o menor (Under) considerando el hándicap asiático indicado en la apuesta.  
Apuesta: A Un (0.75) ST - Descripción: en caso de 0 gol en el segundo tiempo la apuesta es ganadora. En caso de 1 gol en el segundo tiempo la apuesta es perdedora por mitad. En caso de 2 o más goles en el segundo tiempo la apuesta es perdedora  
Apuesta: A Ov (0.75) ST - Descripción: en caso de 0 gol en el segundo tiempo la apuesta es perdedora. En caso de 1 gol en el segundo tiempo la apuesta es ganadora por mitad. En caso de 2 o más goles en el segundo tiempo la apuesta es ganadora  
Apuesta: A Un (1.00) ST - Descripción: en caso de 0 gol en el segundo tiempo la apuesta es ganadora. En caso de 1 gol en el segundo tiempo la apuesta será devuelta. En caso de 2 o más goles en el segundo tiempo, la apuesta es perdedora  
Apuesta: A Ov (1.00) ST - Descripción: en caso de 0 gol en el segundo tiempo la apuesta es perdedora. En caso de 1 gol en el segundo tiempo la apuesta será devuelta. En caso de 2 o más goles en el segundo tiempo, la apuesta es ganadora  
Apuesta: A Un (1.25) ST - Descripción: en caso de 0 gol en el segundo tiempo la apuesta es ganadora. En caso de 1 gol en el segundo tiempo la apuesta es ganadora por mitad. En caso de 2 o más goles en el segundo tiempo la apuesta es perdedora  
Apuesta: A Ov (1.25) ST - Descripción: en caso de 0 gol en el segundo tiempo la apuesta es perdedora. En caso de 1 gol en el segundo tiempo la apuesta es perdedora por mitad. En caso de 2 o más goles en el segundo tiempo la apuesta es ganadora  
Apuesta: A Un (1.75) ST - Descripción: en caso de 1 o menos gol en el segundo tiempo la apuesta es ganadora. En caso de 2 goles en el segundo tiempo la apuesta es perdedora por mitad. En caso de 3 o más goles en el segundo tiempo la apuesta es perdedora  
Apuesta: A Ov (1.75) ST - Descripción: en caso de 1 o menos gol en el segundo tiempo la apuesta es perdedora. En caso de 2 goles en el segundo tiempo la apuesta es ganadora por mitad. En caso de 3 o más goles en el segundo tiempo la apuesta es ganadora  
Apuesta: A Un (2.00) ST - Descripción: en caso de 1 o menos gol en el segundo tiempo la apuesta es ganadora. En caso de 2 goles en el segundo tiempo la apuesta será devuelta. En caso de 3 o más goles en el segundo tiempo, la apuesta es perdedora  
Apuesta: A Ov (2.00) ST - Descripción: en caso de 1 o menos gol en el segundo tiempo la apuesta es perdedora. En caso de 2 goles en el segundo tiempo la apuesta será devuelta. En caso de 3 o más goles en el segundo tiempo, la apuesta es ganadora  
Apuesta: A Un (2.25) ST - Descripción: en caso de 1 o menos gol en el segundo tiempo la apuesta es ganadora. En caso de 2 goles en el segundo tiempo la apuesta es ganadora por mitad. En caso de 3 o más goles en el segundo tiempo la apuesta es perdedora  
Apuesta: A Ov (2.25) ST - Descripción: en caso de 1 o menos gol en el segundo tiempo la apuesta es perdedora. En caso de 2 goles en el segundo tiempo la apuesta es perdedora por mitad. En caso de 3 o más goles en el segundo tiempo la apuesta es ganadora  
Apuesta: A Un (2.75) ST - Descripción: en caso de 2 o menos gol en el segundo tiempo la apuesta es ganadora. En caso de 3 goles en el segundo tiempo la apuesta es perdedora por mitad. En caso de 4 o más goles en el segundo tiempo la apuesta es perdedora  
Apuesta: A Ov (2.75) ST - Descripción: en caso de 2 o menos goles en el segundo tiempo la apuesta es perdedora. En caso de 3 goles en el segundo tiempo la apuesta es ganadora por mitad. En caso de 4 o más goles en el segundo tiempo la apuesta es ganadora  
Apuesta: A Un (3.00) ST - Descripción: en caso de 2 o menos goles en el segundo tiempo la apuesta es ganadora. En caso de 3 goles en el segundo tiempo la apuesta será devuelta. En caso de 4 o más goles en el segundo tiempo, la apuesta es perdedora  
Apuesta: A Ov (3.00) ST - Descripción: en caso de 2 o menos goles en el segundo tiempo la apuesta es perdedora. En caso de 3 goles en el segundo tiempo la apuesta será devuelta. En caso de 4 o más goles en el segundo tiempo, la apuesta es ganadora  
Apuesta: A Un (3.25) ST - Descripción: en caso de 2 o menos goles en el segundo tiempo la apuesta es ganadora. En caso de 3 goles en el segundo tiempo la apuesta es ganadora por mitad. En caso de 4 o más goles en el segundo tiempo, la apuesta es perdedora.  
Apuesta: A Ov (3.25) ST - Descripción: en caso de 2 o menos goles en el segundo tiempo la apuesta es perdedora. En caso de 3 goles en el segundo tiempo la apuesta es perdedora por mitad. En caso de 4 o más goles en el segundo tiempo la apuesta es ganadora  
Apuesta: A Un (0.25) ST - Descripción: en caso de 0 gol en el segundo tiempo la apuesta es ganadora por mitad. En caso de 1 o más goles en el segundo tiempo la apuesta es perdedora  
Apuesta: A Ov (0.25) ST - Descripción: en caso de 0 gol en el segundo tiempo la apuesta es perdedora por mitad. En caso de 1 o más goles en el segundo tiempo la apuesta es ganadora  
Apuesta: A Un (0.50) ST - Descripción: en caso de 0 gol la apuesta es ganadora. En caso de 1 o más goles la apuesta es perdedora  
Apuesta: A Ov (0.50) ST - Descripción: en caso de 0 gol la apuesta es perdedora. En caso de 1 o más goles la apuesta es ganadora   
Equipo Local NO cuenta
Se debe predecir qué pronóstico entre los siguientes será ganador: empate o victoria del equipo visitante. En caso de victoria del equipo local, la apuesta será considerada nula y no será incluida en el cálculo de un eventual bono.  
Apuesta: HomeNoBet X - Descripción: Se apuesta por el empate. En caso de victoria del equipo local, la apuesta será devuelta.  
Apuesta: HomeNoBet 2 - Descripción: Se apuesta por la victoria del equipo visitante. En caso de victoria del equipo local la apuesta será devuelta.   
Equipo Visitante NO cuenta
Se debe predecir qué pronóstico entre los siguientes será ganador: victoria del equipo local o empate. En caso de victoria del equipo visitante, la apuesta será considerada nula y no será incluida en el cálculo de un eventual bono.  
Apuesta: AwayNoBet 1 - Descripción: Se apuesta por la victoria del equipo local. En caso de victoria del equipo visitante, la apuesta será devuelta.  
Apuesta: AwayNoBet X - Descripción: Se apuesta por el empate. En caso de victoria del equipo visitante, la apuesta será devuelta.   
¿Qué equipo gana? - Empate NO cuenta
Se debe predecir qué equipo ganará el partido. Si el partido terminara en empate la apuesta será considerada nula y no será incluida en el cálculo de un eventual bono.  
Apuesta: DrawNoBet 1 - Descripción: Se apuesta por la victoria del equipo local. En caso de empate, la apuesta será devuelta.  
Apuesta: DrawNoBet 2 - Descripción: Se apuesta por la victoria del equipo visitante. En caso de empate, la apuesta será devuelta.   
¿Qué equipo gana el primer tiempo? – Empate NO cuenta
Se debe predecir qué equipo ganará el primer tiempo. Si el primer tiempo terminará en empate, la apuesta será devuelta.  
Apuesta: DrawNoBet 1HT - Descripción: Se apuesta por la victoria del equipo local al final del primer tiempo. En caso de empate al final del primer tiempo la apuesta será devuelta.  
Apuesta: DrawNoBet 2HT - Descripción: Se apuesta por la victoria del equipo visitante al final del primer tiempo. En caso de empate al final del primer tiempo la apuesta será devuelta.   
¿Qué equipo gana el segundo tiempo? – Empate no cuenta
Se debe predecir qué equipo ganará el segundo tiempo. En caso de empate al final del segundo tiempo, la apuesta será devuelta.  
Apuesta: DrawNoBet 1ST - Descripción: Se apuesta por la victoria del equipo local al final del segundo tiempo. En caso de empate al final del segundo tiempo la apuesta será devuelta.  
Apuesta: DrawNoBet 2ST - Descripción: Se apuesta por la victoria del equipo visitante al final del segundo tiempo. En caso de empate al final del segundo tiempo la apuesta será devuelta.   
Doble Oportunidad Combo - Primer tiempo
Hay que predecir el resultado del primer tiempo de un partido, apostando por dos tipos de apuestas conectados, una de las que es siempre una doble posibilidad.  
Apuesta: 1X AND GG HT - Descripción: Equipo local ganará o empatará el primer tiempo, y ambos equipos marcarán goles en el primer tiempo.  
Apuesta: 1X AND NG HT - Descripción: Equipo local ganará o empatará el primer tiempo y al menos uno de los dos equipos no marcará goles en el primer tiempo.  
Apuesta: 12 AND GG HT - Descripción: Equipo local o el equipo visitante ganará el primer tiempo y, ambos equipos marcarán goles en el primer tiempo.  
Apuesta: 12 AND NG HT - Descripción: Equipo local o el equipo visitante ganará el primer tiempo y, al menos uno de los dos equipos no marcará goles en el primer tiempo.  
Apuesta: X2 AND GG HT - Descripción: Equipo visitante ganará o empatará el primer tiempo, y ambos equipos meterán goles en el primer tiempo.  
Apuesta: X2 AND NG HT - Descripción: Equipo visitante ganará o empatará el primer tiempo, y al menos uno de los dos equipos no marcará goles en el primer tiempo.   
Doble Oportunidad Combo - Segundo tiempo
Hay que predecir el resultado del segundo tiempo de un partido, apostando por dos tipos de apuestas conectados, una de las que es siempre una doble posibilidad. Para todos los siguientes tipos de apuestas, solo los goles efectivamente marcados en el segundo tiempo serán considerados válidos.  
Apuesta: 1X AND GG ST - Descripción: Equipo local ganará o empatará en el segundo tiempo, y ambos equipos marcarán goles en el segundo tiempo.  
Apuesta: 1X AND NG ST - Descripción: Equipo local ganará o empatará en el segundo tiempo y al menos uno de los dos equipos no marcará goles en el segundo tiempo.  
Apuesta: 12 AND GG ST - Descripción: Equipo local o el equipo visitante ganará el segundo tiempo y, ambos equipos marcarán goles en el segundo tiempo.  
Apuesta: 12 AND NG ST - Descripción: Equipo local o el equipo visitante ganará el segundo tiempo y, al menos uno de los dos equipos no marcará goles en el segundo tiempo.  
Apuesta: X2 AND GG ST - Descripción: Equipo visitante ganará o empatará el segundo tiempo, y ambos equipos marcarán goles en el segundo tiempo.  
Apuesta: X2 AND NG ST - Descripción: Equipo visitante ganará o empatará el segundo tiempo, y al menos uno de los dos equipos no marcará goles en el segundo tiempo.   
El equipo local marca más goles en un tiempo
¿Será el equipo local el que marcará más goles que el equipo visitante en al menos un tiempo de juego?  
Apuesta: Si - Descripción: El equipo local marca más goles con respecto al equipo visitante por lo menos en un tiempo de juego.  
Apuesta: No - Descripción: El equipo local no marca más goles que el equipo visitante en ambos tiempos. (Si el resultado es 0-0 u otro empate en ambos tiempos, la apuesta es ganadora). 
El equipo visitante marca más goles en un tiempo
¿Será el equipo visitante el que marque más goles que el local en al menos un tiempo de juego?  
Apuesta: Si - Descripción: El equipo local marcará más goles con respecto al equipo visitante en ambos los tiempos.  
Apuesta: No - Descripción: El equipo visitante non marca más goles que el equipo local en ambos tiempos. (Si el resultado es 0-0 u otro empate en ambos tiempos, la apuesta es ganadora).   
El primer equipo que marcará en el primer tiempo
¿Qué equipo marcará el primer gol del primer tiempo?  
Apuesta: 1 - Descripción: El equipo local marcará el primer gol del primer tiempo.  
Apuesta: 2 - Descripción: El equipo visitante marcará el primer gol del primer tiempo.  
Apuesta: No - Descripción: Ningún equipo marcará en el primer tiempo (0-0 HT).   
El primer equipo que marcará en el segundo tiempo
¿Qué equipo marcará el primer gol del segundo tiempo?  
Apuesta: 1 - Descripción: El equipo local marcará el primer gol del segundo tiempo.  
Apuesta: 2 - Descripción: El equipo visitante marcará el primer gol del segundo tiempo.  
Apuesta: No - Descripción: Ningún equipo marcará en el primer tiempo (0-0 ST).   
Goles totales - Primer tiempo
Número total de goles en el primer tiempo.  
Apuesta: Total Goals HT 0 - Descripción: Ningún gol será marcado en el primer tiempo.  
Apuesta: Total Goals HT 1 - Descripción: Goles totales marcados en el primer tiempo 1.  
Apuesta: Total Goals HT 2+ - Descripción: Goles totales marcados en el primer tiempo: 2 o más.   
Goles totales - Segundo tiempo
Número total de goles en el segundo tiempo.  
Apuesta: Total Goals ST 0 - Descripción: Ningún gol será marcado en el segundo tiempo.  
Apuesta: Total Goals ST 1 - Descripción: Goles totales marcados en el segundo tiempo 1.  
Apuesta: Total Goals ST 2+ - Descripción: Goles totales marcados en el segundo tiempo: 2 o más.   
Equipo local marca más goles en el primer y segundo tiempo
¿Será el equipo local el que marcará más goles tanto en el primero como en el segundo tiempo?  
Apuesta: Si - Descripción: El equipo local marcará más goles con respecto al equipo visitante en ambos los tiempos.  
Apuesta: No - Descripción: El equipo visitante no marca más goles que el equipo local en ambos tiempos. (Si el resultado es 0-0 u otro empate en ambos tiempos, la apuesta es ganadora).   
Equipo visitante marca más goles en el primer y segundo tiempo
¿Será el equipo visitante el que marcará más goles que el local tanto en el primero como en el segundo tiempo?  
Apuesta: Si - Descripción: El equipo visitante marcará más goles con respecto al equipo local en ambos tiempos.  
Apuesta: No - Descripción: El equipo visitante no marcará más goles con respecto al equipo local en ambos tiempos. (Si el resultado es 0-0 u otro empate en ambos tiempos la apuesta es ganadora).  
¿En qué tiempo marcará más goles el equipo local?
¿En cuál de los tiempos habrá mayor número de goles del equipo local?  
Apuesta: 1º T - Descripción: El equipo local marcará más goles en el primer tiempo.  
Apuesta: 2º T - Descripción: El equipo local marcará más goles en el segundo tiempo.  
Apuesta: Igual - Descripción: El equipo local marcará el mismo número de goles tanto en el primer tiempo como en el segundo tiempo.   
¿En qué tiempo marcará más goles el equipo visitante?
¿En cuál de los tiempos habrá mayor número de goles del equipo visitante?  
Apuesta: 1º T - Descripción: El equipo local marcará más goles en el primer tiempo.  
Apuesta: 2º T - Descripción: El equipo local marcará más goles en el segundo tiempo.  
Apuesta: Igual - Descripción: El equipo local marcará el mismo número de goles tanto en el primer tiempo como en el segundo tiempo.   
Márgenes
Se apuesta al margen por el cual uno de los equipos vencerá sobre el otro, considerando los tiempos reglamentarios, incluidos eventuales minutos de alargue.  
Apuesta: HT {'>'} 2 - Descripción: El equipo local gana con 3 o más goles de diferencia.  
Apuesta: HT 2 - Descripción: El equipo local gana con 2 goles de diferencia.  
Apuesta: HT 1 - Descripción: El equipo local gana con 1 gol de diferencia.  
Apuesta: AT 1 - Descripción: El equipo visitante gana con 1 gol de diferencia.  
Apuesta: AT 2 - Descripción: El equipo visitante gana con 2 goles de diferencia.  
Apuesta: AT {'>'} 2 - Descripción: El equipo visitante gana con 3 o más goles de diferencia.   
Equipo Local – Goles – Más/Menos de 0.5 – Primer tiempo 
Número total de goles del equipo local en el primer tiempo.  
Apuesta: Totals Home UN HT 0.5 - Descripción: Local Menos 0.5 HT  
Apuesta: Totals Home OV HT 0.5 - Descripción: Local Más 0.5 HT   
Equipo Local – Goles – Más/Menos de 0.5 – Segundo tiempo 
Número total de goles del equipo local en el segundo tiempo.  
Apuesta: Totals Home UN ST 0.5 - Descripción: Local Menos 0.5 ST  
Apuesta: Totals Home OV ST 0.5 - Descripción: Local Más 0.5 ST   
Equipo Local - Goles – Más/Menos de 1.5 – Primer Tiempo 
Número total de goles del equipo local en el primer tiempo.  
Apuesta: Totals Home UN HT 1.5 - Descripción: Local Menos 1.5 HT  
Apuesta: Totals Home OV HT 1.5 - Descripción: Local Más 1.5 HT   
Equipo Local - Goles – Más/Menos de 1.5 – Segundo Tiempo 
Número total de goles del equipo local en el segundo tiempo.  
Apuesta: Totals Home UN ST 1.5 - Descripción: Local Menos 1.5 ST  
Apuesta: Totals Home OV ST 1.5 - Descripción: Local Más 1.5 ST   
Equipo Visitante – Goles – Más/Menos de 0.5 – Primer tiempo 
Número total de goles del equipo visitante en el primer tiempo.  
Apuesta: Totals Away UN HT 0.5 - Descripción: Equipo Visitante Menos 0.5 HT  
Apuesta: Totals Away OV HT 0.5 - Descripción: Equipo Visitante Más 0.5 HT   
Equipo Visitante – Goles – Más/Menos de 0.5 – Segundo tiempo 
Número total de goles del equipo visitante en el segundo tiempo.  
Apuesta: Totals Away UN ST 0.5 - Descripción: Equipo Visitante Menos 0.5 ST  
Apuesta: Totals Away OV ST 0.5 - Descripción: Equipo Visitante Más 0.5 ST   
Equipo Visitante - Goles – Más/Menos de 1.5 – Primer Tiempo 
Número total de goles del equipo visitante en el primer tiempo.  
Apuesta: Totals Away UN HT 1.5 - Descripción: Equipo Visitante Menos 1.5 HT  
Apuesta: Totals Away OV HT 1.5 - Descripción: Equipo Visitante Más 1.5 HT   
Equipo Visitante - Goles – Más/Menos de 1.5 – Segundo Tiempo 
Número total de goles del equipo visitante en el segundo tiempo.  
Apuesta: Totals Away UN ST 1.5 - Descripción: Equipo Visitante Menos 1.5 ST  
Apuesta: Totals Away OV ST 1.5 - Descripción: Equipo Visitante Más 1.5 ST   
¿En qué intervalo de 10 minutos se marca el primer gol? 
¿En qué intervalo de 10 minutos se marcará el primer gol?  
Apuesta: 1-10 - Descripción: El primer gol se marcará en este intervalo de tiempo  
Apuesta: 11-20 - Descripción: El primer gol se marcará en este intervalo de tiempo  
Apuesta: 21-30 - Descripción: El primer gol se marcará en este intervalo de tiempo.  
Apuesta: 31-40 - Descripción: El primer gol se marcará en este intervalo de tiempo  
Apuesta: 41-50 - Descripción: El primer gol se marcará en este intervalo de tiempo  
Apuesta: 51-60 - Descripción: El primer gol se marcará en este intervalo de tiempo  
Apuesta: 61-70 - Descripción: El primer gol se marcará en este intervalo de tiempo  
Apuesta: 71-80 - Descripción: El primer gol se marcará en este intervalo de tiempo  
Apuesta: 81-90 - Descripción: El primer gol se marcará en este intervalo de tiempo más eventuales tiempo adicional.  
Apuesta: Ninguno  
¿En qué intervalo de 15 minutos se marca el primer gol?
¿En qué intervalo de 15 minutos se marcará el primer gol?  
Apuesta: 1-15 - Descripción: El primer gol se marcará en este intervalo de tiempo  
Apuesta: 16-30 - Descripción: El primer gol se marcará en este intervalo de tiempo  
Apuesta: 31-45 - Descripción: El primer gol se marcará en este intervalo de tiempo  
Apuesta: 46-60 - Descripción: El primer gol se marcará en este intervalo de tiempo  
Apuesta: 61-75 - Descripción: El primer gol se marcará en este intervalo de tiempo  
Apuesta: 76-90 - Descripción: El primer gol se marcará en este intervalo de tiempo más eventuales tiempo adicional. 
Apuesta: Ninguno  
Quien gana en el primer intervalo de 10min 
1X2 en los primeros 10 minutos.  
Apuesta: 1 - Descripción: El equipo local gana en los primeros 10 minutos.  
Apuesta: X - Descripción: Se apuesta por el empate en los primeros 10 minutos.  
Apuesta: 2 - Descripción: El equipo visitante gana en los primeros 10 minutos.   
Último equipo en marcar
Se debe pronosticar qué equipo marcará el último gol. Nota: Los goles en contra serán asignados al equipo contrario.  
Apuesta: 1 - Descripción: El equipo local será el último en marcar.  
Apuesta: 2 - Descripción: El equipo visitante será el último en marcar.  
Apuesta: No - Descripción: Ningún gol.   
Hándicap 
El resultado de una apuesta con hándicap (spread) se obtiene agregando (o sustrayendo) el hándicap al resultado final del partido (90'+ eventual tiempo de recuperación).  
Apuesta: 1 H 1:0 - Descripción: Equipo local ganará o empatará.  
Apuesta: X H 1:0 - Descripción: Equipo visitante ganará con 1 gol de diferencia.  
Apuesta: 2 H 1:0 - Descripción: Equipo visitante ganará por lo menos con 2 goles de diferencia.  
Apuesta: 1 H 0:1 - Descripción: Equipo local ganará por lo menos con 2 goles de diferencia.  
Apuesta: X H 0:1 - Descripción: Equipo local ganará con 1 gol de diferencia.  
Apuesta: 2 H 0:1 - Descripción: Equipo visitante ganará o empatará.  
Apuesta: 1 H 2:0 - Descripción: Equipo local ganará, empatará o perderá con 1 gol de diferencia.  
Apuesta: X H 2:0 - Descripción: Equipo visitante ganará con 2 goles de diferencia.  
Apuesta: 2 H 2:0 - Descripción: Equipo visitante ganará con al menos 3 goles de diferencia.  
Apuesta: 1 H 0:2 - Descripción: Equipo local ganará con al menos 3 goles de diferencia.  
Apuesta: X H 0:2 - Descripción: Equipo local ganará con 2 goles de diferencia.  
Apuesta: 2 H 0:2 - Descripción: Equipo visitante ganará, empatará, o perderá con 1 gol de diferencia.  
Apuesta: 1 H 3:0 - Descripción: Equipo local ganará, empatará, o perderá con 1 o 2 goles de diferencia.  
Apuesta: X H 3:0 - Descripción: Equipo visitante ganará con 3 goles de diferencia.  
Apuesta: 2 H 3:0 - Descripción: Equipo visitante ganará con una diferencia de al menos 4 goles.  
Apuesta: 1 H 0:3 - Descripción: Equipo local ganará con al menos 4 goles de diferencia.  
Apuesta: X H 0:3 - Descripción: Equipo local ganará con 3 goles de diferencia.  
Apuesta: 2 H 0:3 - Descripción: Equipo visitante ganará, empatará o perderá con 1 o 2 goles de diferencia.   
Desarrollo de partido
Se debe pronosticar qué equipo marcará primero y quién ganará el partido.  
Apuesta: Home team scores first and home - Descripción: El equipo local marca primero y gana el partido.  
Apuesta: Home team scores first and draw - Descripción: El equipo local marca primero y el encuentro termina en empate.  
Apuesta: Home team scores first and away - Descripción: El equipo local marca primero y el equipo visitante gana.  
Apuesta: Away team scores first and home - Descripción: El equipo visitante marca primero y el equipo local gana.  
Apuesta: Away team scores first and draw - Descripción: El equipo visitante marca primero y el encuentro termina en empate.  
Apuesta: Away team scores first and away - Descripción: El equipo visitante marca primero y gana el partido.  
Apuesta: No - Descripción: No habrá ningún gol en el encuentro (0-0).   
Cantidad de goles anotados en el partido
Se debe pronosticar el número total de goles marcados en el encuentro eligiendo entre las opciones propuestas:  
Apuesta: 0 - Descripción: Ningún gol será marcado.  
Apuesta: 1 - Descripción: Total de goles en el partido será 1  
Apuesta: 2 - Descripción: Total de goles en el partido será 2.  
Apuesta: 3 - Descripción: Total de goles en el partido será 3.  
Apuesta: 4 - Descripción: Total de goles en el partido será 4.  
Apuesta: 5+ - Descripción: Total de goles en el partido será 5 o más.   
Goles marcados
Se debe pronosticar el número total de goles del partido eligiendo entre las opciones propuestas.  
Apuesta: 0-1 goals - Descripción: Se apuesta a que en el encuentro se marcará 1 o ningún gol.  
Apuesta: 2-3 goals - Descripción: Se apuesta a que en el encuentro se marcarán 2 o 3 goles.  
Apuesta: 4-5 goals - Descripción: Se apuesta a que en el encuentro se marcarán 4 o 5 goles.  
Apuesta: 6+ - Descripción: Se apuesta a que en el encuentro se marcarán por lo menos 6 o más goles.   
Cantidad exacta de goles del equipo Local
Se debe pronosticar el número exacto de goles marcados por el equipo local.  
Apuesta: 0 - Descripción: Ningún gol marcado.  
Apuesta: 1 - Descripción: El equipo local marcará un gol.  
Apuesta: 2 - Descripción: El equipo local marcará 2 goles.  
Apuesta: 3+ - Descripción: El equipo local marcará 3 o más goles.   
Cantidad exacta de goles del equipo Visitante
Se debe pronosticar el número exacto de goles marcados por el equipo visitante.  
Apuesta: 0 - Descripción: Ningún gol marcado.  
Apuesta: 1 - Descripción: El equipo visitante marcará 1 gol.  
Apuesta: 2 - Descripción: El equipo local marcará 2 goles.  
Apuesta: 3+ - Descripción: El equipo local marcará 3 o más goles.   
Multiresultados
Se debe pronosticar el resultado final del encuentro entre las opciones ofrecidas.  
Apuesta: 1:0, 2:0 or 3:0 - Descripción: El equipo local gana con los siguientes resultados.  
Apuesta: 0:1, 0:2 or 0:3 - Descripción: El equipo visitante gana con los siguientes resultados.  
Apuesta: 4:0, 5:0 or 6:0 - Descripción: El equipo local gana con los siguientes resultados.  
Apuesta: 0:4, 0:5 or 0:6 - Descripción: El equipo visitante gana con los siguientes resultados.  
Apuesta: 2:1, 3:1 or 4:1 - Descripción: El equipo local gana con los siguientes resultados.  
Apuesta: 1:2, 1:3 or 1:4 - Descripción: El equipo visitante gana con los siguientes resultados.  
Apuesta: 3:2, 4:2, 4:3 or 5:1 - Descripción: El equipo local gana con los siguientes resultados.  
Apuesta: 2:3, 2:4, 3:4 or 1:5 - Descripción: El equipo visitante gana con los siguientes resultados.  
Apuesta: Other homewin - Descripción: Se apuesta a que el equipo local gana con un resultado distinto a los incluidos en la lista.  
Apuesta: Other awaywin - Descripción: Se apuesta a que el equipo visitante gana con un resultado distinto a los incluidos en la lista  
Apuesta: X - Descripción: Se apuesta al empate del encuentro.   
Apuestas combinadas - Primer tiempo (Matchbet + Totales)
Se debe pronosticar el resultado de un encuentro al finalizar el primer tiempo apostando a dos tipos de apuestas conectadas.  
Apuesta: 1 and under 1.5 HT - Descripción: El equipo local ganará 1-0 al finalizar el primer tiempo.  
Apuesta: X and under 1.5 HT - Descripción: Los equipos empatarán 0-0 al finalizar el primer tiempo.  
Apuesta: 2 and under 1.5 HT - Descripción: El equipo visitante ganará 0-1 al finalizar el primer tiempo.  
Apuesta: 1 and over 1.5 HT - Descripción: El equipo local ganará el primer tiempo y la suma de los goles será de 2 o más.  
Apuesta: X and over 1.5 HT - Descripción: Los equipos empatarán al finalizar el primer tiempo y la suma de los goles será de 2 o más.  
Apuesta: 2 and over 1.5 HT - Descripción: El equipo visitante ganará el primer tiempo y la suma de los goles será de 2 o más.   
Apuestas combinadas - Primer tiempo (Matchbet + Ambos equipos marcarán)
Se debe pronosticar el resultado de un encuentro al finalizar el primer tiempo, apostando a dos tipos de apuestas conectadas.  
Apuesta: 1 and GG HT - Descripción: El equipo local ganará el primer tiempo y ambos equipos marcarán.  
Apuesta: 1 and NG HT - Descripción: El equipo local ganará el primer tiempo y el equipo visitante no marcará.  
Apuesta: X and GG HT - Descripción: Los equipos empatarán al finalizar el primer tiempo, y ambos equipos marcarán.  
Apuesta: X and NG HT - Descripción: Los equipos empatarán al finalizar el primer tiempo y no habrá goles.  
Apuesta: 2 and GG HT - Descripción: El equipo visitante ganará el primer tiempo y ambos equipos marcarán.  
Apuesta: 2 and NG HT - Descripción: El equipo visitante ganará el primer tiempo y el equipo local no marcará.   
Apuestas combinadas - Segundo tiempo (Matchbet + Totales)
Se debe pronosticar el resultado de un encuentro al finalizar el segundo tiempo apostando a dos tipos de apuestas conectadas. Para la definición del resultado cuentan sólo exclusivamente los goles marcados en el segundo tiempo; los goles marcados en el primer tiempo no serán considerados.  
Apuesta: 1 and under 1.5 ST - Descripción: El equipo local ganará 1-0 al terminar el segundo tiempo.  
Apuesta: X and under 1.5 ST - Descripción: Los equipos empatarán 0-0 al terminar el segundo tiempo.  
Apuesta: 2 and under 1.5 ST - Descripción: El equipo visitante ganará 0-1 al finalizar el segundo tiempo.  
Apuesta: 1 and over 1.5 ST - Descripción: El equipo local ganará el segundo tiempo y la suma de golas será de 2 o más.  
Apuesta: X and over 1.5 ST - Descripción: Los equipos empatarán al finalizar el segundo tiempo y la suma de los goles será de 2 o más.  
Apuesta: 2 and over 1.5 ST - Descripción: El equipo visitante ganará el el segundo tiempo y la suma de los goles será de 2 o más.   
Apuestas combinadas - Segundo tiempo (Matchbet + Ambos equipos marcarán)
Se debe pronosticar el resultado de un encuentro al finalizar el primer tiempo, apostando a dos tipos de apuestas conectadas. Para la definición del resultado cuentan sólo exclusivamente los goles marcados en el segundo tiempo; los goles marcados en el primer tiempo no serán considerados.  
Apuesta: 1 and GG ST - Descripción: El equipo local ganará el segundo tiempo y ambos equipos marcarán.  
Apuesta: 1 and NG ST - Descripción: El equipo local ganará el segundo tiempo y el equipo visitante no marcará.  
Apuesta: X and GG ST - Descripción: Los equipos empatarán al finalizar el segundo tiempo y ambos equipos marcarán.  
Apuesta: X and NG ST - Descripción: Los equipos empatarán al finalizar el segundo tiempo y no habrá goles.  
Apuesta: 2 and GG ST - Descripción: El equipo visitante ganará el segundo tiempo y ambos equipos marcarán.  
Apuesta: 2 and NG ST - Descripción: El equipo visitante ganará el segundo tiempo y el equipo local no marcará.   
Primer tiempo - Hándicap Europeo
El resultado de una apuesta con hándicap (spread) se obtiene adicionando (o sustrayendo) el hándicap al resultado final del primer tiempo.  
Apuesta: 1 H 1:0 - Descripción: Equipo local ganará o empatará al finalizar el primer tiempo.  
Apuesta: X H 1:0 - Descripción: El equipo visitante ganará con 1 gol de ventaja al finalizar el primer tiempo.  
Apuesta: 2 H 1:0 - Descripción: El equipo visitante ganará con una ventaja de al menos 2 goles al finalizar el primer tiempo.  
Apuesta: 1 H 0:1 - Descripción: El equipo local ganará con una ventaja de al menos 2 goles al finalizar el primer tiempo.  
Apuesta: X H 0:1 - Descripción: El equipo local ganará con un gol de ventaja al finalizar el primer tiempo.  
Apuesta: 2 H 0:1 - Descripción: El equipo visitante ganará o empatará al finalizar el primer tiempo.   
Segundo tiempo - Hándicap Europeo
El resultado de una apuesta hándicap (spread) se obtiene adicionando (o sustrayendo) el hándicap al resultado final del segundo tiempo.  
Apuesta: 1 H 1:0 - Descripción: El equipo local ganará o empatará al finalizar el segundo tiempo.  
Apuesta: X H 1:0 - Descripción: El equipo visitante ganará con un gol de ventaja al finalizar el segundo tiempo.  
Apuesta: 2 H 1:0 - Descripción: El equipo visitante ganará con una ventaja de al menos 2 goles al finalizar el segundo tiempo.  
Apuesta: 1 H 0:1 - Descripción: El equipo local ganará con una ventaja de al menos 2 goles al finalizar el segundo tiempo.  
Apuesta: X H 0:1 - Descripción: El equipo local ganará con un gol de ventaja al finalizar el segundo tiempo.  
Apuesta: 2 H 0:1 - Descripción: El equipo visitante ganará o empatará al finalizar el segundo tiempo.   
Marca el equipo local al finalizar el Primer tiempo
Se debe pronosticar si el equipo local marcará o no marcará al finalizar el primer tiempo.  
Apuesta: Si - Descripción: El equipo local marca al finalizar el primer tiempo.  
Apuesta: No - Descripción: El equipo local no marca al finalizar el primer tiempo.   
Marca el equipo visitante al finalizar el Primer tiempo
Se debe pronosticar si el equipo visitante marcará o no marcará al finalizar el primer tiempo.  
Apuesta: Si - Descripción: El equipo visitante marca al finalizar el primer tiempo.  
Apuesta: No - Descripción: El equipo visitante no marca al finalizar el primer tiempo.   
Marca el equipo local al finalizar el Segundo tiempo
Se debe pronosticar si el equipo local marcará o no marcará al finalizar el segundo tiempo. Nota: Los goles marcados en el primer tiempo no serán considerados.  
Apuesta: Si - Descripción: El equipo local marca al finalizar el segundo tiempo  
Apuesta: No - Descripción: El equipo local no marca al finalizar el segundo tiempo.   
Marca el equipo visitante al finalizar el Segundo tiempo
Se debe pronosticar si el equipo visitante marcará o no marcará al finalizar el segundo tiempo. Nota: Los goles marcados en el primer tiempo no serán considerados.  
Apuesta: Si - Descripción: El equipo visitante marca al finalizar el segundo tiempo.  
Apuesta: No - Descripción: El equipo visitante no marca al finalizar el segundo tiempo.   
Goles - ¿Ambos tiempos sobre 1.5? 
Se debe pronosticar si se marcarán al menos 2 goles en ambos tiempos de juego.  
Apuesta: Si - Descripción: En ambos tiempos serán marcados al menos 2 goles.  
Apuesta: No - Descripción: En ambos tiempos (o en al menos un tiempo) no serán marcados 2 goles.   
Goles - ¿Ambos tiempos por debajo 1.5? 
Se debe pronosticar si se marcará 1 o ningún gol en ambos tiempos de juego.  
Apuesta: Si - Descripción: En ambos tiempos se marcarán al menos 1 o ningún gol.  
Apuesta: No - Descripción: En ambos tiempos (o en al menos un tiempo) se marcarán 2 o más goles.   
Goles - Equipo Local - ¿Marca en ambos tiempos?
Se debe pronosticar si el equipo local marcará al menos 1 gol en ambos tiempos.  
Apuesta: Si - Descripción: El equipo local marcará al menos un gol en ambos tiempos.  
Apuesta: No - Descripción: El equipo local no marcará ni en uno ni en ambos tiempos.   
Goles - Equipo Visitante - ¿Marca en ambos tiempos?
Se debe pronosticar si el equipo visitante marcará al menos 1 gol en ambos tiempos.  
Apuesta: Si - Descripción: El equipo visitante marcará al menos 1 gol en ambos tiempos.  
Apuesta: No - Descripción: El equipo visitante no marcará ni en uno ni en ambos tiempos.   
Goles exactos
Se debe pronosticar el resultado exacto del encuentro.  
Apuesta: 1:0 - Descripción: 1:0  
Apuesta: 2:0 - Descripción: 2:0  
Apuesta: 2:1 - Descripción: 2:1  
Apuesta: 3:0 - Descripción: 3:0  
Apuesta: 3:1 - Descripción: 3:1  
Apuesta: 3:2 - Descripción: 3:2  
Apuesta: 4:0 - Descripción: 4:0  
Apuesta: 4:1 - Descripción: 4:1  
Apuesta: 4:2 - Descripción: 4:2  
Apuesta: 0:0 - Descripción: 0:0  
Apuesta: 1:1 - Descripción: 1:1  
Apuesta: 2:2 - Descripción: 2:2  
Apuesta: 3:3 - Descripción: 3:3  
Apuesta: 0:1 - Descripción: 0:1  
Apuesta: 0:2 - Descripción: 0:2  
Apuesta: 1:2 - Descripción: 1:2  
Apuesta: 0:3 - Descripción: 0:3  
Apuesta: 1:3 - Descripción: 1:3  
Apuesta: 2:3 - Descripción: 2:3  
Apuesta: 0:4 - Descripción: 0:4  
Apuesta: 1:4 - Descripción: 1:4  
Apuesta: 2:4 - Descripción: 2:4  
Apuesta: 3:4 - Descripción: 3:4  
Apuesta: 4:3 - Descripción: 4:3  
Apuesta: 4:4 - Descripción: 4:4  
Apuesta: Other - Descripción: Se apuesta a un resultado al finalizar el primer tiempo, distinto a los incluidos en la lista (uno de los equipos marcará al menos 6 goles)   
Mayor cantidad de corners
Se debe pronosticar qué equipo obtendrá el mayor número de corners durante el evento, entre las opciones propuestas:  
Apuesta: 1 - Descripción: El equipo local obtendrá el mayor número de corners.  
Apuesta: X - Descripción: El número de corners será igual para ambos equipos.  
Apuesta: 2 - Descripción: El equipo visitante obtendrá el mayor número de corners.   
Cantidad total de corners en el partido
Se debe pronosticar si la suma de los corners será mayor (Over) o menor (Under) respecto del número (Spread).  
Apuesta: Over - Descripción: Más 8.5  
Apuesta: Under - Descripción: Menos 8.5  
Apuesta: Over - Descripción: Más 9.5  
Apuesta: Under - Descripción: Menos 9.5  
Apuesta: Over - Descripción: Más 10.5  
Apuesta: Under - Descripción: Menos 10.5  
Apuesta: Over - Descripción: Más 11.5  
Apuesta: Under - Descripción: Menos 11.5  
Apuesta: Over - Descripción: Más 12.5  
Apuesta: Under - Descripción: Menos 12.5   
¿Hay penales?
Apuesta: Si  
Apuesta: No   
Doble Oportunidad FT and GG/NG Primer Tiempo 
Se debe pronosticar el equipo que ganará el encuentro, teniendo disponibles dos resultados de tres y, si ambos equipos marcarán o no al finalizar el primer tiempo.  
Apuesta: 1X FT and GG HT - Descripción: El equipo local ganará o empatará al finalizar los tiempos reglamentarios y ambos equipos marcarán en el primer tiempo.  
Apuesta: 1X FT and NG HT - Descripción: El equipo local ganará o empatará al finalizar los tiempos reglamentarios y uno o ambos equipos no marcará en el primer tiempo.  
Apuesta: 12 FT and GG HT - Descripción: El equipo local o visitante ganará al finalizar los tiempos reglamentarios y ambos equipos marcarán en el primer tiempo.  
Apuesta: 12 FT and NG HT - Descripción: El equipo local o visitante ganará al finalizar los tiempos reglamentarios y uno o ambos equipos no marcarán en el primer tiempo.  
Apuesta: X2 FT and GG HT - Descripción: El equipo visitante ganará o empatará al finalizar los tiempos reglamentarios y uno o ambos equipos marcarán en el primer tiempo.  
Apuesta: X2 FT and NG HT - Descripción: El equipo visitante ganará o empatará al finalizar los tiempos reglamentarios y uno o ambos equipos no marcarán en el primer tiempo.   
Doble Oportunidad FT and GG/NG Segundo Tiempo
Se debe pronosticar el equipo que ganará el encuentro, teniendo disponibles dos de tres resultados y si ambos equipos marcarán o no al finalizar el segundo tiempo.  
Apuesta: 1X FT and GG ST - Descripción: El equipo local ganará o empatará al finalizar los tiempos reglamentarios y ambos equipos marcarán en el segundo tiempo.  
Apuesta: 1X FT and NG ST - Descripción: El equipo local ganará o empatará al finalizar los tiempos reglamentarios y uno o ambos equipos no marcarán en el segundo tiempo.  
Apuesta: 12 FT and GG ST - Descripción: El equipo local o visitante ganará al finalizar los tiempos reglamentarios y ambos equipos marcarán en el segundo tiempo.  
Apuesta: 12 FT and NG ST - Descripción: El equipo local o visitante ganará al finalizar los tiempos reglamentarios y uno o ambos equipos no marcarán en el segundo tiempo.  
Apuesta: X2 FT and GG ST - Descripción: El equipo visitante ganará o empatará al finalizar los tiempos reglamentarios y ambos equipos no marcarán en el segundo tiempo.  
Apuesta: X2 FT and NG ST - Descripción: El equipo visitante ganará o empatará al finalizar los tiempos reglamentarios y uno o ambos equipos no marcarán en el segundo tiempo.   
Cantidad de tarjetas
Se debe pronosticar si el número total de tarjetas mostradas durante los tiempos reglamentarios será mayor (Over) o menos (Under) respecto del número (spread) indicado. Tarjeta amarilla = 1 Punto Tarjeta Roja = 2 Puntos Amarilla + Tarjeta amarilla que conduce a la tarjeta roja= 3 Puntos  
Apuesta: Over - Descripción: Más 3.5  
Apuesta: Under - Descripción: Menos 3.5  
Apuesta: Over - Descripción: Más 4.5  
Apuesta: Under - Descripción: Menos 4.5  
Apuesta: Over - Descripción: Más 5.5  
Apuesta: Under - Descripción: Menos 5.5  
Apuesta: Over - Descripción: Más 6.5  
Apuesta: Under - Descripción: Menos 6.5  
Apuesta: Over - Descripción: Más 7.5  
Apuesta: Under - Descripción: Menos 7.5   
Cantidad de tarjetas - Equipo Local
Se debe pronosticar el número de tarjetas asignadas al equipo local durante los tiempos reglamentarios.  
Apuesta: 0-1 - Descripción: Se apuesta a que el número de tarjetas asignadas al equipo local será 1 o ninguno.  
Apuesta: 2 - Descripción: Se apuesta a que el número de tarjetas asignadas al equipo local será 2  
Apuesta: 3 - Descripción: Se apuesta a que el número de tarjetas asignadas al equipo local será 3.  
Apuesta: 4+ - Descripción: Se apuesta a que el número de tarjetas asignadas al equipo local será 4 o más.   
Cantidad de tarjetas - Equipo Visitante
Se debe pronosticar el número de tarjetas asignadas al equipo visitante durante los tiempos reglamentarios.  
Apuesta: 0-1 - Descripción: Se apuesta a que el número de tarjetas asignadas al equipo visitante será 1 o ninguno.  
Apuesta: 2 - Descripción: Se apuesta a que el número de tarjetas asignadas al equipo visitante será 2.  
Apuesta: 3 - Descripción: Se apuesta a que el número de tarjetas asignadas al equipo visitante será 3.  
Apuesta: 4+ - Descripción: Se apuesta que el número de tarjetas asignadas al equipo visitante será 4 o más.   
Tarjetas rojas - Equipo Local
Se debe pronosticar si un jugador del equipo local será expulsado.  
Apuesta: Si - Descripción: Un jugador será expulsado.  
Apuesta: No - Descripción: Ningún jugador será expulsado.   
Tarjetas rojas - Equipo Visitante
Se debe pronosticar si un jugador del equipo visitante será expulsado.  
Apuesta: Si - Descripción: Un jugador será expulsado.  
Apuesta: No - Descripción: Ningún jugador será expulsado. 
Básquet
1-2
Hay que pronosticar el equipo que ganará el encuentro.  
1: El equipo local ganará el encuentro.  
2: El equipo visitante ganará el encuentro.   
Más/Menos de 
Hay que pronosticar si el número total de los puntos realizados al final de los tiempos regulares será mayor o menor del número de referencia indicado. "Eventuales tiempos suplementarios no serán considerados".  
Over: el número total de los puntos realizados al final de los tiempos regulares será mayor del número de referencia indicado.  
Under: el número total de los puntos realizados al final de los tiempos regulares será menor del número de referencia indicado.   
Par/Impar 
Hay que pronosticar si el número total de los puntos realizados será par o impar.  
Impar: el número total de los puntos realizados será impar.  
Par: el número total de los puntos realizados será par.   
Handicap 
El resultado de una apuesta con handicap se obtiene sumando el handicap al resultado final del encuentro (o sustrayendo el mismo).  
1H: el equipo local ganará con más puntos con respecto al equipo visitante (considerando el handicap).  
2H: el equipo visitante ganará con más puntos con respecto al equipo local (considerando el handicap).   
Hockey 
1X2
Hay que pronosticar el equipo que ganará el encuentro eligiendo entre las opciones propuestas:  
1: El equipo local ganará el encuentro.  
X: Se apuesta sobre el empate.  
2: El equipo visitante ganará el encuentro.   
DC 
Hay que pronosticar el equipo que ganará el encuentro, eligiendo entre las opciones propuestas.  
1XDC: se apuesta sobre la victoria del equipo local o sobre el empate.  
12DC: Se apuesta sobre la victoria del equipo local o del equipo visitante.  
X2DC: Se apuesta sobre la victoria del equipo visitante o sobre el empate.   
Más/Menos de
Hay que pronosticar si la suma de los goles marcados en un encuentro será mayor (over) o menor (under) con respecto al número indicado.  
Over: el número total de los goles marcados será mayor con respecto al número indicado.  
Under: el número total de los goles marcados será menor con respecto al número indicado. 
Hándicap 
El resultado de una apuesta con hándicap se obtiene sumando el valor del spread al resultado final del encuentro (o sustrayendo el mismo). El valor del spread siempre se refiere al equipo local.  
1H (2:0): El equipo local ganará, empatará o perderá con 1 gol de diferencia.  
1H (0:2): El equipo local ganará con al menos 3 goles de diferencia.  
XH (2:0): El equipo visitante ganará con 2 goles de diferencia.  
XH (0:2): El equipo local ganará con 2 goles de diferencia.  
2H (2:0): El equipo visitante ganará con al menos de 3 goles de diferencia.  
2H (0:2): El equipo visitante ganará, empatará, o perderá con 1 gol de diferencia.  
1H (3:0): El equipo local ganará, empatará, o perderá con 1 o 2 goles de diferencia.  
1H (0:3): El equipo local ganará con al menos 4 goles de diferencia.  
XH (3:0): El equipo visitante ganará con 3 goles de diferencia.  
XH (0:3): El equipo local ganará con 3 goles de diferencia.  
2H (3:0): El equipo visitante ganará con una diferencia de al menos 4 goles.  
2H (0:3): El equipo visitante ganará, empatará o perderá con 1 o 2 goles de diferencia.  
1H (1:0): el equipo local ganará o empatará.  
XH (0:1): el equipo local ganará con 1 gol de diferencia.  
2H (0:1): el equipo visitante ganará o empatará  
1H (0:1): el equipo local ganará por lo menos con 2 goles de diferencia.  
XH (1:0): el equipo visitante ganará con 1 gol de diferencia.  
2H (1:0): el equipo visitante ganará por lo menos con 2 goles de diferencia   
Par/ Impar
Hay que pronosticar si el número total de los goles de un encuentro será par o impar. El resultado de 0-0 se considera como par.  
Impar: Se apuesta sobre el hecho de que el número total de los goles marcados en el encuentro será impares.  
Par: Se apuesta sobre el hecho de que el número total de los goles marcados será pares.
    
Tenis
1-2
Hay que predecir el ganador del encuentro.  
1: el jugador del equipo local ganará el encuentro.  
2: el jugador del equipo visitante ganará el encuentro.   
Resultado correcto (Mejor de 3). 
Hay que pronosticar el resultado correcto del encuentro considerando el número de los set ganados por parte de cada tenista.  
2-0: el jugador del equipo local ganará 2 set y el jugador del equipo visitante no ganará ningún set.  
2-1: el jugador del equipo local ganará 2 set y el jugador del equipo visitante ganará un set.  
1-2: el jugador del equipo local ganará un set y el jugador del equipo visitante ganará 2 set.  
0-2: el jugador del equipo local no ganará ningún set y el jugador del equipo visitante ganará 2 set.   
Resultado correcto (Mejor de 5). 
Hay que pronosticar el resultado correcto del encuentro considerando el número de los set ganados por parte de cada tenista.  
3-0: el jugador del equipo local ganará 3 set y el jugador del equipo visitante no ganará ningún set.  
3-1: el jugador del equipo local ganará 3 set y el jugador del equipo visitante ganará un set.  
3-2: el jugador del equipo local ganará 3 set y el jugador del equipo visitante ganará 2 set.  
0-3: el jugador del equipo local no ganará ningún set y el jugador del equipo visitante ganará 3 set.  
1-3: el jugador del equipo local ganará un set y el jugador del equipo visitante ganará 3 set.  
2-3: el jugador del equipo local ganará 2 set y el jugador del equipo visitante ganará 3 set.   
Set Hand (Mejor de 3). 
El resultado de la apuesta se obtiene sumando o sustrayendo el valor del spread al resultado final del jugador del jugador del equipo local (en set). El valor del spread siempre se refiere al jugador del equipo local.  
H (-1.5): el jugador del equipo local ganará por 2 set a 0.  
H (-1.5): el jugador del equipo visitante ganará el encuentro o lo perderá por 2 set a 1.  
H (+1.5): el jugador del equipo local ganará el encuentro o lo perderá por 2 set a 1.  
H (+1.5): el jugador del equipo visitante ganará por 2 set a 0.   
Set Hand (mejor de 5). 
El resultado de la apuesta se obtiene sumando o sustrayendo el valor del spread al resultado final del jugador del equipo local (en set). El valor del spread siempre se refiere al jugador del equipo local.  
1H (-2.5): el jugador del equipo local ganará por 3 set a 0.  
2H (-2.5): el jugador del equipo visitante ganará el encuentro o lo perderá por 3 set a 1 o por 3 set a 2.  
1H (-1.5): el jugador del equipo local ganará por 3 set a 0 o por 3 set a 1.  
2H (-1.5): el jugador del equipo visitante ganará el encuentro o lo perderá por 3 set a 2.  
1H (+1.5): el jugador del equipo local ganará el encuentro o lo perderá por 3 set a 2.  
2H (+1.5): el jugador del equipo visitante ganará por 3 set a 0 o por 3 set a 1.  
1H (+2.5): el jugador del equipo local ganará el encuentro o lo perderá por 3 set a 1 o por 3 set a 2.  
2H (+2.5): el jugador del equipo visitante ganará por 3 set a 0.   
Vóley
1-2
Hay que pronosticar el equipo ganador del encuentro.  
1: El equipo local ganará en el encuentro.  
2: El equipo visitante ganará en el encuentro.   
Resultado Correcto 
Hay que pronosticar el resultado correcto del encuentro según el set.  
3-0: El equipo local ganará 3 set y el equipo visitante no ganará ningún set.  
3-1: El equipo local ganará 3 set y el equipo visitante ganará 1 set.  
3-2: El equipo local ganará 3 set y el equipo visitante ganará 2 set.  
0-3: El equipo local no ganará ningún set y el equipo visitante ganará 3 set.  
1-3: El equipo local ganará un set y el equipo visitante ganará 3 set.  
2-3: El equipo local ganará 2 set y el equipo visitante ganará 3 set. 
   
Handball
1X2
Hay que pronosticar el equipo que ganará el encuentro.  
1: El equipo local ganará en el encuentro.  
X: Se apuesta por el empate.  
2: El equipo visitante ganará en el encuentro.   
Más/Menos de 
Hay que pronosticar si el número total de los goles marcados será mayor o menor con respecto al número de referencia indicado (spread).  
Over: el número total de los goles marcados será mayor con respecto al número indicado (spread).  
Under: el número total de los goles marcados será menor con respecto al número indicado (spread).   
Par/Impar 
Hay que pronosticar si el número total de los goles marcados en el encuentro será par o impar.  
Impar: El número total de los goles marcados en el encuentro será impar.  
Par: El número total de los goles marcados en el encuentro será par.
    
Béisbol
1-2
Hay que pronosticar el equipo ganador del encuentro.  
1: El equipo local ganará en el encuentro.  
2: El equipo visitante ganará en el encuentro.   
Más/Menos de 
Hay que pronosticar si el número total de las carreras marcadas en el encuentro será mayor o menor con respecto al número de referencia indicado (spread).  
Over: el número total de las carreras marcadas en el encuentro será mayor con respecto al número de referencia indicado (spread).  
Under: el número total de las carreras marcadas en el encuentro será menor con respecto al número de referencia indicado (spread). 
   
Waterpolo
1X2 
Hay que pronosticar el equipo ganador del encuentro.  
1: Se apuesta por la victoria del equipo local.  
X: Se apuesta por el empate.  
2: Se apuesta por la victoria del equipo visitante.  
Más/Menos de 
Hay que pronosticar si el número total de los goles marcados en el encuentro será mayor o menor con respecto al número de referencia indicado (spread). 
Over: el número total de los goles marcados será mayor con respecto al número indicado (spread). 
Under: el número total de los goles marcados será menor con respecto al número indicado (spread).
   
Criket
1-2
Hay que pronosticar el equipo ganador del encuentro.  
1: Se apuesta por la victoria del equipo local. 
2: Se apuesta por la victoria del equipo visitante.   
Dardos
1-2
Hay que pronosticar el competidor que ganará el encuentro. 
1: El primer competidor en la lista ganará en el encuentro.  
2: El segundo competidor en la lista ganará en el encuentro.
   
Boxeo/MMA
1-2
Hay que pronosticar el equipo que ganará el encuentro. 
1: El primer luchador en la lista ganará el encuentro.  
2: El segundo luchador en la lista ganará el encuentro.
   
Futsal
1X2
Hay que pronosticar el ganador del encuentro. 
1: El equipo local ganará en el encuentro.  
X: Se apuesta por el empate.  
2: El equipo visitante ganará en el encuentro.   
Más/Menos de 
Hay que pronosticar si el número total de los goles marcados será mayor o menor con respecto al número de referencia indicado (spread).  
Over: el número total de los goles marcados será mayor con respecto al número indicado. 
Under: el número total de los goles marcados será menor con respecto al número indicado.  
Par/Impar 
Hay que pronosticar si el número total de los goles marcados en el encuentro será par o impar.  El resultado 0-0 se considera como par. 
Impar: El número total de los goles marcados en el encuentro será impar.  
Par: El número total de los goles marcados en el encuentro será par.
    
Fútbol Australiano - AUSSIE
1-2
Hay que pronosticar el equipo que ganará el encuentro. 
1: Se apuesta por la victoria del equipo local. 
2: Se apuesta por la victoria del equipo visitante.  
Más/Menos de 
Hay que pronosticar si el número total de los puntos marcados en el encuentro será mayor (Más) o menor (Menos) con respecto al número indicado (spread). 
Over: el número total de los puntos marcados será mayor con respecto al número indicado (spread). 
Under: el número total de los puntos marcados será menor con respecto al número indicado (spread).
   
Beach Volley
1-2
Hay que pronosticar el equipo ganador del encuentro. 
1: El equipo local ganará en el encuentro. 
2: El equipo visitante ganará en el encuentro. 
  
Pesapallo 
1X2
Hay que pronosticar el equipo que ganará el encuentro eligiendo entre las opciones propuestas: 
1: El equipo local ganará el encuentro 
X: Se apuesta sobre el empate.  
2: El equipo visitante ganará en el encuentro. 
  
Moto GP
Cabeza a Cabeza:
Se debe pronosticar que piloto (de los dos preseleccionados) obtendrá la mejor posición en el MotoGP. En caso de que dos pilotos se retiren en la misma ronda, las apuestas serán consideradas nulas. 
1: El piloto seleccionado tendrá una posición mejor que el piloto 2 
2: El piloto seleccionado tendrá una posición mejor que el piloto 1 
  
Formula 1
Cabeza a Cabeza:
Se debe pronosticar que piloto (de los dos preseleccionados) obtendrá la mejor posición en el Gran Premio. En caso de que dos pilotos se retiren en la misma ronda, las apuestas serán consideradas nulas. 
1: El piloto seleccionado tendrá una posición mejor que el piloto 2 
2: El piloto seleccionado tendrá una posición mejor que el piloto 1  
 
Rugby
1X2
Hay que pronosticar el equipo que ganará el encuentro eligiendo entre las opciones propuestas. 
1: El equipo local ganará en el encuentro.  
X: Se apuesta por el empate.  
2: El equipo visitante ganará en el encuentro.   
Más/Menos de 
Se debe pronosticar si el número total de puntos del encuentro será mayor (over) o menor (under) que el número dado como referencia.  
Over: el número total de los goles marcados será mayor con respecto al número indicado. 
Under: el número total de los goles marcados será menor con respecto al número indicado.
   
Beach Soccer
1X2
Hay que pronosticar el equipo que ganará el encuentro eligiendo entre las opciones propuestas. 
1: El equipo local ganará en el encuentro.  
X: Se apuesta por el empate.  
2: El equipo visitante ganará en el encuentro.   
Más/Menos de 
Hay que pronosticar si la suma de los goles marcados en un encuentro será mayor (over) o menor (under) con respecto al número indicado.  
Over: el número total de los goles marcados será mayor con respecto al número indicado. 
Under: el número total de los goles marcados será menor con respecto al número indicado. 
  
Floorball
1X2
Hay que pronosticar el equipo que ganará el encuentro eligiendo entre las opciones propuestas. 
1: El equipo local ganará en el encuentro.  
X: Se apuesta por el empate.  
2: El equipo visitante ganará en el encuentro.   
DC
Hay que pronosticar el equipo que ganará el encuentro, eligiendo entre las opciones propuestas. 
1XDC: se apuesta sobre la victoria del equipo local o sobre el empate. 
12DC: Se apuesta sobre la victoria del equipo local o del equipo visitante. 
X2DC: Se apuesta sobre la victoria del equipo visitante o sobre el empate.  
Más/Menos
Hay que pronosticar si la suma de los goles marcados en un encuentro será mayor (Más) o menor (Menos) con respecto al número indicado. 
Over: el número total de los goles marcados será mayor con respecto al número indicado. 
Under: el número total de los goles marcados será menor con respecto al número indicado.  
Par/Impar 
Hay que pronosticar si el número total de los goles de un encuentro será pares o impares. El resultado de 0-0 se considera como par. 
Impar: Se apuesta sobre el hecho de que el número total de los goles marcados en el encuentro será impares. 
Par: Se apuesta sobre el hecho de que el número total de los goles marcados será pares.  
Squash
1-2
Hay que predecir el ganador del encuentro. 
1: El equipo local ganará en el encuentro.  
2: El equipo visitante ganará en el encuentro.
    
Deportes electrónicos
1X2
El Jugador apuesta al resultado final del partido. 
1: El equipo local ganará.  
X: Empate.  
2: El equipo visitante ganará. 
   
Doble oportunidad
Hay que pronosticar el ganador del partido eligiendo una de las opciones disponibles. 
Apuesta: 1X - Descripción: El equipo de local ganará el partido o el resultado será empate. 
Apuesta: 12 - Descripción: El equipo de local o el equipo visitante ganará el partido. 
Apuesta: X2 - Descripción: El equipo visitante ganará el partido o el resultado será empate.  
Más/Menos
Hay que pronosticar si la suma de los goles será mayor (Más) o menor (Menos) que el número indicado. 
Over: Más 2.5 
Under: Menos 2.5

        </div>
      
    </div>
  );
};

export default Glosario;