import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminLogin from './components/AdminLogin'; // Formulario de ingreso
import AdminDashboard from './components/AdminDashboard'; // Panel principal
import AdminPlayers from './components/AdminPlayers';
import AdminCashiers from './components/AdminCashiers';
import AdminStaff from './components/AdminStaff';
import WithSidebar from './components/WithSidebar'; // Contenedor con menú
import { UserProvider } from './components/UserContext';  // Importar el UserProvider


const AdminApp = () => {

  return (
    // Envolviendo el contenido de las rutas con UserProvider
    <UserProvider>
      <Routes>
        {/* Página sin menú */}
        <Route path="/" element={<AdminLogin />} /> 

        {/* Páginas con menú */}
        <Route 
          path="/dashboard" 
          element={
            <WithSidebar>
              <AdminDashboard />
            </WithSidebar>
          } 
        />
        <Route 
          path="/players" 
          element={
            <WithSidebar>
              <AdminPlayers />
            </WithSidebar>
          } 
        />
        <Route 
          path="/cashiers" 
          element={
            <WithSidebar>
              <AdminCashiers />
            </WithSidebar>
          } 
        />

        <Route
          path="/staff"
          element={
              <WithSidebar>
                <AdminStaff />
              </WithSidebar>
          }
        />
        {/* Agrega más rutas aquí, usando WithSidebar si necesitan el menú */}
      </Routes>
    </UserProvider>
  );
};

export default AdminApp;
