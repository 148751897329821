import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/css/CarousselElements.css'; // Estilos personalizados

// Datos de ejemplo (reemplázalos con los datos reales)
const elements = [
  { id: 1, title: 'VIP Roulette - The Club', image: '/images/roulette1.png', provider: 'PRAGMATIC LIVE' },
  { id: 2, title: 'VIP Auto Roulette', image: '/images/roulette2.png', provider: 'PRAGMATIC LIVE' },
  { id: 3, title: 'Speed Roulette 1', image: '/images/roulette3.png', provider: 'PRAGMATIC LIVE' },
  { id: 4, title: 'Spanish Roulette', image: '/images/roulette4.png', provider: 'VIVOGAMESLIVE' },
  { id: 5, title: 'Roulette Turkish', image: '/images/roulette5.png', provider: 'PRAGMATIC LIVE' },
];

const CarousselElements = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Número de elementos visibles
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="carousel-elements-container">
      <h3 className="carousel-elements-title">MAS JUGADOS</h3>
      <Slider {...settings}>
        {elements.map((item) => (
          <div key={item.id} className="carousel-elements-card">
            <img src={item.image} alt={item.title} className="carousel-elements-image" />
            <div className="carousel-elements-info">
              <h4>{item.title}</h4>
              <p>{item.provider}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CarousselElements;