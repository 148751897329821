// RoleServices/permissions.js

import httpClient from '../httpClient';

// Crear un permiso
export const createPermission = async (permissionData) => {
  const response = await httpClient.post('/permissions', permissionData);
  return response.data;
};

// Obtener todos los permisos
export const getPermissions = async () => {
  const response = await httpClient.get('/roles-permissions/permissions');
  return response.data;
};

// Obtener un permiso por ID
export const getPermissionById = async (id) => {
  const response = await httpClient.get(`/roles-permissions/permissions/id/${id}`);
  return response.data;
};

// Obtener un permiso por nombre
export const getPermissionByName = async (permission_name) => {
  const response = await httpClient.get(`/roles-permissions/permissions/name/${permission_name}`);
  return response.data;
};

//editar status de un permiso por nombre
export const updatePermissionByName = async (permission_name, status) => {
  const response = await httpClient.put('/roles-permissions/permissions', {permission_name, status});
  return response.data
};

// Eliminar un permiso por ID
export const deletePermission = async (id) => {
  const response = await httpClient.delete(`/roles-permissions/permissions/${id}`);
  return response.data;
};