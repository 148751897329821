// components/CarouselPromociones.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/css/CarousselPromociones.css';
import slots from '../assets/images/slots.png';
import welcome from '../assets/images/welcome.png';
import deporte from '../assets/images/deporte.png';
import casino from '../assets/images/casinoen vivo.png';

const CarouselPromociones = () => {
  const settings = {
    dots: true, // Muestra indicadores de posición
    infinite: true, // Carrusel infinito
    speed: 2000, // Velocidad de transición en milisegundos
    slidesToShow: 1, // Número de imágenes visibles
    slidesToScroll: 1, // Número de imágenes a desplazarse
    autoplay: true, // Activa el pase automático
    autoplaySpeed: 3000, // Tiempo entre transiciones (3 segundos)
    pauseOnHover: true, // Pausa al pasar el ratón sobre el carrusel
    arrows: true, // Muestra las flechas de navegación
  };

  const images = [
    slots,
    welcome,
    deporte,
    casino
  ];

  return (
    <div className="carousel-promociones">
      <Slider {...settings}>
        {images.map((src, index) => (
          <div key={index} className="carousel-item">
            <img src={src} alt={`Promoción ${index + 1}`} className="carousel-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CarouselPromociones;
