import React from 'react';
import CarousselPromociones from '../components/CarousselJuegos';
import BannersRedirecciones from '../components/BannersRedirecciones';
import '../assets/css/home.css';

function CashierPage() {
  return (
    <div className="home">
      {/* Carrusel de Promociones */}
      <div className="home-carousel">
        <CarousselPromociones />
      </div>

      {/* Banners de Redirección */}
      <div className="home-redirecciones">
        <BannersRedirecciones />
      </div>
    </div>
  );  
};


export default CashierPage;