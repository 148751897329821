// ReportServices/cashiers.js

import httpClient from '../httpClient';

// Crear un informe de cajero
export const createReportCashier = async (reportData) => {
  const response = await httpClient.post('/reports/cashiers', reportData);
  return response.data;
};

// Obtener todos los informes de cajeros
export const getReportsCashiers = async () => {
  const response = await httpClient.get('/reports/cashiers');
  return response.data;
};

// Obtener un informe de cajero por ID
export const getReportCashierById = async (id) => {
  const response = await httpClient.get(`/reports/cashiers/${id}`);
  return response.data;
};

// Filtrar informes de cajeros por parámetros
export const getReportsCashiersByFilters = async (filters) => {
  const response = await httpClient.get('/reports/cashiers/filter', {
    params: filters,
  });
  return response.data;
};
