import React from 'react';
import '../../assets/css/footer.css';

const JuegoResponsable = () => {
  return (
    <div className="footer-page">
        <div className='footer-page-title'>
            <h1>RESPONSABLE DE LA UE:</h1>
        </div>
        <div className='footer-page-content'>
            <h4>CONSEJOS PARA LOS JUGADORES</h4>
            <h5>Gerente de Juegos</h5>
            <p> 
            www.play.bet.ar se adhiere al más alto nivel de estándares éticos. Uno de los pilares fundamentales de www.play.bet.ar es brindar diversión a sus jugadores y toma todas las precauciones necesarias para garantizar que el elemento de diversión reine en todo momento. Por lo tanto, cada jugador tiene una página de funciones de restricción que les permite establecer límites fijos a la cantidad que pueden jugar. El personal de www.play.bet.ar también hará todo lo posible para excluir a los menores de edad y a aquellos que no comprendan las responsabilidades del juego online.

            </p>
            <h5>Lista de verificación de responsabilidad del jugador</h5>
            <p>
                * Sólo debes jugar por diversión. Si juegas para recuperar pérdidas, estás jugando por el motivo equivocado.
                * Sólo debes jugar con el dinero que has reservado para entretenimiento, no con el dinero que necesitas para los gastos básicos. Nunca apuestes con dinero prestado.
                * El juego no pretende dañar tu relación con familiares y amigos. Si notas que empieza a molestarte, debes parar.

            </p>
            <h5>Reconoce tu problema</h5>
            <p>
                * Tienes dificultades para controlar el tiempo y el dinero que gastas en juegos de azar.
                * Siempre piensas en el juego y te cuesta concentrarte en cualquier otra cosa.
                * Está pasando por dificultades financieras.
                * Descuidas responsabilidades como la familia, el trabajo o la educación debido al juego.
                * Pides dinero prestado para poder pagar el juego.
                * Mentirte sobre tus juegos de azar u ocultarlos por completo.
                * Hablas con tu familia y amigos sobre tus hábitos de juego.
                * Considera que el juego es una forma de salir de los problemas financieros.
                * Has perdido interés en tus aficiones, actividades o amigos habituales.
                * Sientes constantemente la necesidad de jugar con mayores cantidades de dinero.
                Si está de acuerdo con algo de lo anterior, lo mejor sería que buscara ayuda. Aquí hay un enlace directo a un sitio web recomendado que podría ayudarlo a lidiar con su adicción:
                www.gamblersanonymous.org
            </p>
            <h5>Menores de 18 Años</h5>
            <p>
                La ley prohíbe a menores de 18 años abrir una cuenta o jugar en los sitios www.play.bet.ar. Los menores que sean descubiertos jugando en estos sitios perderán sus ganancias y el incidente podrá ser reportado a las autoridades.

            </p>
            <h5>Consejos de Uso</h5>
            <p>
                * www.play.bet.ar considera que es su deber garantizar que los clientes de www.play.bet.ar puedan disfrutarlo, siendo conscientes de los posibles perjuicios sociales y económicos vinculados al juego.
                * El juego puede ser un momento de diversión placentero y emocionante, pero hay que ejercitar el autocontrol.
                * Aprovechando las capacidades de los juegos online gratuitos, te acostumbrarás a los efectos gráficos y sonoros y, en particular, aprenderás las reglas del juego.
                * Nunca apuestes más de lo que puedes permitirte.
                * Nunca juegues cuando estés cansado o bajo la influencia del alcohol.
                * Establece un límite en tus depósitos o apuestas.
                * Una vez que hayas alcanzado las ganancias, deja un poco a un lado y juega con el dinero restante.
                * No te endeudes para apostar.
            </p>
        </div>
      
    </div>
  );
};

export default JuegoResponsable;