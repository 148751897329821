import React from 'react';
import '../assets/css/vipStyles.css';  // Estilos exclusivos para la sala VIP

const VIPLayout = ({ children }) => {
  return (
    <div className="vip-container">
      <main className="vip-content">{children}</main>
    </div>
  );
};

export default VIPLayout;
