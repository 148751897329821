import React from 'react';
import '../../assets/css/footer.css';

const ReglasJuego = () => {
  return (
    <div className="footer-page">
        <div className='footer-page-title'>
            <h1>REGLAS DEL JUEGO</h1>
        </div>
        <div className='footer-page-content'>
            <h5>1. Introducción</h5>
            <p>   
                Bienvenido a Play.bet.ar, el casino online donde puedes disfrutar de una experiencia de juego emocionante y segura. Al utilizar nuestros servicios, aceptas cumplir con las reglas y regulaciones establecidas en este documento.
            </p>
            <h5>2. Elegibilidad</h5>
            <p>
                Solo pueden jugar personas mayores de 18 años.
                No se permite el acceso a residentes de países donde el juego en línea esté prohibido.
                Cada usuario debe proporcionar información veraz y verificable al registrarse.
            </p>
            <h5>3. Registro y Cuentas</h5>
            <p>
                Cada jugador puede poseer solo una cuenta.
                El uso de información falsa o múltiples cuentas resultará en la suspensión del usuario.
                Se recomienda establecer una contraseña segura y no compartirla con terceros.            </p>
            <h5>4. Depósitos y Retiros</h5>
            <p>
                Los depósitos deben realizarse a través de los métodos de pago autorizados por Play.bet.ar.
                Los retiros están sujetos a verificaciones de seguridad y pueden tardar hasta 72 horas en procesarse.
                Se prohíben intentos de fraude o lavado de dinero.
            </p>
            <h5>5. Reglas de Juego</h5>
            <p>
                Cada juego tiene sus propias reglas específicas que deben ser leídas antes de jugar.
                Se prohíbe el uso de software o estrategias que manipulen los resultados.   
                Los resultados de los juegos son determinados por algoritmos de generación aleatoria certificados.
            </p>
            <h5>6. Conducta del Jugador</h5>
            <p>
                No se tolerarán comportamientos abusivos o fraudulentos.
                Se penalizará el uso de lenguaje ofensivo en el chat en vivo o foros.
                Play.bet.ar se reserva el derecho de suspender cuentas que incumplan estas reglas.
            </p>
            <h5>7. Juego Responsable</h5>
            <p>
                Se recomienda establecer límites de depósito para mantener el control del juego.
                Play.bet.ar ofrece herramientas para la autoexclusión si el juego se convierte en un problema.
                Jugar con responsabilidad es clave para una experiencia segura y divertida.
            </p>
            <h5>8. Privacidad y Seguridad</h5>
            <p>
                Los datos de los jugadores se manejan con estricta confidencialidad.
                Utilizamos tecnología de encriptación para proteger la información personal y financiera.
                No compartimos información con terceros sin consentimiento del usuario.
            </p>
            <h5>9. Modificaciones de las Reglas</h5>
            <p>
                Play.bet.ar se reserva el derecho de modificar estas reglas en cualquier momento.
                Los jugadores serán notificados de cambios a través del sitio web o correo electrónico.
            </p>
            <h5>10. Contacto y Soporte</h5>
            <p>
                Si tienes dudas o necesitas ayuda, nuestro equipo de soporte está disponible las 24 horas del día a través del chat en vivo, correo electrónico o WhatsApp.
            </p>
            <h4>Gracias por elegir Play.bet.ar. ¡Buena suerte y disfruta del juego!</h4>
        </div>
      
    </div>
  );
};

export default ReglasJuego;