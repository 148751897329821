import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from './UserContext'; // Importar el contexto del usuario
import '../assets/css/sidebarMenu.css';

const SidebarMenu = ({ isSidebarOpen, toggleSidebar }) => {
  const location = useLocation();
  const { currentUser } = useContext(UserContext);

  return (
    <div className={`sidebar-menu ${isSidebarOpen ? '' : 'hidden'}`}>
      <h2>Panel Administrativo</h2>
      <ul>
        <li className={location.pathname === '/admin/dashboard' ? 'active' : ''}>
          <Link to="/admin/dashboard" onClick={toggleSidebar}>Dashboard</Link>
        </li>
        <li className={location.pathname === '/admin/players' ? 'active' : ''}>
          <Link to="/admin/players" onClick={toggleSidebar}>Gestión de Jugadores</Link>
        </li>
        <li className={location.pathname === '/admin/cashiers' ? 'active' : ''}>
          <Link to="/admin/cashiers" onClick={toggleSidebar}>Gestión de Cajeros</Link>
        </li>
        {currentUser?.role_id === 1 && (
          <li className={location.pathname === '/admin/staff' ? 'active' : ''}>
            <Link to="/admin/staff" onClick={toggleSidebar}>STAFF</Link>
          </li>
        )}
        <li className={location.pathname === '/admin/reports' ? 'active' : ''}>
          <Link to="/admin/reports" onClick={toggleSidebar}>Reportes</Link>
        </li>
      </ul>
      <div className="logout">
        <Link to="/admin" onClick={toggleSidebar}>Cerrar Sesión</Link>
      </div>
    </div>
  );
};

export default SidebarMenu;
