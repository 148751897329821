import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/MiPerfil.css';
import { editPlayer, getPlayerById } from '../services/UserServices/player';
import { getCashierById } from '../services/UserServices/cashier';
import { getUserById } from '../services/UserServices/user';
import { getMovimientos } from '../services/ReportServices/movimientos';
import { useUser } from '../contexts/UserContext';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

const MiPerfil = () => {
    const [selectedButton, setSelectedButton] = useState('boton1');
    const [selectedOption, setSelectedOption] = useState('datos-personales');
    const { user, setUser } = useUser();
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [currentPassword, setCurrentPassword] = useState('');
    const [newUserPassword, setNewUserPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [newPhone, setNewPhone] = useState('');

    const [movementState, setMovementState] = useState("");
    const [movementProvider, setMovementProvider] = useState("");
    const [movements, setMovements] = useState([]);
    const [allMovements, setAllMovements] = useState([]); 

    const formatDate = (isoString) => {
        const date = new Date(isoString);
    
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
    
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
    
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const extractDate = (datetimeString) => {
        return datetimeString.split(' ')[0]; // Devuelve solo la parte de la fecha "YYYY-MM-DD"
    };

    const fetchNameByType = async (id, type) => {
        const typeToFunctionMap = {
            player: getPlayerById,
            cashier: getCashierById,
            user: getUserById,
        };
    
        const fetchFunction = typeToFunctionMap[type];
        if (!fetchFunction || !id) {
            return "N/A"; // Retornar "N/A" si el tipo o ID no es válido
        }
    
        try {
            const response = await fetchFunction(id); // Llama a la función correspondiente
            console.log(`Respuesta para id=${id}, type=${type}:`, response);
            return (
                response.player_name || // Para jugadores
                response.cashier_name || // Para cajeros
                response.name || // Para usuarios
                "N/A"
            );
        } catch (error) {
            console.error(`Error fetching name for type: ${type}, id: ${id}`, error);
            return "N/A";
        }
    };

    const fetchMovements = async () => {
        setLoading(true);
        setError('');
        setMovements([]); // Limpia movimientos previos mientras carga
        setAllMovements([]); // Limpia el estado de todos los movimientos
    
        try {
            const response = await getMovimientos();
            console.log('Movimientos obtenidos del servicio:', response);
    
            if (!response || response.length === 0) {
                setError('No se encontraron movimientos.');
                return;
            }
    
            // Filtrar movimientos relevantes para el usuario actual
            const userMovements = response.filter((item) => {
                // Filtrar "TRANSFERENCIA REALIZADA" solo si el usuario es el remitente
                if (item.description === "TRANSFERENCIA REALIZADA" && item.id_sender === user.id) {
                    return true;
                }

                // Filtrar "TRANSFERENCIA RECIBIDA" solo si el usuario es el receptor
                if (item.description === "TRANSFERENCIA RECIBIDA" && item.id_receptor === user.id) {
                    return true;
                }

                // Filtrar "TRANSFERENCIA RECIBIDA" solo si el usuario es el receptor
                if (item.description === "RETIRO POR CAJERO" && item.id_sender === user.id) {
                    return true;
                }

                // Incluir otros movimientos no relacionados con transferencias (e.g., TELEPAGOS, RETIROS)
                if (item.description !== "TRANSFERENCIA REALIZADA" && item.description !== "TRANSFERENCIA RECIBIDA") {
                    return item.id_sender === user.id || item.id_receptor === user.id;
                }

                return false; // Excluir todo lo demás
            });
    
            // Mapeo dinámico para obtener nombres según sender_type y receiver_type
            const movementsWithUsers = await Promise.all(
                userMovements.map(async (item) => {
                    const senderName = 
                        item.sender_type
                            ? await fetchNameByType(item.id_sender, item.sender_type) // Prioridad al sender_type
                            : item.description?.toUpperCase().includes('TELEPAGOS')
                            ? 'TELEPAGOS'
                            : 'N/A'; // Si no hay sender_type ni TELEPAGOS

                    const receptorName = 
                        item.receiver_type
                            ? await fetchNameByType(item.id_receptor, item.receiver_type) // Prioridad al receiver_type
                            : item.description?.toUpperCase().includes('TELEPAGOS')
                            ? 'TELEPAGOS'
                        : 'N/A'; // Si no hay receiver_type ni TELEPAGOS
    
                    return {
                        ...item,
                        fecha_hora: formatDate(item.fecha_hora),
                        senderName,
                        receptorName,
                    };
                })
            );
    
            setAllMovements(movementsWithUsers); // Guarda todos los movimientos
            setMovements(movementsWithUsers); // Inicializa con todos los movimientos
        } catch (err) {
            console.error('Error al obtener movimientos:', err);
            setError('Error al cargar las transferencias. Intenta nuevamente.');
        } finally {
            setLoading(false);
        }
    };

    const applyFilters = () => {
        let filtered = [...allMovements];
    
        // Filtro por fecha "Desde"
        if (fromDate) {
            filtered = filtered.filter(
                (item) => extractDate(item.fecha_hora) >= fromDate
            );
        }

        // Filtro por fecha "Hasta"
        if (toDate) {
            filtered = filtered.filter(
                (item) => extractDate(item.fecha_hora) <= toDate
            );
        }
    
        // Filtro por tipo de movimiento
        if (movementState) {
            filtered = filtered.filter((item) => item.description === movementState);
        }
    
        setMovements(filtered);
    };

    useEffect(() => {
        if (selectedOption === 'transferencias') {
            fetchMovements();
        }
    }, [selectedOption]);

    //CAMBIAR CONTRASEÑA USUARIO
    const handlePasswordChange = async () => {
        // Reset messages
        setErrorMessage('');
        setSuccessMessage('');

        // Validar si la contraseña actual es correcta
        if (currentPassword !== user.password) {
            toast.error('La contraseña actual es incorrecta.');
            return;
        }

        // Validar si la nueva contraseña y su confirmación coinciden
        if (newUserPassword !== confirmNewPassword) {
            toast.error('La nueva contraseña y su confirmación no coinciden.');
            return;
        }

        // Validar si la contraseña no es la misma a la actual
        if ((newUserPassword || confirmNewPassword) === user.password) {
            toast.error('La nueva contraseña debe ser distinta a la anterior.');
            return;
        }

        // Validar que las contraseñas no estén vacías
        if (!newUserPassword || !confirmNewPassword) {
            toast.error('La nueva contraseña no puede estar vacía.');
            return;
        }


        const updatedFormData = { password: newUserPassword };
        await editPlayer(user.id, updatedFormData);

        // Si todo está correcto, realizar la actualización
        toast.success('¡Contraseña cambiada exitosamente!')
        setSuccessMessage('');
        setCurrentPassword('');
        setNewUserPassword('');
        setConfirmNewPassword('');

        setUser({ ...user, password: newUserPassword });
        localStorage.setItem(
            'currentUser',
            JSON.stringify({ ...user, password: newUserPassword })
        );

        
    };

    //MODAL EDITAR TELEFONO
    const openEditModal = (user) => {
        setNewPhone(user.phone); // Inicializa con el teléfono actual
        setIsModalOpen(true);
    };
    
    // Cierra el modal
    const closeEditModal = () => {
        setIsModalOpen(false);
        
    };

    // Guarda los cambios
    const handleSaveChanges = async () => {
        if (user) {
          user.phone = newPhone; // Actualiza el teléfono localmente

          const updatedFormData = { phone: newPhone };
          await editPlayer(user.id, updatedFormData);
          toast.success("CAMBIO EXITOSO")
          closeEditModal();
        }
    };

    // CONTENIDO RECUADRO GRANDE SEGUN SELECCION
    const renderContent = () => {
        switch (selectedOption) {
        case 'datos-personales':
            return (
                <div>
                    <h3>Generales</h3>
                    <div className="form-row">
                        <label className='label'>Nombre de Usuario</label>
                        <input type="text" readOnly value={user.player_name} />
                    </div>
                    <div className="form-row">
                        <label className='label'>CUIL</label>
                        <input type="text" readOnly value={user.cuil} />
                    </div>
                    {/* Número de Teléfono */}
                    <div className="security-box">
                        <h4>Número de teléfono</h4>
                            <div className="form-row">
                                <label>Móvil</label>
                                <input type="text" readOnly value={user.phone} />
                                <button onClick={() => openEditModal(user)}>Editar</button>
                                {/* Modal */}
                                <Modal
                                    isOpen={isModalOpen}
                                    onRequestClose={closeEditModal}
                                    shouldCloseOnOverlayClick = {true}
                                    contentLabel="Editar Teléfono"
                                    className="EditModal-content"
                                    overlayClassName="EditModal-overlay"
                                    >
                                    <h2>Editar Número de Teléfono</h2>
                                    {user && (
                                    <div>
                                        <p>Editando tu telefono</p>
                                        <input
                                        type="text"
                                        value={newPhone}
                                        onChange={(e) => setNewPhone(e.target.value)}
                                        placeholder="Nuevo número de teléfono"
                                        />
                                        <div className="EditModal-buttons">
                                            <button onClick={handleSaveChanges}>Guardar Cambios</button>
                                            <button onClick={closeEditModal}>Cancelar</button>
                                        </div>
                                    </div>
                                    )}
                                </Modal>
                            </div>
                    </div>
                    
                </div>
                );
        case 'seguridad':
            return (
                <div className="seguridad-container">
                    <h3>Seguridad</h3>
                    <div className="security-sections">
                        {/* Cambiar Contraseña */}
                        <div className="security-box">
                            <h4>Cambiar la contraseña</h4>
                            <div className="form-row1">
                                <label>Contraseña actual</label>
                                <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Ingrese su contraseña actual"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                
                                />
                                <button
                                    type="button-show"
                                    className="ocultar-password"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? 'Ocultar' : 'Mostrar'}
                                </button>
                            </div>
                            <div className="form-row1">
                                <label>Nueva contraseña</label>
                                <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Ingrese su nueva contraseña"
                                value={newUserPassword}
                                onChange={(e) => setNewUserPassword(e.target.value)}
                                />
                                <button
                                    type="button-show"
                                    className="ocultar-password"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? 'Ocultar' : 'Mostrar'}
                                </button>
                            </div>
                            <div className="form-row1">
                                <label>Confirmar nueva contraseña</label>
                                <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Confirme su nueva contraseña"
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                />
                                <button
                                    type="button-show"
                                    className="ocultar-password"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? 'Ocultar' : 'Mostrar'}
                                </button>
                            </div>
                            <button className="security-button" onClick={handlePasswordChange}>
                                Confirmar
                            </button>
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                            {successMessage && <p className="success-message">{successMessage}</p>}
                        </div>
                    </div>
                </div>
            );
        case 'transferencias':
            return (
                <div className="transfer-container">
                    <h3>Transferencias</h3>
                    <div className="filters-container">
                        <div className="filters">
                            <div className="filter-group">
                                <label>Desde</label>
                                <input
                                    type="date"
                                    value={fromDate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                            </div>
                            <div className="filter-group">
                                <label>Hasta</label>
                                <input
                                    type="date"
                                    value={toDate}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                            <div className="filter-group">
                                <label>Tipo de Movimiento</label>
                                <select
                                    value={movementState}
                                    onChange={(e) => setMovementState(e.target.value)}
                                >
                                    <option value="">Todos</option>
                                    <option value="DEPOSITO TELEPAGOS">Deposito</option>
                                    <option value="RETIRO TELEPAGOS">Retiros</option>
                                    <option value="TRANSFERENCIA REALIZADA">Transferencia Realizada</option>
                                    <option value="TRANSFERENCIA RECIBIDA">Transferencia Recibida</option>
                                    <option value="RETIRO CAJERO">Retiros del Cajero</option>
                                </select>
                            </div>
                        </div>
                        <div className="filter-buttons">
                            <button className="search-button" onClick={applyFilters}>
                                Buscar
                            </button>
                            <button className="reset-button" onClick={() => { setFromDate(''); setToDate(''); setMovementState(''); setMovements(allMovements); }}>
                                Limpiar
                            </button>
                        </div>
                    </div>
                    <div className='tableT-container'>
                        <table className="infoT-table">
                            <thead>
                                <tr>
                                    <th>Fecha y Hora</th>
                                    <th>Usuario Emisor</th>
                                    <th>Usuario Receptor</th>
                                    <th>Tipo</th>
                                    <th>Importe</th>
                                    <th>Saldo Anterior</th>
                                    <th>Saldo Final</th>
                                </tr>
                            </thead>
                            <tbody>
                                {movements.length > 0 ? (
                                    movements.map((item, index) => (
                                        <tr key={index}>
                                                <td>{item.fecha_hora}</td>
                                                <td>{item.senderName}</td>
                                                <td>{item.receptorName}</td>
                                                <td>{item.description}</td>
                                                <td>{item.amount}</td>
                                                <td>{item.balance_before}</td>
                                                <td>{item.balance_after}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="7">No hay datos disponibles</td>
                                        </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            );
        default:
            return <div><h3>Seleccione una opción</h3></div>;
        }
    };

    

    return (
        <div className="container-miperfil">
        
          <div className="profile-buttons">
                <button
                className={`profile-button ${selectedButton === 'boton1' ? 'active' : ''}`}
                onClick={() => setSelectedButton('boton1')}
                >
                Mi Perfil
                </button>
                <button
                className={`profile-button ${selectedButton === 'boton2' ? 'active' : ''}`}
                onClick={() => setSelectedButton('boton2')}
                >
                Historial de Jugadas
                </button>
          </div>

          {/*OPCIONES MI PERFIL*/}  
          {selectedButton === 'boton1' && (
          <div className="profile-container">
                {/* Recuadro pequeño con opciones */}
                <div className="myprofile-options">
                    <button
                        className={`option-button ${selectedOption === 'datos-personales' ? 'active' : ''}`}
                        onClick={() => setSelectedOption('datos-personales')}
                    >
                        Datos personales
                    </button>
                    <button
                        className={`option-button ${selectedOption === 'seguridad' ? 'active' : ''}`}
                        onClick={() => setSelectedOption('seguridad')}
                    >
                        Seguridad
                    </button>
                    <button
                        className={`option-button ${selectedOption === 'transferencias' ? 'active' : ''}`}
                        onClick={() => setSelectedOption('transferencias')}
                    >
                        Transferencias
                    </button>
                </div>
    
                {/* Recuadro grande con contenido */}
                <div className="profile-content">
                    {renderContent()}
                </div>
          </div>


          )}  

          {/* TABLA  JUGADAS*/}
            {selectedButton === 'boton2' && (
                <div className="table-container">
                {/* Contenedor de Filtros */}
                <div className="filtersT-container">
                    <h3>TUS JUGADAS</h3>
                        <div className="filtersT">
                            <div className="filterT-group">
                              <label>Desde</label>
                              <input
                                  type="date"
                                  value={fromDate}
                                  onChange={(e) => setFromDate(e.target.value)}
                              />
                            </div>
                            <div className="filterT-group">
                              <label>Hasta</label>
                              <input
                                  type="date"
                                  value={toDate}
                                  onChange={(e) => setToDate(e.target.value)}
                              />
                            </div>
                            <div className="filterT-group">
                              <label>Estado</label>
                              <select
                                  value={movementState}
                                  onChange={(e) => setMovementState(e.target.value)}
                              >
                                  <option value="">Todos</option>
                                  <option value="Pendiente">Pendiente</option>
                                  <option value="Completado">Completado</option>
                              </select>
                            </div>
                            <div className="filterT-group">
                              <label>Proveedores</label>
                              <select
                                  value={movementProvider}
                                  onChange={(e) => setMovementProvider(e.target.value)}
                              >
                                  <option value="">Todos</option>
                              </select>
                            </div>
                            <div className="filterT-group">
                              <label>Nombre Usuario</label>
                              <input
                                  type="text"
                                  value={fromDate}
                                  onChange={(e) => setFromDate(e.target.value)}
                              />
                            </div>
                            <div className="filterT-group">
                              <label>CUIL</label>
                              <input
                                  type="number"
                                  value={fromDate}
                                  onChange={(e) => setFromDate(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="filterT-buttons">
                              <button className="search-button" >
                              Buscar
                              </button>
                              <button className="reset-button" >
                              Limpiar
                              </button>
                          </div>
                          </div>
                            <table className="info-table">
                              <thead>
                                  <tr>
                                      <th>Fecha y Hora</th>
                                      <th>Nombre del Usuario</th>
                                      <th>Proveedor</th>
                                      <th>ID del Movimiento</th>
                                      <th>Monto</th>
                                      <th>Tipo</th>
                                      <th>Repeticion</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  
                                      <tr >
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>
                                              <Link></Link>
                                          </td>
                                      </tr>
                                  
                              </tbody>
                            </table>
                      </div>
                    )}
            
            

        </div>
        
      );
    };
    

export default MiPerfil;