import axios from 'axios';
import { API_BASE_URL } from './apiConfig';

const httpClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer mi_token_secreto'
  },
});

// Permitir sobrescribir encabezados en ciertas llamadas
httpClient.interceptors.request.use((config) => {
  if (config.skipAuth) {
    delete config.headers.Authorization; // Elimina el token si `skipAuth` está presente
  }
  return config;
});

export default httpClient;
