// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-promociones {
  width: 66vw; /* 2/3 de la pantalla */
  max-width: none; /* Evita que un max-width limite el tamaño */
  height: auto; /* Ajuste automático de altura */
  margin-left: 20px; /* Pegado a la izquierda */
  border-radius: 50px; /* Más redondeado */
  overflow: visible;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px; /* Asegura que las imágenes también sean redondeadas */
}

@media (max-width: 768px) {
  .carousel-promociones {
    width: 85vw; /* En móviles ocupa más ancho */
    height: 50px; /* Reduce altura en móviles */
    margin-bottom: 30px;
    margin-left: 30px;
    
  }
  
}`, "",{"version":3,"sources":["webpack://./src/assets/css/CarousselPromociones.css"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAE,uBAAuB;EACpC,eAAe,EAAE,4CAA4C;EAC7D,YAAY,EAAE,gCAAgC;EAC9C,iBAAiB,EAAE,0BAA0B;EAC7C,mBAAmB,EAAE,mBAAmB;EACxC,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;;AAElB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,mBAAmB,EAAE,sDAAsD;AAC7E;;AAEA;EACE;IACE,WAAW,EAAE,+BAA+B;IAC5C,YAAY,EAAE,6BAA6B;IAC3C,mBAAmB;IACnB,iBAAiB;;EAEnB;;AAEF","sourcesContent":[".carousel-promociones {\n  width: 66vw; /* 2/3 de la pantalla */\n  max-width: none; /* Evita que un max-width limite el tamaño */\n  height: auto; /* Ajuste automático de altura */\n  margin-left: 20px; /* Pegado a la izquierda */\n  border-radius: 50px; /* Más redondeado */\n  overflow: visible;\n}\n\n.carousel-item {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  border-radius: 20px;\n  overflow: hidden;\n  \n}\n\n.carousel-image {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  border-radius: 50px; /* Asegura que las imágenes también sean redondeadas */\n}\n\n@media (max-width: 768px) {\n  .carousel-promociones {\n    width: 85vw; /* En móviles ocupa más ancho */\n    height: 50px; /* Reduce altura en móviles */\n    margin-bottom: 30px;\n    margin-left: 30px;\n    \n  }\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
