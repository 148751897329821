// components/BannersRedirecciones.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import bannerDeporte from '../assets/images/banner deportes.png';
import bannerSlots from '../assets/images/bannerslots.png';
import bannerCasino from '../assets/images/banner casino.png';
import '../assets/css/BannersRedirecciones.css'

const BannersRedirecciones = () => {
  const { user, login, logout } = useUser();

  return (
    <div className="banners-redirecciones">
      {user && user.role_id === 3 ? (
        <>
          <div className="banner-item">
              <Link to="/ "><img className="img" src={bannerDeporte} alt="Banner2" />
                <div className="banner-text"> 
                  <p>Apoya tu pasión y disfruta la emoción de los deportes</p>
                </div>
              </Link>
          </div>
        </>
          
      ) : (
        <>
          <div className="banner-item">
            <Link to="/tragamonedas">
              <img className="img" src={bannerSlots} alt="Banner1" />
              <div className="banner-text"> 
                <p>Juga en mas de 3000 slots</p>
              </div>
            </Link>
          </div>
          <div className="banner-item">
            <Link to="/tragamonedas"><img className="img" src={bannerDeporte} alt="Banner2" />
              <div className="banner-text"> 
                <p>Apoya tu pasión y disfruta la emoción de los deportes</p>
              </div>
            </Link>
          </div>
          <div className="banner-item">
            <Link to="/tragamonedas"><img className="img" src={bannerCasino} alt="Banner3" />
              <div className="banner-text"> 
                <p>Crupiers en vivo a tu disposicion</p>
              </div>
            </Link>
          </div>
        </>
        

      )}
      
    </div>
  );
};

export default BannersRedirecciones;
